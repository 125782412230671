<template>
  <apexchart :options="options" :series="series"></apexchart>
</template> 

<script>
import VueApexCharts from "vue3-apexcharts";
import { useViewSelector } from "../../stores/viewSelector";
import { useUserStore } from '../../stores/user';
import { storeToRefs } from 'pinia';
export default{
  name: 'lineChart',
  components:{
    apexchart: VueApexCharts,
  },
  setup() {
    const viewSelector = useViewSelector();
    const {currentView} = storeToRefs(viewSelector);
    
    const userStore = useUserStore();
    const {user_id, comp_id, isSessionExpired} = storeToRefs(userStore);
    const { trigSessVerify } = userStore;

    return {
      user_id,
      comp_id,
      currentView,      
      isSessionExpired,
      trigSessVerify,
    }
  },
  data(){
    return {
      options: {
        chart: {
          type:'line',
          id: 'vueLineChart',
          width:'100%',
        },
        colors:['#7789A4','#0B9719','#BA2D0D'],
        xaxis: {
          type:'datetime',
          categories:[],
        },
        yaxis: {
          labels: {
            formatter: val => val.toFixed() + "%"
          },
        },
        tooltip: {
          y: {
            formatter: val => val.toFixed(2) 
          },
        },
        dataLabels:{
          enabled:false,
        },
        title: {
          text: "Churn Percentual",
        },
        responsive: [
          {
            breakpoint: 640,
            options: {
              chart: {
                toolbar: {
                  tools: {
                    zoom: false,
                    zoomin: false,
                    zoomout: false,
                    pan: false,
                    reset: false,
                  }
                },
              },
            }
          }
        ]
      },
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 10,
        },
      },
      noData:{
        text:'Loading...',
      },
      legend: {
        position: 'right',
        offsetY: 40,
      },
      series: [{
            name: 'Churn Percentual (%)',
            data: []
          },
      ],
    }
  },  
  
    
    
  mounted() {
    let url = process.env.VUE_APP_URL + '/churnR';
    let nB = {u_id:this.user_id ,c_id:this.comp_id }
    fetch(url, {
      method:'POST',
      body:JSON.stringify(nB),
      headers: new Headers({
        "content-type":"application/json"
      })
    })
    .then((res) =>res.json())
    .then((content) => {
      console.table(content);
      if(content.invalidSession){
        this.currentView = 'Login';
      }else{
        let x = [].concat(...content.aChurnR[0].chart_data.data).map(({date})=>date);
        let y = [].concat(...content.aChurnR[0].chart_data.data).map(({id})=>id);
        this.options = {
        ...this.options,
        ...{
            xaxis:{
              categories: x
            }
          }
        }
        this.series[0].data = y;
      }
    })
    .catch((err) => {console.log('Erro, '+err)}); 
    
  },
}
</script>


<style>
</style>
