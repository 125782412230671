<template>
  <div id="main_container">
    <form @submit.prevent="accCheck" id="form">
      <div class="form__header">
        <h2>{{$t('confirmNewAccount.title')}}</h2>
      </div>
      <label for="email">{{$t("confirmNewAccount.description")}}</label>
      <input type="text" name="text" id="text" class="input" v-model='code' required>

      <div class="group-link">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M19 12H5" stroke="#3593FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M12 19L5 12L12 5" stroke="#3593FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <a class="forgot" @click="resendAccCode">{{$t("confirmNewAccount.link")}}</a>
      </div>

      <button class="botao" >
        {{$t("confirmNewAccount.button")}}
      </button>
    </form>
  </div>
</template>

<script>
//Receber ID do usuário 
  import { useViewSelector } from "../../stores/viewSelector";
  import { useUserStore } from "../../stores/user";
  import { storeToRefs } from 'pinia'; 

  export default{
    name: 'ConfirmNewAccount',
    components: '',
    setup(){
      const viewSelector = useViewSelector();
      const { currentView } = storeToRefs(viewSelector); 

      const userStore = useUserStore();
      const {user_id, } = storeToRefs(userStore);

      return{
        currentView,
        user_id,
      }
    },
    props: ['email'],

    data(){
      return {
        code:'',
      }
    },
    mounted() {
    },
    methods:{
      resendAccCode(){
        let nB = {'id':this.user_id}
        let url = process.env.VUE_APP_URL + '/resendAccCode'
        fetch(url, {
        method:'POST',
        body:JSON.stringify(nB),
        headers: new Headers({
          "content-type":"application/json"
        })
        })
        .then((res) =>res.json())
        .then((content) => {
          if(content.codeSent){
            //Algum feedback pro usuario avisando que o código foi enviado ao email
            console.log('Código enviado')
          }else{
            console.log('Problema no envio')
          }
        })
      },
      accCheck(){
        let nB = {'code':this.code}
        let url = process.env.VUE_APP_URL + '/accountCheck'
        fetch(url, {
        method:'POST',
        body:JSON.stringify(nB),
        headers: new Headers({
          "content-type":"application/json"
        })
        })
        .then((res) =>res.json())
        .then((content) => {
          if(content.codeOk){
            console.log(content)
            this.user_id = content.uid;
            this.currentView='Main'
          }else{
            //o código inserido está inválido (/ incorreto)
          }
        })
      } 
    },
  }
  
</script>


<style scoped>
  #main_container{
    width: 100vw;
    max-width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--secundary-1);
  }

  form {
    background-color: #FFF;
    padding: 40px;
    box-shadow: 0px 6px 8px -6px rgba(24, 39, 75, 0.12), 0px 8px 16px -6px rgba(24, 39, 75, 0.08);
    /* border-radius: 16px; */
    max-width: 500px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
    font-size: 18px;
    position: relative;
  }

  .form__logo {
    display: none;
  }

  .form__header > h2 {
    margin-bottom: 20px;
    font-size: 32px;
    margin-top: 8px;
  }

  .input, .botao {
    display: block;
    width: 100%;
    height: 60px;
    border-radius: 8px;
  }

  .input {
    border: 1px solid #E8E8E8;
    outline-color: var(--secundary-3);
    padding: 16px;
    font-size: 16px;
    width: 100%;
    height: 60px;
  }

  .input:first-of-type {
    margin-bottom: 16px;
  }

  .options {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
    font-size: 16px;
    flex-wrap: wrap;
    gap: 16px;
  }

  .forgot {
    text-decoration: none;
    color: var(--secundary-3);
    width: max-content;
    font-size: 16px;
    cursor: pointer;
  }

  .botao {
    border: 0;
    font-size: 18px;
    color: #FFF;
    font-weight: 700;
    background-color: var(--secundary-3);
    cursor: pointer;
    transition: .4s ease;
  }

  .group-link{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    width: max-content;
    margin-bottom: 10px;
  }

  .botao.disabledSubmit {
    pointer-events: none;
  }

  .botao:hover {
    background-color: var(--secundary-3);
  }

  .form__error {
    color: #f62727;
    position: absolute;
    top: 120px;
    font-style: italic;
  }

  @media screen and (max-width: 1023px) {

    form {
      box-shadow: none;
      padding: 64px 16px 40px 16px;
    }

    .form__error {
      color: #f62727;
      position: absolute;
      top: 220px;
      font-style: italic;
    }
  }
</style>