<template>
  <section class="result__container">
    <div class="result__banner">
      <img src="../../assets/img/ai/concluido.svg" :alt="analysisType.type">
    </div>
    <div class="result__content">
      <h3 class="result__title">Your model is ready!</h3>
      <button
        class="ai__button"
        @click="redirectTo('AiModelResults')"
      >
        See results
      </button>
    </div>
  </section>
</template>

<script>
import { storeToRefs } from 'pinia';
import { useAiStore } from '../../stores/ai';
import { usePageSelector } from '../../stores/pageSelector';

export default {
  name: "Result",
  setup() {
    const aiStore = useAiStore();
    const { analysisType } = storeToRefs(aiStore);

    const pageSelector = usePageSelector();
    const { redirectTo } = pageSelector;

    return {
      analysisType,
      redirectTo,
    }
  },
}
</script>

<style>
  .result__container {
    border-radius: 16px;
    background-color: #FFF;
    margin: 24px auto;
    max-width: 380px;
    animation: surgir 1s;
    display: grid;
    grid-template-areas: 'banner' 'content';
    grid-template-rows: 110px auto;
  }

  @keyframes surgir {
    from {
      opacity: 0.5;
      transform: translateY(32px);
    }

    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .result__banner {
    grid-area: banner;
    background-color: var(--secundary-3);
    border-radius: 16px 16px 0 0;
    display: grid;
    place-content: center;
  }

  .result__banner > img {
    /* width: 60px; */
    height: 60px;
  }

  .result__content {
    padding: 40px 32px 32px 32px;
  }

  .result__title {
    font-size: 24px;
    font-weight: 600;
  }

  .ai__button {
    border-radius: 8px;
    border: 0;
    background-color: var(--primary);
    color: #fff;
    font-weight: 600;
    font-size: 14px;
    width: 100%;
    height: 40px;
    margin-top: 32px;
    cursor: pointer;
    transition: 0.4s ease;
    position: relative;
  }

  .ai__button::after {
    content: url(../../assets/img/ai/right-arrow.svg);
    position: absolute;
    padding-left: 4px;
    transition: 0.2s ease;
  }

  .ai__button:hover {
    background-color: var(--secundary-3);
  }

  .ai__button:hover::after {
    padding-left: 12px;
  }
</style>