<template>
  <div class="home__container">
    <section class="banner">
      <div class="banner__container">
        <!-- <h2 class="banner__titulo">{{ $t("home.banner.titulo") }}</h2> -->
        <img src="../../assets/revxy/revxy_fullLogo_fullWhite_NOsubtitle.svg" alt="">
        <br>
        <br>
        <!-- <p class="banner__texto">{{ $t("home.banner.texto") }}</p> -->
        <!-- Futuro botão de tutorial -->
        <button
          class="banner__botao"
          @click="redirectTo('Analytics')">
          {{ $t("home.banner.botao") }}
        </button>
      </div>
    </section>

    <section class="quick">
      <h2 class="quick__titulo">{{ $t("home.quick.titulo") }}</h2>
      <div class="quick__cards">
        <!-- <div class="quick__card">
          <div class="quick__card--icon">
            <img src="../../assets/img/main/icon-ia.svg" alt="Dados" />
          </div>
          <h3 class="quick__card--titulo">
            {{ $t("home.quick.cards[0].titulo") }}
          </h3>
          <div class="quick__card--botoes">
            <button class="quick__card--botao" @click="newAnalysis(3)" :class="{disabled : canCreateAnalysis}">
              {{ $t("home.quick.cards[0].botoes[0]") }}
            </button>
            <button class="quick__card--botao" @click="newAnalysis(13)" :class="{disabled : canCreateAnalysis}">
              {{ $t("home.quick.cards[0].botoes[1]") }}
            </button>
            <button class="quick__card--botao" @click="newAnalysis(0)" :class="{disabled : canCreateAnalysis}">
              {{ $t("home.quick.cards[0].botoes[2]") }}
            </button>
          </div>
        </div> -->

        <!-- <div class="quick__card">
          <div class="quick__card--icon">
            <img
              src="../../assets/img/main/icon-analytics.svg"
              alt="Dados"
            />
          </div>
          <h3 class="quick__card--titulo">
            {{ $t("home.quick.cards[1].titulo") }}
          </h3>
          <div class="quick__card--botoes" v-if="lastTwoAnalyis.length > 0">
            <button v-for="analysis in lastTwoAnalyis" :key="analysis.id" class="quick__card--botao" @click="goMyLastAnalysis(analysis)">
              {{ analysis.custom_name }}
            </button>
            <button class="quick__card--botao" @click="newAnalysis(-1)">
              {{ $t("home.quick.cards[1].botoes[0]") }}
            </button>
          </div>

          <div class="quick__card--botoes" v-else>
            <button class="quick__card--botao" @click="newAnalysis(-1)">
              {{ $t("home.quick.cards[1].botoes[0]") }}
            </button>
          </div>
        </div> -->

        <div class="quick__card">
          <div class="quick__card--icon">
            <img src="../../assets/img/main/icon-dados.svg" alt="Dados" />
          </div>
          <h3 class="quick__card--titulo">
            {{ $t("home.quick.cards[2].titulo") }}
          </h3>
          <div class="quick__card--botoes">
            <button class="quick__card--botao" @click="showModalContact">
              {{ $t("home.quick.cards[2].botoes[1]") }}
            </button>
            <button class="quick__card--botao" @click="showModalDadaGenius">
              {{ $t("home.quick.cards[2].botoes[2]") }}
            </button>
            <!-- <button class="quick__card--botao">
              {{ $t("home.quick.cards[2].botoes[0]") }}
            </button> -->
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { storeToRefs } from 'pinia';
import { usePageSelector } from '../../stores/pageSelector';
import { useAiStore } from '../../stores/ai';
import { useUserStore } from '../../stores/user';
export default {
  name: "Home",
  setup() {
    const pageSelector = usePageSelector();
    const { allowedPages } = storeToRefs(pageSelector);
    const { redirectTo, requestPageElements, getAllowedPages } = pageSelector;
    
    const aiStore = useAiStore()
    const { cameFromQuickActions, analysisType , targetColumn , quickLatestAnalysis, setModalDataGenius } = storeToRefs(aiStore)

    const userStore = useUserStore()
    const { user_id, statusSub, isSubscription , analysis , transf_id} = storeToRefs(userStore)
    return {
      redirectTo,
      requestPageElements,
      getAllowedPages,
      allowedPages,
      pageSelector,
      cameFromQuickActions,
      analysisType,
      user_id,
      statusSub,
      isSubscription,
      
      analysis,
      transf_id,
      targetColumn,
      setModalDataGenius,

      quickLatestAnalysis,
    }
  },
  data() {
    return {
      storedElements: [],
      lastTwoAnalyis: [],
    }
  },
  methods: {
    showModalContact(){
      this.setModalDataGenius.show = true
      this.setModalDataGenius.title = this.$i18n.t('modalContact.title')
      this.setModalDataGenius.msg = this.$i18n.t('modalContact.label')
    },
    showModalDadaGenius(){
      this.setModalDataGenius.show = true
      this.setModalDataGenius.title = this.$i18n.t('metrics.dataGeniusModal[0]')
      this.setModalDataGenius.msg = this.$i18n.t('metrics.dataGeniusModal[1]')
    },
    newAnalysis(type){
      // Settando desde já o id de uma análise q o usuário quer criar para Choose
      this.analysisType.id = type
      this.cameFromQuickActions = type

      this.redirectTo('AiChoose')
      console.log('CAME FROM QUICK ACTIONS ------ '+this.cameFromQuickActions)
      console.log( "ANALYSIS TYPE DO PINIA --- " +this.analysisType)
    },
    goMyLastAnalysis(analysis){

      this.analysis = analysis.id
      this.transf_id = analysis.transformation_id
      this.targetColumn = analysis.target_column

      this.quickLatestAnalysis = true

      this.redirectTo('AiModelResults')
    },
    getHomeElements() {
      this.pageSelector.requestPageElements('Home').then((elements)=>{
        this.storedElements = elements
        console.log('getHomeElements: ' + this.storedElements);
      });
    }
  },
  computed:{
    canCreateAnalysis(){
      if(this.isSubscription){
        switch (this.statusSub){
          case 'past_due': return false;

          case 'active': return false;

          // (incomplete, incomplete_expired, canceled, unpaid) 
          default: return true;
        }
      }
      else{
        return false;
      }
    }
  },
  async mounted() {
    if (this.allowedPages.length === 0) {
      await this.getAllowedPages()
      // this.redirectTo("Analytics")
    } 
    this.getHomeElements();

    let url = process.env.VUE_APP_URL + '/recentAnalysis'
    let nB = {'u_id':this.user_id}
    fetch(url, { 
      method:'POST',
      body:JSON.stringify(nB),
      headers: new Headers({
        "content-type":"application/json"
      })
    })
    .then((res) =>res.json())
    .then((content) => {
      this.lastTwoAnalyis = content.lastAnalysis
      console.log(content)
      console.log(JSON.stringify(content))
      console.log(this.lastTwoAnalyis)
    })
  }
};
</script>

<style scoped>
.home__container {
  display: grid;
  grid-template-areas:
    "banner"
    "quick";
  grid-template-rows: auto auto;
  grid-template-columns: auto;
  margin: auto;
  margin-top: 32px;
  max-width: 1280px;
  animation: surgir 1s;
}
@keyframes surgir {
  from {
    opacity: 0.5;
    transform: translateY(32px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.banner {
  grid-area: banner;
  /* background-image: url("../../assets/img/home/backgroundLeft.svg"),
    url("../../assets/img/home/backgroundRight.svg"); */
  background-color: var(--primary);
  background-repeat: no-repeat;
  background-position: left center, right center;
  background-size: contain;
  color: #fff;
  border-radius: 16px;
  display: grid;
  place-items: center;
  padding: 32px;
  font-size: 16px;
}
.banner__container {
  max-width: 435px;
}
.banner__titulo {
  font-size: 2em;
}
.banner__texto {
  margin-top: 16px;
  margin-bottom: 32px;
  font-size: 1.1em;
  line-height: 24px;
}
.banner__botao {
  display: flex;
  height: 48px;
  width: 100%;
  min-width: 200px;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: 0;
  font-weight: 600;
  color: var(--primary);
  cursor: pointer;
  font-size: 1.1em;
  transition: 0.4s ease;
}
.banner__botao:hover {
  box-shadow: 0 4px 8px rgba(6, 44, 126, 0.4);
  transform: translateY(-4px);
}
.quick {
  grid-area: quick;
  margin-top: 64px;
}
.quick__titulo {
  font-weight: 600;
  font-size: 28px;
  line-height: 39px;
  margin-bottom: 72px;
  text-align: center;
}
.quick__cards {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 72px 24px;
}
.quick__card {
  background-color: #fff;
  border-radius: 16px;
  text-align: center;
  width: 100%;
  min-height: 332px;
  max-width: 385px;
  padding: 32px;
  padding-top: 72px;
  position: relative;
  margin: auto;
  transition: 0.8s ease;
}
.quick__card:hover {
  transform: translateY(-8px);
  box-shadow: 0 8px 16px rgba(6, 44, 126, 0.2);
}
.quick__card--icon {
  background-color: var(--primary);
  width: 72px;
  height: 72px;
  border-radius: 50%;
  display: grid;
  place-items: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -36px;
  transition: 0.8s ease;
}
.quick__card:hover .quick__card--icon {
  background-color: var(--secundary-3);
}
.quick__card--icon > img {
  width: 44px;
}
.quick__card--titulo {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: var(--primary);
}
.quick__card--botoes {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 30px;
}
.quick__card--botao {
  background-color: var(--primary);
  border-radius: 8px;
  color: #fff;
  font-family: inherit;
  font-size: 14px;
  font-weight: 600;
  height: 40px;
  border: 0;
  cursor: pointer;
  padding: 0 8px;
  transition: 0.4s ease;
}
.quick__card--botao:hover {
  background-color: var(--secundary-3);
}

.quick__card--botao.disabled {
  opacity: 0.4;
  cursor: not-allowed;
  pointer-events: none;
}

@media screen and (max-width: 1023px) {
  .banner {
    background-position: -200px center, right -200px center;
  }
}
@media screen and (max-width: 767px) {
  .banner {
    background-position: -300px center, right -300px center;
    font-size: 14px;
  }
}
@media screen and (max-width: 480px) {
  .banner {
    background-image: none;
  }
}
</style>