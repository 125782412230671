// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/img/ai/icon-done.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".steps__container{margin:40px 0 8px 0;padding:0 16px;height:80px;color:#fff;transform:translateX(6px)}.steps__wrapper{display:flex;justify-content:space-between;max-width:560px;margin:auto}.step{display:flex;flex-direction:column;align-items:center;justify-content:center;gap:8px;cursor:pointer;position:relative}.step:not(.step:first-of-type):after{content:\"\";height:4px;width:300%;background-color:#999;position:absolute;top:17px;left:-100px;z-index:1;transition:.4s ease}.step__number{width:34px;height:34px;background:none no-repeat 50%/contain;background-color:#999;display:grid;place-content:center;border-radius:50%;position:relative;z-index:2;transition:.8s ease}.step.active:not(.step:first-of-type):after,.step.active>.step__number{background-color:var(--primary);animation:active 1s ease}.step.completed:not(.step:first-of-type):after,.step.completed>.step__number{color:transparent;background-color:var(--secundary-3)}.step.completed>.step__number{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 50%/contain;background-color:var(--secundary-3);background-size:20px;animation:done .6s ease}@keyframes done{0%{transform:rotate(-45deg)}to{transform:rotate(0deg)}}@keyframes active{0%{transform:scale(1)}50%{transform:scale(1.2)}to{transform:scale(1)}}.step__name{position:absolute;bottom:-24px;white-space:nowrap;font-size:12px;color:#999}.step.active .step__name{color:var(--primary)}.step.completed .step__name{color:var(--secundary-3)}@media screen and (max-width:500px){.steps__container{margin-top:80px}.step__number{width:24px;height:24px;font-size:10px}.step:nth-child(2n) .step__name{bottom:auto;top:-24px}.step:not(.step:first-of-type):after{height:3px;width:16vw;top:12px;left:-16vw}}", ""]);
// Exports
module.exports = exports;
