<template>
  <div class="dashpage__container">
    <ModalHelper v-if="setModalHelper.show" />
    <ModalHelperScroll v-if="setModalHelperScroll.show" />
    <ModalDataGenius v-if="setModalDataGenius.show"/>
    <ModalErrorAI v-if="setModalErrorAI.show" />
    <LateralMenu
      class="menu-lateral"
    />
    <main class="principal">
      <!-- <UserDropdown
        class="user__container"
        :username="firstname"
      /> -->
      <!-- <h1
        class="principal__hello"
        v-html="$t('dashboard.hello') + `<strong>${firstname}</strong>!`"
      ></h1> -->
      <!-- <p
        class="principal__logged"
        v-html="$t('dashboard.logged') + `<strong>${empresa}</strong>.`"
        v-if="isSubs"
      ></p> -->
      <component
        :is="currentPage"
      ></component>
    </main>
  </div>
</template>

<script>
import LateralMenu from "../elements/LateralMenu.vue";
import Analytics from "../pages/Analytics.vue";
import FileUpload from "../pages/FileUpload.vue";
import Home from "../pages/Home.vue";
import Table from "../pages/Table.vue";
import UserDropdown from "../elements/UserDropdown.vue";
import Settings from "../pages/Settings.vue";
import AiChoose from "../ai/Choose.vue";
import AiRun from "../ai/Run.vue";
import AiModelResults from "../ai/ModelResults.vue";
import NotFound from "../pages/NotFound.vue";
import AiNewPrediction from "../ai/NewPrediction/Container.vue";
import ModalHelper from "../elements/ModalHelper.vue";
import ModalHelperScroll from "../elements/ModalHelperScroll.vue";
import ModalDataGenius from "../elements/ModalDataGenius.vue";
import ModalErrorAI from "../elements/ModalErrorAI.vue";


import { usePageSelector } from "../../stores/pageSelector";
import { useViewSelector } from "../../stores/viewSelector";
import { useUserStore } from "../../stores/user";
import { storeToRefs } from 'pinia';
import { useAiStore } from '../../stores/ai';

export default {
  components: {
    LateralMenu,
    Analytics,
    FileUpload,
    Home,
    Table,
    UserDropdown,
    Settings,
    AiChoose,
    AiRun,
    AiModelResults,
    NotFound,
    AiNewPrediction,
    ModalHelper,
    ModalHelperScroll,
    ModalDataGenius,
    ModalErrorAI,
},
  name: "Main",
  setup() {
    
    const userStore = useUserStore();
    const {firstname, lastname, fullname, empresa, isSessionExpired, isSubscription} = storeToRefs(userStore);

    const viewSelector = useViewSelector();
    const { currentView } = storeToRefs(viewSelector);

    const pageSelector = usePageSelector();
    const { currentPage, allowedPages, pagesElements, lateralMenuElements } = storeToRefs(pageSelector);
    const { redirectTo, requestPageElements } = pageSelector;

    const aiStore = useAiStore();
    const { setModalHelper , setModalHelperScroll , setModalDataGenius, setModalErrorAI} = storeToRefs(aiStore);

    return {
      firstname,
      lastname,
      fullname,
      empresa,
      isSubscription,
      currentView,
      currentPage,
      redirectTo,
      allowedPages,
      pagesElements,
      requestPageElements,
      lateralMenuElements,
      isSessionExpired,
      setModalHelper,
      setModalHelperScroll,
      setModalDataGenius,
      setModalErrorAI,
    }
  },
  computed: {
    isSubs(){
      if(this.isSubscription === true) return false
      else return true
    }
  },
  mounted() {
    console.log('----PINIA (Main.vue)---- '+ this.isSubscription)

    let url = process.env.VUE_APP_URL + '/user';

    fetch(url, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((content) => {
        console.log('Main, User data: '+ JSON.stringify(content))
        if (content.invalidSession) {
          this.isSessionExpired = true;
        } else {
          this.firstname = content.firstname;
          this.lastname = content.lastname;
          this.fullname = content.firstname + ' ' + content.lastname;
          this.empresa = content.comp;
        }
      });
  },
  // Sempre que a Main atualizar (trocar de página), voltar ao topo:
  beforeUpdate() {
    window.scrollTo(0,0)
  },
  // Resetar valores quando a Main for desmontada (usuário deslogou ou foi para a view Companies):
  unmounted() {
    this.currentPage = 'Home';
    this.allowedPages = [];
    this.pagesElements = [];
    this.lateralMenuElements = [];
  }
};
</script>

<style scoped>
.dashpage__container {
  background-color: var(--secundary-1);
  background-color: white;
  min-height: 100vh;
  display: grid;
  grid-template-areas: "menu-lateral" 
                        "principal";
  grid-template-rows: 96px 1fr;
}

.menu-lateral {
  grid-area: menu-lateral;
}

.principal {
  grid-area: principal;
  width: 100%;
  padding: 32px 24px 24px 24px;
}

/* .principal__hello {
  margin: 0;
  margin-bottom: 8px;
  font-style: italic;
  font-weight: 400;
  font-size: 32px;
  max-width: calc(100% - 80px);
}

.principal__logged {
  font-style: italic;
  font-weight: 400;
  border-bottom: 1px solid rgba(166, 166, 166, 0.16);
  padding-bottom: 24px;
} */

.principal__hello :deep(strong),
.principal__logged :deep(strong) {
  font-style: normal;
  text-transform: capitalize;
}

.principal__logged :deep(strong) {
  text-transform: uppercase;
}

/* .user__container {
  position: absolute;
  right: 24px;
  top: 136px;

  padding-right: 24px;
} */

@media screen and (max-width: 767px) {
  /* .dashpage__container {
    grid-template-areas:
      "menu-lateral"
      "principal";
    grid-template-columns: auto;
    grid-template-rows: 104px 1fr;
  } */

  .principal {
    padding: 32px 16px;
  }

  .principal__hello {
    margin-bottom: 16px;
  }
}
</style>