// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/img/warning.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/img/main/close.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".modal__overlay[data-v-b99b699a]{background-color:rgba(0,0,0,.4);position:fixed;left:0;top:0;height:100vh;width:100vw;z-index:20;display:flex;align-items:center;justify-content:center;animation:esmaecer-b99b699a .3s;padding:16px}@keyframes esmaecer-b99b699a{0%{opacity:0}to{opacity:1}}.modal__container[data-v-b99b699a]{background-color:#fff;width:100%;max-width:640px;min-height:280px;border-radius:16px;display:grid;grid-template-areas:\"banner\" \"content\";grid-template-rows:120px 1fr;box-shadow:0 6px 8px -6px rgba(24,39,75,.12),0 8px 16px -6px rgba(24,39,75,.08);animation:crescer-b99b699a .3s}@keyframes crescer-b99b699a{0%{transform:scale(0)}to{transform:scale(1)}}.modal__banner[data-v-b99b699a]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 50%/contain;background-size:72px;background-color:var(--secundary-3);border-radius:16px 16px 0 0;grid-area:banner;position:relative;padding:24px}.modal__closeButton[data-v-b99b699a]{height:32px;width:32px;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat 50%/contain;border:0;position:absolute;top:16px;right:16px;cursor:pointer}.modal__content[data-v-b99b699a]{display:grid;place-content:center;grid-area:content;padding:24px;text-align:center;margin:auto}.modal__title[data-v-b99b699a]{color:var(--secundary-3);font-weight:600;font-size:24px;margin-bottom:16px}.modal__message[data-v-b99b699a]{line-height:22px}.buttons__wrapper[data-v-b99b699a]{flex-direction:column;margin-top:32px;gap:24px}.button[data-v-b99b699a],.buttons__wrapper[data-v-b99b699a]{display:flex;justify-content:center;align-items:center}.button[data-v-b99b699a]{background-color:var(--primary);border:2px solid var(--primary);font-family:inherit;color:#fff;font-size:14px;font-weight:600;height:40px;width:100%;max-width:220px;border-radius:8px;cursor:pointer;transition:.2s ease}.button[data-v-b99b699a]:hover{background-color:var(--secundary-3);border-color:var(--secundary-3)}", ""]);
// Exports
module.exports = exports;
