<template>
  <div id="container">
    <div class="form__header">
      <img src="../../assets/img/logo_comum_azul.svg" alt="">
      <h2>{{$t("confirmNewPassoword.title")}}</h2>
    </div>


    <label>{{$t("confirmNewPassoword.labels[0]")}}</label>
    <div class="input__wrapper">
      <input :type="showPassword ? 'text' : 'password'" id="new-password" v-model="newPassord" class="input" @focusin.once="badPass = true">
      <button @click="toggleShowPassword" :class="{hide: showPassword}" title="Change password visibility"></button>
    </div>
    <p class="pass__rules" v-if="badPass">
      {{$t("settings.changePass[2]")}}
      <ul class="rules">
        <li class="min__digits"  :class="{ok : newPassord.length >= 8}">-{{$t("settings.changePass[3]")}}</li>
        <li class="min__letters" :class="{ok : hasLetter}">-{{$t("settings.changePass[4]")}}</li>
        <li class="min__numbers" :class="{ok : hasNumber}">-{{$t("settings.changePass[5]")}}</li>
      </ul>
    </p>

    <button class="botao" :class="{invalidBtn: goodToGoForm}" @click="commitChange">
      {{$t("confirmNewPassoword.button")}}
    </button>
  </div>
</template>

<script>
  import { usePageSelector } from '../../stores/pageSelector';
  import { useUserStore } from '../../stores/user';
  import { storeToRefs } from 'pinia';

  export default{
    name: 'ConfirmNewPassword',
    components: '',

    setup() {
      const pageSelector = usePageSelector();
      const { currentForm } = storeToRefs(pageSelector);

      const userStore = useUserStore();
      const { emailForRESET } = storeToRefs(userStore);

      return{
        currentForm,
        emailForRESET
      }  
    },

    data(){
      return {
        newPassord: '',
        invalidPassword: false,
        email: '',


        badPass: false,
        hasNumber: false,
        hasLetter: false,
        okBtn: false,

        showPassword: false,
      }
    },
    computed: {
      goodToGoForm(){
        if(this.badPass === false && this.okBtn === true){
          return false
        }
        else {
          return true
        }
      }
    },
    watch:{
      newPassord(newValue){
        if(/[a-zA-Z]/.test(newValue)){
          this.hasLetter = true
        }
        else{
          this.hasLetter = false
        }
        if(/[0-9]/.test(newValue)){
          this.hasNumber = true
        }
        else{
          this.hasNumber = false
        }
        if(this.hasLetter && this.hasNumber && newValue.length >= 8){
          this.badPass = false
          this.okBtn = true
        }
        else{
          this.badPass = true
          this.okBtn = false
        }
      },
    },
    methods:{
      commitChange(){
        console.log(this.email)
        let nB = {'email':this.email, 'pass':this.newPassord}
        let url = process.env.VUE_APP_URL + '/commitRecovery'
        fetch(url, {
        method:'POST',
        body:JSON.stringify(nB),
        headers: new Headers({
          "content-type":"application/json"
        })
        })
        .then((res) =>res.json())
        .then((content) => {
          if(content.passChanged){

            // Se o reset der certo, jogamos o bacana na LoginForm (sempre esteve desse jeito)
            // Será q seria melhor já logar o cara na Home? (mais chatinho, creio eu)
            this.currentForm = 'LoginForm'
            this.emailForRESET = ''

            //o que fazer
          }/*else{
            Não deveria ter else, vou conversar com o Lucca
          }*/
        })
      },
      toggleShowPassword() {
        this.showPassword = !this.showPassword
      },
    },
    mounted() {
      // Atualizando essa var do data com o valor da var do pinia pra mandar na função no futuro
      this.email = this.emailForRESET
    }
  }
</script>


<style scoped>
  #container {
    background-color: #FFF;
    padding: 40px;
    box-shadow: 0px 6px 8px -6px rgba(24, 39, 75, 0.12), 0px 8px 16px -6px rgba(24, 39, 75, 0.08);
    /* border-radius: 16px; */
    max-width: 500px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
    font-size: 18px;
    position: relative;
  }

  .form__logo {
    display: none;
  }

  .form__header{
    margin: 0 auto;
  }

  .form__header img{
    width: 100%;
    height: 70px;
  }

  .form__header > h2 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 32px;
    margin-top: 8px;
  }

  .input, .botao {
    display: block;
    width: 100%;
    height: 60px;
    border-radius: 8px;
  }

  .input {
    border: 1px solid #E8E8E8;
    outline-color: var(--secundary-3);
    padding: 16px;
    font-size: 16px;
    width: 100%;
    height: 60px;
  }

  .invalid{
    border: 1px solid #f62727;
  }

  .invalidBtn{
    opacity: 0.5;
    cursor: none;
    pointer-events: none;
  }

  .warning{
    color: #f62727;
    font-size: 14px;
  }

  .input:first-of-type {
    margin-bottom: 16px;
  }

  .options {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
    font-size: 16px;
    flex-wrap: wrap;
    gap: 16px;
  }

  .forgot {
    text-decoration: none;
    color: var(--secundary-3);
    width: max-content;
    font-size: 16px;
    cursor: pointer;
  }

  .botao {
    border: 0;
    font-size: 18px;
    color: #FFF;
    font-weight: 700;
    background-color: var(--secundary-3);
    cursor: pointer;
    transition: .4s ease;
  }

  .group-link{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    width: max-content;
    margin-bottom: 10px;
  }

  .botao.disabledSubmit {
    pointer-events: none;
  }

  .botao:hover {
    background-color: var(--secundary-3);
  }

  .form__error {
    color: #f62727;
    position: absolute;
    top: 120px;
    font-style: italic;
  }

  .pass__rules{
    font-size: 0.85rem;
    color: #b6b3b3;
  }

  .ok{
    color: var(--primary);
    text-decoration: line-through;
  }

  .input__wrapper {
    position: relative;
    display: flex;
    align-items: center;
  }

  .input__wrapper > button {
    position: absolute;
    display: block;
    right: 16px;
    top: 20px;
    height: 20px;
    border: 0;
    width: 20px;
    background: url(../../assets/img/eye-off-line.svg) no-repeat center / contain;
    cursor: pointer;
  }
  .input__wrapper > button.hide {
    background-image: url(../../assets/img/eye-line.svg);
  }

  @media screen and (max-width: 1023px) {

    form {
      box-shadow: none;
      padding: 64px 16px 40px 16px;
    }

    .form__error {
      color: #f62727;
      position: absolute;
      top: 220px;
      font-style: italic;
    }
  }
</style>