<template>
  <section class="processing__container">
    <ul class="processing__wrapper">
      <li class="processing__analysis">
        
        <!-- <img :src="require('../../assets/img/ai/analyses/' + analysisType.icon)" :alt="analysisType.type" class="analysis__icon"> -->
        <h3 class="analysis__name">{{analysisType.type}}</h3>
        <p class="analysis__desc">{{analysisType.description}}</p>
        <!-- O back-end ainda não envia a % exata do progresso (apenas 0 e 100). -->
        <!-- <span class="analysis__prog">
          <progress :value="analysisType.progress" max="100">
            {{ analysisType.progress }}%
          </progress>
        </span> -->
        <div class="analysis__status" :class="{isLoading: analysisType.progress < 100}">
          <img src="../../assets/img/ai/icon-loading-blue.svg" alt="Loading" v-if="analysisType.progress < 100">
          <img src="../../assets/img/ai/icon-done-blue.svg" alt="Done" v-else>
        </div>
      </li>
    </ul>
  </section>
</template>

<script>
import { storeToRefs } from 'pinia';
import { useAiStore } from '../../stores/ai';
import { useUserStore } from '../../stores/user';
import { usePageSelector } from "../../stores/pageSelector";
export default {
  name: "Processing",
  setup() {
    const pageSelector = usePageSelector();
    const { redirectTo } = pageSelector;

    const aiStore = useAiStore();
    const { currentComponent, analysisType, analysisTypeList, prevAnalysisList } = storeToRefs(aiStore);

    const userStore = useUserStore();
    const {analysis, comp_id, user_id, firstname} = storeToRefs(userStore);

    function progress(){
      console.log('UserStore.analysis :' + userStore.analysis)
      let nB = {'a_id':userStore.analysis}
      let urlStatus = process.env.VUE_APP_URL + '/AIStatus';
      fetch(urlStatus, {  
        method:'POST',
        body:JSON.stringify(nB),
        headers: new Headers({
          "content-type":"application/json"
        })
      })
      .then((res) =>res.json())
      .then((content) => {
        console.log(JSON.stringify(content))
        let status = content;
        console.log(status)
        if(status.status_pipe === 'Finished'){
          aiStore.currentComponent = 'Result'
          console.log('aiStore.currentComponent :' + aiStore.currentComponent)
        } else { 
          let url = process.env.VUE_APP_URL + '/AIProgress';
          fetch(url, {
            method:'POST',
            body:JSON.stringify(nB),
            headers: new Headers({
              "content-type":"application/json"
            })
          })
          .then((res) =>res.json())
          .then((progCont) => {
            console.log(JSON.stringify(progCont))
            if(progCont.ML_training_error){
              //Mostrar PopUp de erro pro usuario, com explicações (futuro), com botão pra voltar pra choose
              // aiStore.currentComponent = 'Upload'
              this.redirectTo('AiChoose')
            }else{
              let porcent = progCont
              if(porcent.pct == 100){
                aiStore.currentComponent = 'Result'
              }else{
                console.log(JSON.stringify(porcent))
                aiStore.analysisType.progress = porcent.pct;
                console.log('aiStore.analysisType.progress :' + aiStore.analysisType.progress)
                setTimeout(progress,1000); 
              }
            }
          })
        }
      })
    }
    return {
      redirectTo,
      currentComponent,
      analysisType,
      analysisTypeList,
      prevAnalysisList,
      analysis,
      progress,
      comp_id,
      user_id,
      firstname,
    }
  },
  methods: {
    getPaginateAnalysis(index) {
      let url2 = process.env.VUE_APP_URL + '/paginateAnalysis'
      let nB2 = {'comp_id': this.comp_id, 'user_id': this.user_id, 'pageIndex': index,}
      fetch(url2, {
        method:'POST',
        body:JSON.stringify(nB2),
        headers: new Headers({
          "content-type":"application/json"
        })
      })
      .then((res) =>res.json())
      .then((content) => {
        console.log('Resposta do fetch:', content);
        this.analysisPagination = content.analysisPagination;
        this.prevAnalysisList = content.analysisList.map(analysis => {
          const analysisType = this.analysisTypeList.find(el => el.type === analysis.type) 

          let newAnalysis = {
            ...analysis,
            description: (analysisType && analysisType.description) || "No description available",
            icon: (analysisType && analysisType.icon) || "icon-robot-default.svg",
          }
          newAnalysis.infos.creator = this.firstname

          let mlStartTS = new Date(newAnalysis.infos["created at"]);

          let formatted = {
            day: mlStartTS.getDate() < 10 ? "0" + mlStartTS.getDate() : mlStartTS.getDate(),
            month: mlStartTS.getMonth() < 10 ? "0" + mlStartTS.getMonth() : mlStartTS.getMonth(),
            year: mlStartTS.getFullYear(),
            hours: mlStartTS.getHours() < 10 ? "0" + mlStartTS.getHours() : mlStartTS.getHours(),
            minutes: mlStartTS.getMinutes() < 10 ? "0" + mlStartTS.getMinutes() : mlStartTS.getMinutes(),
          }

          newAnalysis.infos["created at"] = `${formatted.year}-${formatted.month}-${formatted.day} ${formatted.hours}:${formatted.minutes}`;

          return newAnalysis

        })
      })
      .catch((err) => {console.log('Erro, '+err)}); 
    },
  },
  mounted() {
    // Checar o progresso do processamento da análise:
    this.progress();
    // Atualizar a lista de análises com a nova análise que está sendo criada, para poder exibir os dados na ModelResults em seguida:
    this.getPaginateAnalysis(1);
  }, 
}
</script>

<style>
  .processing__container {
    margin-top: 16px;
    animation: surgir 1s;
  }
  @keyframes surgir {
    from {
      opacity: 0.5;
      transform: translateY(32px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  .processing__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }
  .processing__analysis {
    background-color: #FFF;
    box-shadow: 0px 6px 8px -6px rgba(24, 39, 75, 0.12), 0px 8px 16px -6px rgba(24, 39, 75, 0.08);
    border-radius: 16px;
    padding: 24px;
    display: grid;
    gap: 4px 16px;
    font-size: 12px;
    width: 100%;
    max-width: 620px;
    text-align: left;
    grid-template-areas: 
      'icon name status'
      'icon desc status'
      'icon prog status';
    grid-template-columns: 48px 1fr 40px;
  }
  @media screen and (max-width: 480px) {
    .processing__analysis {
      grid-template-areas: 
        'icon name status'
        'icon desc status'
        'icon prog prog';
    }
  }
  .analysis__icon {
    grid-area: icon;
    max-height: 40px;
    margin-right: auto;
  }
  .analysis__name {
    grid-area: name;
    font-size: 1.2em;
  }
  .analysis__desc {
    grid-area: desc;
    font-size: 1em;
    color: #999;
    line-height: 1.4;
  }
  .analysis__prog {
    grid-area: prog;
    font-size: 1em;
  }
  .analysis__status {
    grid-area: status;
    display: flex;
    align-items: center;
    margin-left: auto;
  }
  .analysis__status.isLoading > img {
    animation: girar 1.5s infinite linear;
  }
  @keyframes girar {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
  .analysis__prog {
    display: flex;
    align-items: center;
    margin-top: 8px;
  }
  .analysis__prog > progress {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    transform: translateY(4px);
  }
  .analysis__prog > progress::-webkit-progress-bar {
    background: #ececec;
    border-radius: 4px;
    height: 6px;
  }
  .analysis__prog > progress::-webkit-progress-value {
    background: var(--secundary-2);
    border-radius: 4px;
    height: 6px;
    transition: 1s ease;
  }
  .analysis__prog > progress::-moz-progress-bar {
    background: #ececec;
    border-radius: 4px;
    height: 6px;
  }
</style>