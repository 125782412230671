<template>
  <apexchart :options="options" :series="series"></apexchart>
</template> 

<script>
import VueApexCharts from "vue3-apexcharts";
import { useViewSelector } from "../../stores/viewSelector";
import { storeToRefs } from 'pinia';
import { useUserStore } from '../../stores/user';
export default{
  name: 'aiDailyReqChart',
  components:{
    apexchart: VueApexCharts,
  },
  setup() {
    const viewSelector = useViewSelector();
    const {currentView} = storeToRefs(viewSelector);

    const userStore = useUserStore();
    const {analysis} = storeToRefs(userStore);
    

    return {
      currentView,
      analysis,
    }
  },
  data(){
    return {
      options: {
        chart: {
          type:'line',
          id: 'aiDailyReqChart',
          width:'100%',
        },
        colors:['#7789A4','#0B9719','#BA2D0D'],
        xaxis: {
          type:'datetime',
          categories:[],
        },
        yaxis: {
          labels: {
            formatter: val => val.toFixed(),
          }
        },
        dataLabels:{
          enabled:false,
        },
        title: {
          text: "Daily Requests",
        },
        noData:{
          text: 'No predictions have been run yet.',
          align: 'center',
          verticalAlign: 'bottom',
          offsetX: 0,
          offsetY: 28,
          style: {
            color: '#01009F',
            fontSize: '14px',
            fontFamily: 'Montserrat'
          }
        },
        responsive: [
          {
            breakpoint: 640,
            options: {
              chart: {
                toolbar: {
                  tools: {
                    zoom: false,
                    zoomin: false,
                    zoomout: false,
                    pan: false,
                    reset: false,
                  }
                },
              },
            }
          }
        ]
      },
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 10,
        },
      },
      legend: {
        position: 'right',
        offsetY: 40,
      },
      series: [{
            name: 'Daily Requests',
            data: []
          },
      ],
    }
  },  
  
  mounted() {
    let url = process.env.VUE_APP_URL + '/aiDailyPredReq';

    let nB = {'a_id': this.analysis}
    fetch(url, {
      method:'POST',
      body:JSON.stringify(nB),
      headers: new Headers({
        "content-type":"application/json"
      })
    })
    .then((res) =>res.json())
    .then((content) => {
      console.log(content);
      if(content.invalidSession){
        this.currentView = 'Login';
      }else{
        let x = content.map(({cal_date})=>cal_date);
        let y = content.map(({reqs})=>reqs);
        this.options = {
        ...this.options,
        ...{
            xaxis:{
              categories: x
            }
          }
        }
        this.series[0].data = y;
      }
    })
    .catch((err) => {console.log('Erro, '+err)}); 
    
  },
}
</script>


<style>
</style>
