<template>
  <div class="cards__box">
    <!-- Efeito visual de carregar 6 cards antes dos dados chegarem -->
    <div class="cards__loading" v-if="Object.keys(cardsContent).length === 0">
      <div class="card__loading" v-for="n in 6" :key="n"></div>
    </div>
    <!-- Renderização dos cards -->
    <div class="card" v-for="(name, value, index) in cardsContent" :key="index">
      <span class="card__name">{{value}}</span>
      <span class="card__value">{{name}}</span>
    </div>
  </div>
</template>

<script>
import { useViewSelector } from "../../stores/viewSelector";
import { useUserStore } from '../../stores/user';
import { storeToRefs } from 'pinia';
export default {
  name: "Cards",
  setup() {
    const viewSelector = useViewSelector();
    const {currentView} = storeToRefs(viewSelector);
    
    const userStore = useUserStore();
    const {isSessionExpired} = storeToRefs(userStore);
    const { user_id, comp_id, trigSessVerify } = userStore;

    return {
      user_id,
      comp_id,
      currentView,      
      isSessionExpired,
      trigSessVerify,
    }
  },
  data() {
    return {
     cardsContent: {},
    };
  },
  mounted() {
    let url = process.env.VUE_APP_URL + '/cardsAnalytics';
    let nB = {u_id:this.user_id ,c_id:this.comp_id, }
    fetch(url, {
      method:'POST',
      body:JSON.stringify(nB),
      headers: new Headers({
        "content-type":"application/json"
      })
    })
    .then((res) =>res.json())
    .then((content) => {
      if(content.invalidSession){
        this.isSessionExpired = true;
      }else {
        this.trigSessVerify()
        console.log("Cards mounted:", content)
        this.cardsContent = content.cards[0].chart_data || {}
      }
    })
  }
};
</script>

<style scoped>

  .cards__box {
    background-color: #FFF;
    border-radius: 16px;
    margin-bottom: 24px;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 32px 24px;
    gap: 32px 24px;
  }

  .card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    gap: 8px;
  }

  .cards__loading {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 32px 24px;
  }

  .card__loading {
    height: 56px;
    width: 100px;
    display: inline-block;
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    background-color: rgba(228, 235, 242, 0.2);
  }

  .card__loading::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(228, 235, 242, 0) 0,
      rgba(213, 224, 238, 0.1) 20%,
      rgba(213, 224, 238, 0.2) 60%,
      rgba(228, 235, 242, 0)
    );
    animation: shimmer 2s infinite;
    content: '';
  }
  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }

  .card::after {
    content: '';
    display: block;
    height: 2px;
    width: 100%;
    border-radius: 4px;
    background-color: var(--primary);
  }

  .card:nth-child(1) {
    order: 4;
  }

  .card:nth-child(2) {
    order: 5;
  }

  .card:nth-child(5) {
    order: -1;
  }

  .card:nth-child(6) {
    order: 6;
  }

  .card__name {
    font-size: 14px;
    color: #949494;
  }

  .card__value {
    font-size: 18px;
  }

</style>