<template>
  <ModalError v-if="showModal" :message="errorMessage" :missing-attr="missingAttrSelected" @close-modal="handleShowModal" />
  <article class="entry__table">
    <div class="title__wrapper">
      <button title="Back to previous page" aria-label="Back to previous page" class="button__back" @click="backPage">
        <img src="../../../assets/img/ai/icon-back-arrow.svg" alt="Back to previous page">
      </button>
      <h3 class="upload__title" v-html=" isUploadPredict ? $t('newPrediction.entryPaste.title[1]') : $t('newPrediction.entryPaste.title[0]')"></h3>
    </div>

    <div class="table__buttons">
      <button @click="pasteData" class="paste__button" title="Paste data" aria-label="Paste data" v-if="!isUploadPredict">
        <img src="../../../assets/img/ai/icon-clipboard.svg" alt="Icon clipboard">
        {{$t('newPrediction.entryPaste.btnCopy')}}
      </button>
<!-- Botão de Editar a tabela desligado devido a vazão de memória quando tentamos renderizar 100% de dataset's grandes na tabela para o usuário manipular -->
<!-- Ainda não está definido quem está causando o problema, mas com o método 'repeatedAttrColumns' desligado  -->
      <!-- <button class="edit__button" @click="toggleEdit" :class="{'edit-actived': editActived}" title="Enable edit" aria-label="Enable edit">
        <img src="../../../assets/img/ai/icon-edit.svg" alt="Icon clipboard">
        Enable edit
      </button> -->
    </div>
    <div class="table__container" v-if="tableContent.rowsData.length">
      <div class="table__wrapper">
        <table>
          <!-- Cabeçalho da tabela -->
          <tr>
            <th v-for="name in tableContent.columnsName" :key="name" :title="name">
              <span :class="{columnError: repeatedAttrColumns.includes(name)}">{{name}}</span>
              <!-- Dropdown de match entre nome da coluna e nome do field obrigatório -->
              <select class="tablehead__dropdown" v-model="selectedTableColumns[name]" :class="{dropdownError: repeatedAttrColumns.includes(name)}">
                <option value="">-</option>
                <option v-for="option in requiredColumns" :key="option" :value="option">{{option}}</option>
                <option :value="name + '*'" title="This column will not be processed by PredictAPI, just displayed in the results table.">*Display column in results</option>
              </select>
            </th>
          </tr>
          <!-- Renderização dos dados -->
          <tr v-for="(row, i) in tableContent.rowsData" :key="row">
            <td v-for="(data, j) in row" :key="data" :title="data">
              <input type="text" @blur="e => tableContent.rowsData[i][j] = e.target.value" :value="data" :readonly="!editActived">
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div class="table__buttons" v-if="tableContent.rowsData.length">
      <button @click="handlePredict" class="predict__button">{{$t('newPrediction.entryPaste.btnConfirm')}}</button>
    </div>
  </article>
</template>

<script>
import { storeToRefs } from 'pinia';
import { useAiStore } from '../../../stores/ai';
import { useUserStore } from '../../../stores/user';
import ModalError from '../../elements/ModalError.vue';

export default {
  name: "EntryTable",
  components: {ModalError},
  setup() {
    const aiStore = useAiStore();
    const { showEntryMenu, showDataEntry, selectedColumns, isUploadPredict, fileUploadData } = storeToRefs(aiStore);
    const { handlePredictAPI } = aiStore;

    const userStore = useUserStore();
    const { user_id, empresa, comp_id, } = storeToRefs(userStore);

    return {
      showEntryMenu,
      showDataEntry,
      selectedColumns,
      isUploadPredict,
      fileUploadData,
      handlePredictAPI,
      user_id,
      empresa,
      comp_id,
    }
  },
  data() {
    return {
      editActived: false, // Edição dos dados da tabela está ativada?
      stringData: "", // Dados copiados a serem 'colados'

      // Objeto que servirá de base para renderizar a tabela:
      tableContent: {
        fileName:'',
        columnsName: [],
        rowsData: [],
        user_id:null,
        company_id:null,
        company:null,
      },

      // Objeto onde as keys são os nomes das colunas da tabela e os values são o nome do field selecionado no dropdown:
      selectedTableColumns: {},
      // Colunas (fields) requeridos pela PredictAPI:
      requiredColumns: [],
      // Valores repetidos (selecionados nos dropdowns):
      repeatedAttr: [],

      showModal: false,
      errorMessage: "Tente novamente em alguns instantes.",
    }
  },
  methods: {
    backPage() {
      this.showEntryMenu = true;
    },
    toggleEdit() {
      this.editActived = !this.editActived
    },
    handleShowModal() {
      this.showModal = !this.showModal
    },
    handlePredict() {
      // Se há valores selecionados repetidos:
      if (this.repeatedAttrColumns.length > 0) {
        this.errorMessage = this.$i18n.t("modalError.errors[0]")
        this.handleShowModal();
        return
      }
      // Se há fields obrigatórios faltantes:
      if (this.missingAttrSelected.length > 0) {
        const attributes = this.missingAttrSelected;
        const message = attributes.length === 1 
          ? this.$i18n.t("modalError.errors[1]")
          : this.$i18n.t("modalError.errors[2]")
        this.errorMessage = message
        this.handleShowModal();
        return
      }

      // Filtrar colunas não selecionadas
      let array = Object.entries(this.selectedTableColumns).filter(array => !(array[0] == "" || array[1] == ""))
      this.selectedTableColumns = Object.fromEntries(array)

      // Remover dados de colunas não selecionadas
      this.tableContent.columnsName.forEach((column, i) => {
        if (!(Object.keys(this.selectedTableColumns).includes(column))) {
          this.tableContent.rowsData = this.tableContent.rowsData.map(row => {
            let array = row
            array.splice(i,1,null)
            return array
          })
        }
      })
      this.tableContent.rowsData = this.tableContent.rowsData
        .filter(row => !row.every(data => data == "" || data == undefined)) // Limpar linhas em branco
        .map(row => row.filter(data => data !== null)) // Limpar valores nulos

      this.tableContent.columnsName = Object.values(this.selectedTableColumns).map(item => [item])
      this.tableContent.user_id = this.user_id;
      this.tableContent.company_id = this.comp_id;
      this.tableContent.company = this.empresa;
      this.handlePredictAPI(this.tableContent);
      
    },
    async pasteData() {
      console.log(JSON.stringify(navigator.clipboard))
      console.log(JSON.stringify(window.isSecureContext))
      // Se o clipboard existir no navegador utilizado:
      if (navigator.clipboard && window.isSecureContext) {
        // Salvar o conteúdo copiado e chamar a função que transforma string em colunas e linhas para a tabela:
        this.stringData = await navigator.clipboard.readText();
        this.excelToObjects();
        return
      } else {
        alert('Oops, something wrong happened! Try again. É NO ENTRYPASTE')
      }
      
    },
    excelToObjects(){
      let array = [];
      let rows = this.stringData.split('\n');

      let columns = rows[0].replace("\r", "").split('\t');

      for (let rowNr = 1; rowNr < rows.length; rowNr++) {
          let o = [];
          let data = rows[rowNr].split('\t');

          for (let cellNr = 0; cellNr < data.length; cellNr++) {
            
            if (cellNr === data.length - 1) {
              o.push(data[cellNr].replace("\r", "").replace(",", "."));
              break
            }

            o.push(data[cellNr].replace(",", "."));
          }

          array.push(o);
      }

      this.tableContent.columnsName = columns;
      this.tableContent.rowsData = array;
      
      // Caso o nome da coluna da tabela seja igual a um dos nomes dos fields obrigatórios, deixá-los selecionados inicialmente:
      this.tableContent.columnsName.forEach(column => {
        const target = this.requiredColumns.find(item => item.toLowerCase() === column.toLowerCase());
        if (target) {
          this.selectedTableColumns[column] = target
        } else {
          this.selectedTableColumns[column] = ""
        }
      })

    },
    updateRepeatedAttr() {
      // Contagem e atualização da lista de valores repetidos nos dropdowns:
      const selectedDropdownValues = Object.values(this.selectedTableColumns)
      let counts = {};
      selectedDropdownValues.forEach(value => {
        counts[value] = (counts[value] || 0) + 1
      })
      const repeatedAttr = 
        Object.entries(counts).filter(array => array[1] > 1).map(array => array[0])
      this.repeatedAttr = 
        Object.entries(this.selectedTableColumns)
          .filter(array => repeatedAttr.includes(array[1]))
    },
  },
  computed: {
    repeatedAttrColumns() {
      return this.repeatedAttr
        .filter(array => this.requiredColumns.includes(array[1]))
        .map(array => array[0])
    },
    missingAttrSelected() {
      // Lista dos fields obrigatórios ainda não selecionados nos dropdowns:
      const missingAttr = [];
      const selectedValues = Object.values(this.selectedTableColumns);
      for (const attr of this.requiredColumns) {
        if (!selectedValues.includes(attr)) {
          missingAttr.push(attr)
        }
      }
      
      return missingAttr
    }
  },
  beforeUpdate() {
    this.updateRepeatedAttr()
  },
  mounted() {
    this.requiredColumns = Object.keys(this.selectedColumns)
    console.log(JSON.stringify(this.requiredColumns))

    // Se o usuário chegou nesta página através do EntryUpload, utilizar os dados do file upload:
    if (this.isUploadPredict) {
      console.log('Chegou pelo EntryUpload')

      const { columnsName, rowsData, fileName } = this.fileUploadData;
      this.tableContent.pipeline = 'mlFileUpload'
      this.tableContent.columnsName = columnsName;
      this.tableContent.rowsData = rowsData;
      this.tableContent.fileName = fileName;

      this.tableContent.columnsName.forEach(column => {
        const target = this.requiredColumns.find(item => item.toLowerCase() === column.toLowerCase());
        if (target) {
          this.selectedTableColumns[column] = target
        } else {
          this.selectedTableColumns[column] = ""
        }
      })

    // Senão, apenas cole os dados copiados para a área de transferência:
    } else {
      this.tableContent.pipeline = 'mlCopyPaste'
      this.pasteData()
    }

  },
  unmounted() {
    this.isUploadPredict = false;
  },
}
</script>

<style scoped>
  
.entry__table {
  position: relative;
}

.table__buttons {
  position: relative;
  display: flex;
  gap: 16px;
  margin: auto;
  margin-bottom: 32px;
  justify-content: center;
  width: fit-content;
}

.table__buttons > button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  border: 0;
  background-color: var(--primary);
  color: #FFF;
  font-size: 14px;
  font-weight: 500;
  height: 40px;
  width: 180px;
  border-radius: 8px;
  cursor: pointer;
  transition: .4s ease;
}

.table__buttons button:not(.predapi__back-button) > img {
  filter: brightness(10);
}

.table__buttons .predict__button {
  width: 180px;
}

.predict__button:hover, .paste__button:hover {
  background-color: var(--secundary-3);
}

.table__buttons .edit__button {
  background-color: #818181;
}

.edit__button.edit-actived {
  background-color: #00b20f;
}

.table__container {
  margin: auto;
  background-color: #FFF;
  padding: 24px;
  border-radius: 16px;
  width: max-content;
  margin-bottom: 32px;
}

.table__wrapper {
  max-width: 70vw;
  max-height: 50vh;
  overflow: scroll;
}

table {
  border-collapse: collapse;
  font-size: 14px;
}

tr {
  display: block;
}

tr:nth-child(even) {
  background-color: rgba(228, 235, 242, 0.4);
}

td input, th input {
  border: 0;
  background-color: transparent;
  outline: 0;
  width: 90%;
  text-overflow: ellipsis;
}

th input {
  font-weight: bold;
  padding: 4px;
}

td, th {
  min-width: 180px;
  max-width: 180px;
  text-align: left;
  overflow: hidden;
  padding: 16px 8px;
  text-overflow: ellipsis;
}

.tablehead__dropdown {
  display: block;
  border: 1px solid rgba(223, 223, 223, 0.8);
  border-radius: 8px;
  padding: 8px 20px 8px 8px;
  margin: 8px 0;
  cursor: pointer;
  transform: translateX(-7px);
  width: calc(100% + 7px);
  position: relative;
  outline: none;
  -moz-appearance:none;
  -webkit-appearance: none; 
  appearance: none;
  background: url(../../../assets/img/table/arrowDown.svg) no-repeat center / contain;
  background-size: 9px;
  background-position: right 6px center;
  line-height: 16px;
}

.tablehead__dropdown option:last-of-type {
  color: var(--primary);
}

.tablehead__dropdown.dropdownError {
  border-color: rgba(255, 0, 0, 0.4);
  background-color: rgba(255, 0, 0, 0.04);
  color: #db0606;
}

span.columnError {
  color: #db0606;
}

::-webkit-scrollbar {
  height: 8px;
  border-radius: 8px;
  width: 8px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #bdc5cc;
  border-radius: 8px;
  transition: 0.4s;
}

::-webkit-scrollbar-thumb:hover {
  background: #95b0c7;
}

.title__wrapper {
  position: relative;
  max-width: fit-content;
  margin: auto;
  display: flex;
  align-items: center;
  margin-bottom: 64px;
}

.button__back {
  background-color: transparent;
  border: 0;
  position: absolute;
  top: -2px;
  left: -64px;
  cursor: pointer;
}

.upload__title {
  font-size: 24px;
  font-weight: 500;
  color: #6b6b6b;
}
</style>