<template>
  <form @submit.prevent="sendCode()" id="form">
    <div class="form__header">
      <h2>{{$t("recoveryPassword.title")}}</h2>
    </div>


    <label for="email" v-html="$t('recoveryPassword.description')"></label>
    <input type="email" name="email" id="email" class="input" required v-model="emailForRESET" @keyup="inputChecker">

    <div class="group-link">
      <a class="forgot" @click="currentForm = 'LoginForm'">{{$t("recoveryPassword.link")}}</a>
    </div>

    <button  class="botao" :disabled="disableBtn" :class="{invalidBtn: disableBtn}">
      {{$t("recoveryPassword.button")}}
    </button>
  </form>
</template>

<script>
  import { usePageSelector } from '../../stores/pageSelector';
  import { useUserStore } from '../../stores/user';
  import { storeToRefs } from 'pinia';

  export default{
    name: 'RecoverForm',
    components: '',

    setup() {
      const pageSelector = usePageSelector();
      const { currentForm } = storeToRefs(pageSelector);

      const userStore = useUserStore();
      const { emailForRESET } = storeToRefs(userStore);

      return{
        currentForm,
        emailForRESET
      }  
    },
    data(){
      return {
        disableBtn: true,
      }
    },

    methods:{
      inputChecker(){
        if(this.emailForRESET.length <= 0 ){
          this.disableBtn = true;
          
        }
        else if(this.emailForRESET.length >= 5 ){
          this.disableBtn = false;
        }
      }, 
      sendCode(){
        let nB = {'email':this.emailForRESET}
        let url = process.env.VUE_APP_URL + '/passRecovery'
        fetch(url, {
        method:'POST',
        body:JSON.stringify(nB),
        headers: new Headers({
          "content-type":"application/json"
        })
        })
        .then((res) =>res.json())
        .then((content) => {
          console.log(content)
          this.currentForm = 'EnterCode'
        })
      }
    }
  }
</script>


<style scoped>
  form {
    background-color: #FFF;
    padding: 40px;
    box-shadow: 0px 6px 8px -6px rgba(24, 39, 75, 0.12), 0px 8px 16px -6px rgba(24, 39, 75, 0.08);
    /* border-radius: 16px; */
    max-width: 500px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
    font-size: 18px;
    position: relative;
  }

  .form__logo {
    display: none;
  }

  .form__header > h2 {
    margin-bottom: 20px;
    font-size: 32px;
    margin-top: 8px;
  }

  .input, .botao {
    display: block;
    width: 100%;
    height: 60px;
    border-radius: 8px;
  }

  .input {
    border: 1px solid #E8E8E8;
    outline-color: var(--secundary-3);
    padding: 16px;
    font-size: 16px;
    width: 100%;
    height: 60px;
  }

  .input:first-of-type {
    margin-bottom: 16px;
  }

  .invalidBtn{
    opacity: 0.5;
    cursor: none;
    pointer-events: none;
  }

  .options {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
    font-size: 16px;
    flex-wrap: wrap;
    gap: 16px;
  }

  .forgot {
    text-decoration: none;
    color: var(--secundary-3);
    width: max-content;
    font-size: 16px;
    cursor: pointer;
  }

  .botao {
    border: 0;
    font-size: 18px;
    color: #FFF;
    font-weight: 700;
    background-color: var(--secundary-3);
    cursor: pointer;
    transition: .4s ease;
  }

  .group-link{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    width: max-content;
    margin-bottom: 10px;
  }

  .botao.disabledSubmit {
    pointer-events: none;
  }

  .botao:hover {
    background-color: var(--secundary-3);
  }

  .form__error {
    color: #f62727;
    position: absolute;
    top: 120px;
    font-style: italic;
  }

  @media screen and (max-width: 1023px) {

    form {
      box-shadow: none;
      padding: 64px 16px 40px 16px;
    }

    .form__error {
      color: #f62727;
      position: absolute;
      top: 220px;
      font-style: italic;
    }
  }
</style>
