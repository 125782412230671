<template>
  <main class="comp__container">
    <div class="comp__img">
      <img src="../../assets/img/brand/aihuk_logo_full_black.svg" alt="Airpoon logo" />
    </div>

    <!-- <h1 class="comp__welcome">{{$t('company.welcome')}}<span>{{ username }}</span>!</h1> -->

    <!-- <h2 class="comp__instruction">{{$t('company.instruction')}}</h2> -->
    <input
      type="text"
      placeholder="Search"
      class="comp__search"
      v-model="filterCompanies"
    />

    <!-- <div class="compinfo__wrapper">
      <input type="checkbox" id="compinfo" v-model="showCompanyInfo">
      <label for="compinfo" class="advanced__switch"></label>
      <span>{{$t('company.information')}}</span>
    </div> -->

    <!-- Cards com a logo e nome das empresas do usuário: -->
    <TransitionGroup name="slide-fade" tag="ul" class="comp__cards" v-if="!showCompanyInfo">
      <li v-if="filteredCompanies.length === 0" class="comp__nocards">
        {{$t('company.nocards')}}
      </li>
      <li
        v-for="company in filteredCompanies"
        :key="company.company_id"
        @click="selectCompany(company.company_id)"
        class="comp__card"
      >
        <!-- <div class="comp__card--img">
          <img v-if="company.logo" :src="'https://storage.googleapis.com/airpoon_companies_logos/' + company.logo" :alt="`Logo ${company.company}`" />
          <img v-else src="../../assets/img/building.svg" alt="Sem logo">
        </div> -->
        <div class="comp__card--content">
          <h3>{{ company.company }}</h3>
        </div>
      </li>
    </TransitionGroup>

    <!-- Cards das empresas com informações adicionais: -->
    <!-- <TransitionGroup name="fade" tag="ul" class="compinfo__cards" v-else>
      <li v-if="filteredCompanies.filter(comp => comp.infos !== null).length === 0" class="comp__nocards">
        {{$t('company.noinfocards')}}
      </li> -->
      <!-- Exibir apenas empresas que possuam infos não nulas -->
      <!-- <li
        v-for="company in filteredCompanies.filter(comp => comp.infos !== null)"
        :key="company.company_id"
        @click="selectCompany(company.company_id)"
        class="compinfo__card"
        :title="company.company"
      >
        <div class="compinfo__logo">
          <img v-if="company.logo" :src="'https://storage.googleapis.com/airpoon_companies_logos/' + company.logo" :alt="`Logo ${company.company}`" />
          <img v-else src="../../assets/img/building.svg" alt="Sem logo">
          <h3>{{company.company}}</h3>
        </div>
        <div class="compinfo__data">
          <div class="compinfo__parameter" v-for="info in company.infos" :key="info[0]">
            <span class="parameter__name">{{info[0]}}</span>
            <span class="parameter__value">{{info[1]}}</span>
          </div>
        </div>
      </li>
    </TransitionGroup> -->
  </main>
</template>

<script>
import { useViewSelector } from "../../stores/viewSelector";
import { storeToRefs } from 'pinia';
import { useUserStore } from "../../stores/user";
import { usePageSelector } from "../../stores/pageSelector";
export default {
  name: "Companies",
  setup() {
    const userStore = useUserStore();
    const {comp_id, isSessionExpired, isSubscription, statusSub, multiple_comps} = storeToRefs(userStore);
    const { trigSessVerify } = userStore;
    
    const viewSelector = useViewSelector();
    const {currentView} = storeToRefs(viewSelector);

    const pageSelector = usePageSelector()
    const {currentPage} = storeToRefs(pageSelector)

    return {
      currentView,
      comp_id,
      isSessionExpired,
      isSubscription,    
      statusSub,
      multiple_comps,
      trigSessVerify,
      currentPage
    }
  },
  methods: {
    selectCompany(compId) {
      let newBody = {compId};
      this.comp_id=compId;
      let url = process.env.VUE_APP_URL + '/companySelected';
      fetch(url, {
        method:'POST',
        body:JSON.stringify(newBody),
        headers: new Headers({
          "content-type":"application/json"
        })
      })
      .then((res)=> res.json())
      .then((content)=>{
        if(content.loginComplete){
          this.trigSessVerify()
          if(content.isSubscription){
            this.isSubscription = true;
            this.statusSub = content.statusSub;
            this.currentView = 'Main';
            this.currentPage = 'AiChoose'
          }else {
            this.isSubscription = false;
            this.statusSub = null
            this.currentView = 'Main';
            this.currentPage = 'AiChoose'
          }
        }else {
          console.log('Falha ao selecionar empresa') //Transformar em um Modal, pedir re-login, avisar erro? (Quais erros podem acontecer aqui?)
        } 
        if(content.inactiveSession){
          this.isSessionExpired = true;
        }
      })
    },
  },
  data() {
    return {
      username: "",
      companies: [],
      filterCompanies: "",
      showCompanyInfo: false,
    };
    
  },
  computed: {
    // Filtrar empresas de acordo com a busca digitada no input
    filteredCompanies: function () {
      return this.companies.filter((comp) =>
        comp.company.toLowerCase().includes(this.filterCompanies.toLowerCase())
      );
    },
  },
  mounted(){
    
    let url = process.env.VUE_APP_URL + '/user';
    fetch(url, {
      method:'GET',
    })
    .then((res) => res.json())
    .then((content) => {
      if(content.invalidSession){
          this.isSessionExpired = true;
      }else {
        this.username = content.firstname + ' ' + content.lastname;
        this.trigSessVerify();
      }
    })
    let url2 = process.env.VUE_APP_URL + '/companies';
    fetch(url2, {
      method: 'GET',
    })
    .then(res => res.json())
    .then(content => {
      console.log("Content:", content)
      if(content.companies.length > 1){
        this.multiple_comps = true
      }else{
        this.multiple_comps = false
      }
      this.companies = content.companies
    })
    
  },
};
</script>

<style scoped>
.slide-fade-move, .slide-fade-enter-active {
  transition: all 0.2s ease-out;
}
.slide-fade-leave-active {
  transition: all 0.4s cubic-bezier(1, 0.5, 0.8, 1);
  position: absolute;
}
.slide-fade-enter-from,
.slide-fade-leave-to {
  opacity: 0;
}
.fade-move, .fade-enter-active {
  transition: all 0.2s ease-out;
}
.fade-leave-active {
  transition: all 0.4s cubic-bezier(1, 0.5, 0.8, 1);
  position: absolute;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.comp__container {
  background-color: var(--secundary-1);
  background-color: #fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  font-size: 12px;
}
.comp__img {
  max-width: 140px;
  margin: 32px auto 0 auto;
}
.comp__img > img {
  width: 100%;
}
.comp__welcome {
  font-weight: 600;
  font-size: 1.75em;
  margin-top: 48px;
}
.comp__welcome > span {
  text-transform: capitalize;
}
.comp__instruction {
  font-weight: 400;
  font-size: 1.5em;
  margin-top: 32px;
}
.comp__search {
  border: 1px solid #c6c6c6;
  outline: 0;
  height: 40px;
  padding: 0 40px 0 16px;
  border-radius: 8px;
  width: 100%;
  max-width: 240px;
  box-sizing: border-box;
  margin-top: 16px;
  background: url(../../assets/img/search-2-line.svg) no-repeat center / contain;
  background-color: #fff;
  background-size: 18px;
  background-position: center right 16px;
}
.compinfo__wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 24px 0;
}
.compinfo__wrapper > span {
  color: #999;
  font-weight: 600;
  transition: .4s ease;
}
.compinfo__wrapper > input:checked ~ span {
  color: var(--secundary-3);
}
.advanced__switch {
  width: 40px;
  height: 22px;
  background-color: #999;
  border-radius: 20px;
  position: relative;
  cursor: pointer;
  transition: 0.4s ease;
}
.advanced__switch::after {
  content: "";
  display: block;
  height: 14px;
  width: 14px;
  border-radius: 50%;
  background-color: #fff;
  position: absolute;
  top: 4px;
  left: 4px;
  transition: 0.2s ease;
}
.compinfo__wrapper > input {
  display: none;
}
.compinfo__wrapper > input:checked ~ .advanced__switch {
  background-color: var(--secundary-3);
}
.compinfo__wrapper > input:checked ~ .advanced__switch::after {
  left: 22px;
}
.comp__nocards {
  color: var(--secundary-3);
  position: absolute;
  z-index: 1;
  transition: 0.2s;
}
.comp__cards {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 80vw;
  gap: 24px;
}
.comp__card {
  background-color: #fff;
  /* display: grid;
  grid-template-areas: "img" "content";
  grid-template-columns: 80px 40px; */
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  /* width: 120px; */
  max-width: 300px;
  /* border-radius: 16px; */
  cursor: pointer;
  transition: 0.4s ease;
  z-index: 3;
  /* background-color: red; */
  width: 100%;
  box-shadow: 0px 6px 8px -6px rgba(24, 39, 75, 0.12), 0px 8px 16px -6px rgba(24, 39, 75, 0.08);

}
.comp__card:hover {
  transform: scale(1.1);
}
.comp__card--img {
  /* grid-area: img; */
  height: 80px;
  width: 80px;
  padding: 16px;
  border-radius: 16px 16px 0 0;
}
.comp__card--img > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.comp__card--content {

  padding: 12px;
  color: #fff;
  font-weight: 500;
  display: grid;
  place-content: center;
}
.comp__card--content > h3 {
  max-width: 100%;
  color: black;
  overflow: hidden;
  /* text-overflow: ellipsis; */
  font-size: 12px;
  text-transform: uppercase;
  text-align: center;
  white-space: nowrap;
}
.compinfo__cards {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80vw;
  /* gap: 24px; */
  padding-bottom: 40px;
}
.compinfo__card {
  width: 100%;
  max-width: 960px;
  display: grid;
  grid-template-columns: 140px 1fr;
  grid-template-rows: 140px;
  grid-template-areas: 'logo data';
  background-color: #FFF;
  /* border-radius: 16px; */
  cursor: pointer;
  transition: .4s ease;
}
.compinfo__card:hover {
  transform: scale(1.02);
  box-shadow: 0 4px 16px rgba(0, 0, 159, 0.1);
}
.compinfo__logo {
  background-color: var(--secundary-3);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  color: #FFF;
  grid-area: logo;
  /* border-radius: 16px 0 0 16px; */
  padding: 24px;
}
.compinfo__card:first-of-type {
  border-top-right-radius: 16px;
}
.compinfo__card:first-of-type,
.compinfo__card:first-of-type > .compinfo__logo {
  border-top-left-radius: 16px;
}
.compinfo__card:last-of-type {
  border-bottom-right-radius: 16px;
}
.compinfo__card:last-of-type,
.compinfo__card:last-of-type > .compinfo__logo {
  border-bottom-left-radius: 16px;
}
.compinfo__logo > img {
  width: 100%;
  max-width: 56px;
  filter: brightness(0) invert(1);
}
.compinfo__logo > h3 {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  white-space: nowrap;
}
.compinfo__data {
  grid-area: data;
  padding: 24px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
}
.compinfo__parameter {
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;
}
.parameter__name {
  color: #949494;
  font-size: 12px;
  font-weight: 600;
}
.parameter__value {
  font-size: 16px;
  font-weight: 600;
  padding-bottom: 8px;
  border-bottom: 2px solid var(--primary);
}
.user__container {
  position: absolute;
  top: 32px;
  right: 24px;
  padding-right: 24px;
}
@media screen and (max-width: 767px) {
  .comp__img {
    margin-top: 24px;
  }
}
@media screen and (max-width: 479px) {
  .user__container {
    display: none;
  }
}
</style>
