import { defineStore } from "pinia";

import { useViewSelector as viewSelector } from "./viewSelector";

export const usePageSelector = defineStore("pageSelector", {
  state: () => {
    return {
      currentPage: "AiChoose", // Página que está sendo exibida na Main.vue
      allowedPages: [], // Páginas que o usuário pode acessar
      pagesElements: [], // Elementos das páginas que o usuário pode acessar
      lateralMenuElements: [], // Elementos do menu lateral
      paginaAtualChoose: "", // Páginação atual da Choose.vue

      currentForm: "LoginForm", //Formulário atual, Login, Recovery etc...
    };
  },

  actions: {
    // Alterar a página atual (se o usuário realmente possuir acesso):
    redirectTo(page) {
      const isAllowed = this.allowedPages.some((el) => el.name === page);
      if (!isAllowed) {
        this.currentPage = "NotFound";
        return;
      }

      this.currentPage = page;
    },
    
    // Request das páginas que o usuário pode ter acesso, salvando-as no state allowedPages.
    async getAllowedPages() {
      let url = process.env.VUE_APP_URL + '/contextPages'
      let result = await fetch(url, {
        method: "GET",
      });
      const data = await result.json();
      console.log(JSON.stringify(data));
      if (data.invalidSession) {
        console.log("IF Get Allowed Pages:" + data.invalidSession);
        viewSelector().currentView = "Login";
      } else {
        this.allowedPages = data.pageArray;
        console.log("AllowedPages: ", data.pageArray);
        return data.pageArray;
      }
    },

    // Request dos elementos do menu lateral
    async getLateralMenuElements() {
      //As vezes, o menu lateral fica pronto antes do fetch das páginas que o usuário pode acessar.
      //Quando isso acontece se o usuário clicar em alguma página ele recebe a "NotFound".
      //Esse IF tenta resolver esse problema
      if(!this.allowedPages){
        await this.getAllowedPages();
      }
      let p_id = 3;
      let url = process.env.VUE_APP_URL + '/getElements';
      let nB = { p_id };
      const result = await fetch(url, {
        method: "POST",
        body: JSON.stringify(nB),
        headers: new Headers({
          "content-type": "application/json",
        }),
      });
      const data = await result.json();
      console.log(JSON.stringify(data));
      if (data.invalidSession) {
        console.log("IF Get Lateral Menu Elements:" + data.invalidSession);
        viewSelector().currentView = "Login";
      } else {
        this.lateralMenuElements = data.elemArray;
        console.log("lateralMenuElements: ", data.elemArray);
        return data.elemArray;
      }
    },
    
    // Obter os elementos de uma determinada página:
    //PROBLEMA, não altera o currentView para a página correta Importa View Selector
    async requestPageElements(page) {
      // Verificar se a página está na lista de páginas permitidas:
      const target = this.allowedPages.find((el) => el.name === page);
      if (!target) {
        this.currentPage = "NotFound";
        console.log('Page target not found in allowedPages!')
        return
      }

      // Verificar se a página já possui elementos armazenados na store:
      const isStored = this.pagesElements.find((el) => el.name === target.name);
      if (isStored) {
        // if user sessions isn`t expired
        return isStored.elements;
        //else...
      }

      let url = process.env.VUE_APP_URL + '/getElements';
      let p_id = target.id;
      let nB = { p_id };
      const result = await fetch(url, {
        method: "POST",
        body: JSON.stringify(nB),
        headers: new Headers({
          "content-type": "application/json",
        }),
      });
      const data = await result.json();
      console.log(JSON.stringify(data));
      if (data.invalidSession) {
        console.log("IF Get Elements:" + data.invalidSession);
        viewSelector().currentView = "Login";
        return;
      } else {

        this.pagesElements.push({ name: page, elements: data.elemArray });
        console.log(page + " elements: ", data.elemArray);
        console.log("pageElements: ", this.pagesElements)
        return data.elemArray;
      }
    },

    // Caso o nível de acesso do usuário seja alterado durante a sessão, deve-se resetar o this.pagesElements e chamar getAllowedPages() novamente.
  },
});
