<template>
  <section class="modal__overlay">
    <div class="modal__container">
      <div class="modal__banner">
        <button title="Fechar modal" aria-label="Fechar modal" class="modal__closeButton" @click="closeModal"></button>
      </div>
      <div class="modal__content">
        <h3 class="modal__title">{{title}}</h3>

        <div class="modal__fields">
          <!-- Renderização dos campos de edição, de acordo com os valores recebidos na props fields -->
          <!-- O que isso faz? Porque tem um v-for em um input único? -->
          <div v-for="(value, field) in fields" :key="field" class="modal__field">
            <input type="text" v-model="entries[field]" :data-value="value">
            <label for="">{{field}}</label>
          </div>
        </div>
        
        <button class="modal__button" @click="handleSave">{{ $t('modalEdit.button') }}</button>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "ModalError",
  props: ['fields', 'title', 'analysisID'],
  data() {
    return {
      entries: {},
      lockButton: false,
    }
  },
  methods: {
    closeModal() {
      this.$emit('close-modal');
    },
    handleSave() {
      if (this.lockButton) return

      this.lockButton = true;

      console.log('ID da análise a ser atualizada: ', this.analysisID)
      console.log('Novo nome da análise:', Object.values(this.entries))
      let a = Object.values(this.entries)
      console.log('Novo nome da análise2 (a): '+ a)
      let url = process.env.VUE_APP_URL + '/updateAnalysisName';

      let nB = {'a_id': this.analysisID, 'newName': a[0]}
      fetch(url, {
        method:'POST',
        body:JSON.stringify(nB),
        headers: new Headers({
          "content-type":"application/json"
        })
      })
      .then((res) =>res.json())
      .then((content) => {
        console.log(content);
        if(content.invalidSession){
          this.currentView = 'Login';
          this.lockButton = false;
        } else{
          console.log('Result:', content)
          // alert('Analysis updated successfully!')
          this.lockButton = false;
          this.$emit('analysis-updated', a[0]);
        }
      })
      .catch((err) => {console.log('Erro, '+err)}); 
    }
  },
  mounted() {
    this.entries = this.fields
    console.log(Object.values(this.fields))
    console.log(this.fields)
  }
}
</script>

<style scoped>
.modal__overlay {
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  z-index: 20;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: .3s esmaecer;
  padding: 16px;
}

@keyframes esmaecer {
  from {
    opacity: 0
  }
  to {
    opacity: 1;
  }
}

.modal__container {
  background-color: #FFF;
  width: 100%;
  max-width: 400px;
  min-height: 280px;
  border-radius: 16px;
  display: grid;
  grid-template-areas: 'banner' 'content';
  grid-template-rows: 120px 1fr;
  box-shadow: 0px 6px 8px -6px rgba(24, 39, 75, 0.12), 0px 8px 16px -6px rgba(24, 39, 75, 0.08);
  animation: .3s crescer;
}

@keyframes crescer {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

.modal__banner {
  background: url(../../assets/img/ai/icon-edit.svg) no-repeat center / contain;
  background-size: 64px;
  background-color: var(--secundary-3);
  border-radius: 16px 16px 0 0;
  grid-area: banner;
  position: relative;
  padding: 24px;
}

.modal__closeButton {
  height: 32px;
  width: 32px;
  background: url(../../assets/img/main/close.svg) no-repeat center / contain;
  border: 0;
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
}

.modal__content {
  display: flex;
  flex-direction: column;
  gap: 32px;
  grid-area: content;
  padding: 32px;
  margin: auto;
  width: 100%;
}

.modal__fields {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.modal__field {
  display: flex;
  flex-direction: column-reverse;
  gap: 8px;
  font-size: 14px;
}

.modal__field > input {
  padding: 8px;
  height: 40px;
  border-radius: 8px;
  outline: 0;
  border: 1px solid #999;
}

.modal__title {
  color: var(--secundary-3);
  font-weight: 600;
  font-size: 24px;
  text-align: center;
}

.modal__button {
  border: 0;
  background-color: var(--primary);
  color: #FFF;
  border-radius: 8px;
  height: 40px;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: .4s ease;
}

.modal__button:hover {
  background-color: var(--secundary-3);
}

label{
  text-align: left;
}
</style>