<template>
  <section class="para__container">
    <!-- Toggle tabs "EasyAI || Advanced" -->
    <div class="para__tabs" :class="{disabled: disableAdvanced}">
      <button
        class="para__tab"
        :class="{ active: activeTab === 'easy' }"
        @click="selectTab"
      >
        Easy AI
      </button>
      <button
        class="para__tab"
        :class="{ active: activeTab === 'advanced'}"
        @click="selectTab"
      >
        Advanced
      </button>
      <span
        class="para__tab--background"
        :class="{ left: activeTab === 'easy', right: activeTab === 'advanced' }"
      ></span>
    </div>

    <article class="para__wrapper">
      <!-- Easy AI tab -->
      <div class="para__easy" v-if="activeTab === 'easy'">
        <img
          src="../../assets/img/brand/aihuk_logo.svg"
          class="easy__img"
          alt="Robot AI"
        />
        <p class="easy__description">
          <strong>Easy and fast</strong> AI analysis for everyone, with default
          settings.
        </p>
        <!-- Required EasyAI (parâmetros obrigatórios que devem ser escolhidos em determidos tipos de análises, como Churn): -->
        <ul class="easy__options" v-if="displayParameters.required.hasDisplay[0]">
          <li class="easy__instru">{{requiredParameters.instru}}</li>
          <!-- Dropdowns -->
          <li class="easy__option" v-for="parameter in displayParameters.required.dropdowns" :key="parameter.reference">
            <div class="option__entry">
              <span>{{parameter.name}}</span>
              <select class="easy__dropdown" v-model="requiredParameters.selected[parameter.reference]">
                <option :value="option" :title="option" v-for="option in parameter.options" :key="option">{{option}}</option>
              </select>
            </div>
          </li>
          <!-- Switches -->
          <li class="easy__option" v-for="parameter in displayParameters.required.switches" :key="parameter.reference">
            <div class="option__entry">
              <span>{{parameter.name}}</span>
              <input type="checkbox" :id="parameter.reference" v-model="requiredParameters.selected[parameter.reference]" />
              <label class="advanced__switch" :for="parameter.reference"></label>
            </div>
          </li>
        </ul>
        
      </div>

      <!-- Advanced tab -->
      <div class="para__advanced" v-if="activeTab === 'advanced'">
        <div class="advanced__wrapper">
          <!-- Required advanced -->
          <ul class="easy__options" v-if="displayParameters.required.hasDisplay[1]">
            <li class="easy__instru">{{requiredParameters.instru}}</li>
            <!-- Dropdowns -->
            <li class="easy__option" v-for="parameter in displayParameters.required.dropdowns" :key="parameter.reference">
              <div class="option__entry">
                <span>{{parameter.name}}</span>
                <select class="easy__dropdown" v-model="requiredParameters.selected[parameter.reference]">
                  <option :value="option" :title="option" v-for="option in parameter.options" :key="option">{{option}}</option>
                </select>
              </div>
            </li>
            <!-- Switches -->
            <li class="easy__option" v-for="parameter in displayParameters.required.switches" :key="parameter.reference">
              <div class="option__entry">
                <span>{{parameter.name}}</span>
                <input type="checkbox" :id="parameter.reference" v-model="requiredParameters.selected[parameter.reference]" />
                <label class="advanced__switch" :for="parameter.reference"></label>
              </div>
            </li>
          </ul>
          <h3 class="advanced__title">Pre-processing</h3>
          <ul class="advanced__options">
            <!-- Dropdowns -->
            <li class="advanced__option" v-for="parameter in displayParameters.advanced.first.dropdowns" :key="parameter.reference">
              <span>{{parameter.name}}</span>
              <select class="advanced__dropdown" v-model="selectedAdvParameters['Preprocessing'][parameter.reference]">
                <option :value="option" v-for="option in parameter.options" :key="option">{{option}}</option>
              </select>
            </li>
           <!-- Switches -->
            <li class="advanced__option" v-for="parameter in displayParameters.advanced.first.switches" :key="parameter.reference">
              <span>{{parameter.name}}</span>
              <input type="checkbox" :id="parameter.reference" v-model="selectedAdvParameters['Preprocessing'][parameter.reference]" />
              <label class="advanced__switch" :for="parameter.reference"></label>
            </li>
          </ul>
        </div>

        <div>
          <h3 class="advanced__title">Optimization</h3>
          <ul class="advanced__options">
            <!-- Dropdowns -->
            <li class="advanced__option" v-for="parameter in displayParameters.advanced.second.dropdowns" :key="parameter.reference">
              <span>{{parameter.name}}</span>
              <select class="advanced__dropdown" v-model="selectedAdvParameters['Optimization'][parameter.reference]">
                <option :value="option" v-for="option in parameter.options" :key="option">{{option}}</option>
              </select>
            </li>
           <!-- Switches -->
            <li class="advanced__option" v-for="parameter in displayParameters.advanced.second.switches" :key="parameter.reference">
              <span>{{parameter.name}}</span>
              <input type="checkbox" :id="parameter.reference" v-model="selectedAdvParameters['Optimization'][parameter.reference]" />
              <label class="advanced__switch" :for="parameter.reference"></label>
            </li>
          </ul>
        </div>
      </div>
    </article>

    <button class="para__button" @click="handleContinue">Continue</button>
  </section>
</template>

<script>
import { storeToRefs } from 'pinia';
import { useAiStore } from '../../stores/ai';
import { useUserStore } from '../../stores/user';
  
export default {
  name: "Parameters",
  setup() {
    const aiStore = useAiStore();
    const { currentComponent, analysisType, targetColumn, selectedColumns, objectParameters } = storeToRefs(aiStore);
    const userStore = useUserStore();
    const {fileName, user_id, comp_id, transf_id, dataset_id, analysis, isSessionExpired,} = storeToRefs(userStore);
    const {trigSessVerify,} = userStore;
    return {
      currentComponent,
      analysisType,
      targetColumn, 
      selectedColumns,
      fileName,
      user_id,
      comp_id,
      transf_id, 
      dataset_id,
      analysis,
      objectParameters,
      isSessionExpired,
      trigSessVerify,
    }
  },
  methods: {
    selectTab() {
      if (this.activeTab === "easy") {
        this.activeTab = "advanced";
        return;
      }
      this.activeTab = "easy";
    },
    handleContinue() {
      // Evitar múltiplos clicks:
      if (this.lockButton) {
        return
      }
      this.lockButton = true;
      
      // Se a tab EasyAI estiver selecionada, isto é o que será enviado:
      if(this.activeTab === "easy"){
        let url = process.env.VUE_APP_URL + '/RunML';
        let selectedData = {
          fname:this.fileName,
          customName:this.analysisType.name,
          analysis:this.analysisType.type,
          outputs:this.analysisType.outputs,
          user_id:this.user_id,
          comp_id:this.comp_id,
          transf_id:this.transf_id,
          dataset_id:this.dataset_id,
          type:'EasyAI',
          targetColumn:this.targetColumn,
          selectedColumns:this.selectedColumns,
          easyai_parameters:this.objectParameters.easyai_parameters,
          easyai_parameters_types:this.objectParameters.easyai_parameters_types,
          displays:this.requiredParameters.selected || {},
        };
        this.trigSessVerify();
        fetch(url, {
        method:'POST',
        body:JSON.stringify(selectedData),
        headers: new Headers({
          "content-type":"application/json"
        })
      })
      .then((res) =>res.json())
      .then((content) => {
        console.log(JSON.stringify(content))
        if(content.invalidSession){
          this.isSessionExpired = true;
        }else {
          console.log(content.analysis_id)
          this.analysis = content.analysis_id;
          console.log('Stored Analysis Id: '+this.analysis);
          this.currentComponent = 'Processing';
          this.lockButton = false;
        }
      })
      // Se a tab Advanced estiver selecionada, isto é o que será enviado:
      } else if(this.activeTab === "advanced"){
          let url = process.env.VUE_APP_URL + '/RunAdvML';
        let selectedData = {
          fname:this.fileName,
          customName:this.analysisType.name,
          analysis:this.analysisType.type,
          outputs:this.analysisType.outputs,
          user_id:this.user_id,
          comp_id:this.comp_id,
          transf_id:this.transf_id,
          dataset_id:this.dataset_id,
          type:'Advanced',
          targetColumn:this.targetColumn,
          selectedColumns:this.selectedColumns,
          advanced_parameters:this.selectedAdvParameters,
          advanced_parameters_types:this.objectParameters.advanced_parameters_types,
          displays:this.requiredParameters.selected || {},
        };
        console.log(selectedData)
        this.trigSessVerify();
        fetch(url, {
        method:'POST',
        body:JSON.stringify(selectedData),
        headers: new Headers({
          "content-type":"application/json"
        })
      })
      .then((res) =>res.json())
      .then((content) => {
        console.log(JSON.stringify(content))
        if(content.invalidSession){
          this.isSessionExpired = true;
        }else {
          console.log(content.analysis_id)
          this.analysis = content.analysis_id;
          console.log('Stored Analysis Id (Adv): '+this.analysis);
          console.log('Selected parameters: ', JSON.stringify(this.selectedAdvParameters))
          this.currentComponent = 'Processing';
          this.lockButton = false;
        }
      })
      }
    },
    setInitialParameters() {
      // Setando os valores iniciais dos switches e dropdowns: 
      this.displayParameters.advanced.first.dropdowns.forEach(item => {
        this.selectedAdvParameters['Preprocessing'][item.reference] = item.options[0]
      })
      this.displayParameters.advanced.second.dropdowns.forEach(item => {
        this.selectedAdvParameters['Optimization'][item.reference] = item.options[0]
      })
      this.displayParameters.required.dropdowns.forEach(item => {
        this.requiredParameters.selected[item.reference] = item.options[0]
      })
      this.displayParameters.advanced.first.switches.forEach(item => {
        this.selectedAdvParameters['Preprocessing'][item.reference] = false
      })
      this.displayParameters.advanced.second.switches.forEach(item => {
        this.selectedAdvParameters['Optimization'][item.reference] = false
      })
      this.displayParameters.required.switches.forEach(item => {
        this.requiredParameters.selected[item.reference] = false
      })

      // Adicionado 'donot-render' parameters no objeto a ser enviado para o back:
      for (var attr in this.selectedAdvParameters) {
        this.selectedAdvParameters[attr] = {
          ...this.selectedAdvParameters[attr], 
          ...this.objectParameters.advanced_parameters['donot-render'][attr] || {}
        }
      }
    }
  },
  data() {
    return {
      activeTab: "easy", // 'easy' || 'advanced'
      lockButton: false, // Evitar múltiplos clicks
      requiredParameters: {
        instru: "Please select the required parameters:", // Texto a ser exibido nas options da EasyAI (quando houver)
        selected: {}, // Options obrigatórias selecionadas na EasyAI
      },
      disableAdvanced: false, // Desabilitar a aba advanced
      // Parâmetros selecionados na aba advanced:
      selectedAdvParameters: {
        Preprocessing:{},
        Optimization:{},
      }, 
      // Objeto que renderiza dinamicamente as options da EasyAI (required) e Advanced:
      displayParameters: {
        required: { // EasyAI
          hasDisplay: [],
          dropdowns: [],
          switches: [],
        },
        advanced: {
          first: { // Pre-processing
            dropdowns: [],
            switches: [],
          },
          second: { // Optimization
            dropdowns: [],
            switches: [],
          }
        }
      }
    };
  },
  mounted() {
    // Setando valores de acordo com o objectParameters montado na DataChoice:
    this.displayParameters = this.objectParameters.displayParameters
    this.disableAdvanced = !this.objectParameters.has_advanced
    this.setInitialParameters();
  }
};
</script>

<style>
.para__container {
  background-color: #fff;
  border-radius: 8px;
  padding: 32px;
  max-width: 370px;
  margin: 32px auto;
  animation: surgir 1s;
  box-shadow: 0px 6px 8px -6px rgba(24, 39, 75, 0.12), 0px 8px 16px -6px rgba(24, 39, 75, 0.08);
}
  @keyframes surgir {
    from {
      opacity: 0.5;
      transform: translateY(32px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
.para__tabs {
  padding: 10px;
  border-radius: 60px;
  border: 2px solid var(--secundary-1);
  display: flex;
  gap: 10px;
  position: relative;
  margin-bottom: 32px;
}
.para__tabs.disabled {
  display: none;
}
.para__tab {
  white-space: nowrap;
  font-size: 14px;
}
.para__tab,
.para__tab--background {
  border-radius: 28px;
  padding: 10px 23px;
  color: var(--secundary-3);
  font-weight: 600;
  width: 100%;
  cursor: pointer;
  border: 0;
  background: transparent;
  transition: 0.4s ease;
  position: relative;
  z-index: 2;
}
.para__tab:hover {
  background-color: rgba(0, 0, 159, 0.04);
}
.para__tab.active {
  color: #fff;
}
.para__tab.disabled {
  opacity: 10%;
  pointer-events: none;
}
.para__tab--background {
  background-color: var(--secundary-3);
  position: absolute;
  height: 40px;
  top: 9px;
  z-index: 1;
  width: 136px;
}
.para__tab--background.right {
  left: 156px;
}
.para__tab--background.left {
  left: 10px;
}
@media screen and (max-width: 400px) {
  .para__tab--background {
    display: none;
  }
  .para__tab.active {
    background: var(--secundary-3);
  }
}
@media screen and (max-width: 350px) {
  .para__tab {
    font-size: 10px;
    border-radius: 16px;
  }
  .para__tabs {
    padding: 8px;
  }
}
@media screen and (max-width: 310px) {
  .para__tab {
    font-size: 10px;
    border-radius: 16px;
  }
  .para__tabs {
    flex-direction: column;
    border-radius: 16px;
  }
}
.para__wrapper {
  margin-bottom: 32px;
  overflow: hidden;
}
.para__easy {
  padding: 16px 0;
}
.para__easy,
.para__advanced {
  animation: chegar 0.4s;
}
@keyframes chegar {
  from {
    opacity: 0;
    transform: translateY(8px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.easy__description {
  margin-top: 32px;
}
.easy__description > strong {
  color: var(--secundary-3);
}
.easy__options {
  margin-top: 32px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: 4px;
  border: 1px solid var(--secundary-1);
}
.advanced__wrapper > .easy__options {
  margin-top: 0;
  margin-bottom: 32px;
}
.easy__option {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.easy__instru {
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  color: var(--secundary-3);
  text-align: left;
}
.option__entry {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
}
.option__entry span {
  font-size: 12px;
  font-weight: 500;
}
.easy__dropdown {
  display: block;
  border: 1px solid rgba(223, 223, 223, 0.8);
  border-radius: 4px;
  padding: 6px 20px 6px 8px;
  cursor: pointer;
  width: 100px;
  outline: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background: url(../../assets/img/table/arrowDown.svg) no-repeat center /
    contain;
  background-size: 9px;
  background-position: right 6px center;
  line-height: 16px;
  font-size: 12px;
}
.para__advanced {
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.advanced__title {
  font-size: 18px;
  color: var(--secundary-3);
  margin-bottom: 32px;
  display: flex;
  align-items: center;
  gap: 8px;
}
.advanced__title::after {
  content: "";
  background: url(../../assets/img/ai/icon-help.svg) no-repeat center / contain;
  display: inline-block;
  height: 16px;
  width: 16px;
  cursor: pointer;
}
.advanced__options {
  display: flex;
  flex-direction: column;
  gap: 18px;
}
.advanced__option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  position: relative;
  height: 22px;
}
.advanced__option > span {
  font-weight: 500;
}
.advanced__dropdown {
  display: block;
  border: 1px solid rgba(223, 223, 223, 0.8);
  border-radius: 4px;
  padding: 6px 20px 6px 8px;
  cursor: pointer;
  width: 100px;
  position: absolute;
  right: 0;
  outline: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background: url(../../assets/img/table/arrowDown.svg) no-repeat center /
    contain;
  background-size: 9px;
  background-position: right 6px center;
  line-height: 16px;
  font-size: 14px;
}
.advanced__option > input,
.option__entry > input {
  display: none;
}
.advanced__switch {
  width: 40px;
  height: 22px;
  background-color: #999;
  border-radius: 20px;
  position: relative;
  cursor: pointer;
  transition: 0.4s ease;
}
.advanced__switch::after {
  content: "";
  display: block;
  height: 14px;
  width: 14px;
  border-radius: 50%;
  background-color: #fff;
  position: absolute;
  top: 4px;
  left: 4px;
  transition: 0.2s ease;
}
.advanced__option > input:checked ~ .advanced__switch,
.option__entry > input:checked ~ .advanced__switch {
  background-color: var(--secundary-3);
}
.advanced__option > input:checked ~ .advanced__switch::after,
.option__entry > input:checked ~ .advanced__switch::after  {
  left: 22px;
}
.para__button {
  border-radius: 4px;
  border: 0;
  background-color: var(--primary);
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  width: 100%;
  height: 40px;
  cursor: pointer;
  transition: 0.4s ease;
  position: relative;
}
.para__button.disabled {
  opacity: 0.4;
  cursor: not-allowed;
  pointer-events: none;
}
.para__button::after {
  content: url(../../assets/img/ai/right-arrow.svg);
  position: absolute;
  padding-left: 4px;
  transition: 0.2s ease;
}
.para__button:hover {
  background-color: var(--secundary-3);
}
.para__button:hover::after {
  padding-left: 12px;
}
</style>