<template>
  <article class="steps__container">
    <div class="steps__wrapper">
      <div v-for="(el,i) in stepList" :key="i" class="step" :class="{active: currentIndex == i, completed: currentIndex > i}">
        <div class="step__number">
          {{i + 1}}
        </div>
        <span class="step__name">{{el.step}}</span>
      </div>
    </div>
  </article>
</template>

<script>
export default {
  name: "Steps",
  props: ["currentIndex", "stepList"],
  beforeUpdate() {
    window.scrollTo(0,0)
  }
}
</script>

<style>
  .steps__container {
    margin: 40px 0 8px 0;
    padding: 0 16px;
    height: 80px;
    color: #FFF;
    transform: translateX(6px);
  }

  .steps__wrapper {
    display: flex;
    justify-content: space-between;
    max-width: 560px;
    margin: auto;
  }

  .step {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    cursor: pointer;
    position: relative;
  }

  .step:not(.step:first-of-type)::after {
    content: '';
    height: 4px;
    width: 300%;
    background-color: #999;
    position: absolute;
    top: 17px;
    left: -100px;
    z-index: 1;
    transition: .4s ease;
  }

  .step__number {
    width: 34px;
    height: 34px;
    background: none no-repeat center / contain;
    background-color: #999;
    display: grid;
    place-content: center;
    border-radius: 50%;
    position: relative;
    z-index: 2;
    transition: .8s ease;
  }

  .step.active > .step__number, 
  .step.active:not(.step:first-of-type)::after {
    background-color: var(--primary);
    animation: active 1s ease;
  }

  .step.completed > .step__number, 
  .step.completed:not(.step:first-of-type)::after {
    color: transparent;
    background-color: var(--secundary-3);
  }

  .step.completed > .step__number {
    background: url(../../assets/img/ai/icon-done.svg) no-repeat center / contain;
    background-color: var(--secundary-3);
    background-size: 20px;
    animation: done .6s ease;
  }

  @keyframes done {
    0% {
      transform: rotate(-45deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }

  @keyframes active {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }

  .step__name {
    position: absolute;
    bottom: -24px;
    white-space: nowrap;
    font-size: 12px;
    color: #999;
  }

  .step.active .step__name {
    color: var(--primary);
  }

  .step.completed .step__name {
    color: var(--secundary-3);
  }

  @media screen and (max-width: 500px) {

    .steps__container {
      margin-top: 80px;
    }

    .step__number {
      width: 24px;
      height: 24px;
      font-size: 10px;
    }

    .step:nth-child(2n) .step__name {
      bottom: auto;
      top: -24px;
    }

    .step:not(.step:first-of-type)::after {
      height: 3px;
      width: 16vw;
      top: 12px;
      left: -16vw;
    }
  }
</style>