// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/img/ai/icon-help.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".tabular__container[data-v-5179da12]{margin:auto;margin-top:32px;animation:surgir-5179da12 1s;text-align:center}.title__wrapper[data-v-5179da12]{position:relative;max-width:-moz-fit-content;max-width:fit-content;margin:auto;display:flex;align-items:center}.button__back[data-v-5179da12]{background-color:transparent;border:0;position:absolute;top:0;left:-64px;cursor:pointer}.tabular__title[data-v-5179da12]{font-size:32px;font-weight:600;margin-bottom:32px}.tabular__title strong[data-v-5179da12]{font-weight:600;color:var(--primary)}@keyframes surgir-5179da12{0%{opacity:.5;transform:translateY(32px)}to{opacity:1;transform:translateY(0)}}h2 button[data-v-5179da12]{cursor:pointer;height:20px;width:20px;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 50%/contain;border:0;transition:.4s ease;position:relative;left:9px;top:3px;transition:all 1s ease}h2 button[data-v-5179da12]:hover{transform:scale(1.2)}", ""]);
// Exports
module.exports = exports;
