<template>
  <article class="matrix__wrapper">
    <h3 class="matrix__title">Confusion Matrix</h3>
    <h4 class="matrix__yaxis--title">True Class</h4>
    <table>
      <tr v-for="(serie, j) in tableData.series" :key="serie.name">
        <th class="table__yaxis--name">{{serie.name}}</th>
        <td 
          v-for="(data, i) in serie.data" 
          :title="tableData.absVal.pivot_abs[j][i]"
          :key="data"
          :style="{
            backgroundColor: i === j 
              ? `rgba(0, 159, 83, ${data})` 
              : `rgba(196, 25, 25, ${data})`,
            maxWidth: `${(320/serie.data.length)+16}px`,
            minWidth: `${(320/serie.data.length)+16}px`,
            height: `${(320/serie.data.length)+16}px`,
          }"
        >
          {{tableData.absVal.pivot_abs[j][i]}}
        </td>
      </tr>
      <tr>
        <th></th>
        <th class="table__xaxis--name" v-for="column in tableData.xaxis.columns" :key="column">
          {{column.column_name}}
        </th>
      </tr>
    </table>
    <h4  class="matrix__xaxis--title">Predicted Class</h4>
  </article>
</template>

<script>
import { storeToRefs } from 'pinia';
import { useUserStore } from '../../stores/user';
import { useViewSelector } from '../../stores/viewSelector';

export default {
  name: "Matrix",
  setup() {    
    const userStore = useUserStore();

    const {analysis, isSessionExpired, } = storeToRefs(userStore);
    const {trigSessVerify, } = userStore

    const viewSelector = useViewSelector();
    const {currentView} = storeToRefs(viewSelector);

    return {
      currentView,
      analysis,
      isSessionExpired,
      trigSessVerify
    }
  },
  data() {
    return {
      tableData: {
        "series":[{"data":[0.00,0.00],"name":"category_0"},{"data":[0.00,0.00],"name":"category_1"}],
        "absVal":{"pivot_abs":[[0,0],[0,0]]},
        "xaxis":{"columns":[{"column_name":0},{"column_name":1}]}},
    }
  },
  mounted() {
    let url = process.env.VUE_APP_URL + '/AIResultHeat'
    let nB = {'a_id':this.analysis}
    this.trigSessVerify();
    fetch(url, {
      method:'POST',
      body:JSON.stringify(nB),
      headers: new Headers({
        "content-type":"application/json"
      })
    })
    .then((res) => res.json())
    .then((content) => {
      if(content.invalidSession){
        this.isSessionExpired = true;
      } else if (content.error || content.noRender) {
        return
      } else{
        console.log(content)
        this.tableData = content.heatmap;
      }
    })
    .catch((err) => {console.log('Erro, '+err)}); 
  }
}
</script>

<style scoped>

.matrix__wrapper {
  position: relative;
  width: max-content;
  margin: 0 36px;
  padding-bottom: 24px;
}

table {
  border-collapse: collapse;
  margin: auto;
}

tr {
  position: relative;
}

th {
  font-weight: normal;
  font-size: 12px;
}

td {
  border: 1px solid #E6E6E6;
  font-weight: 500;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 11px;
}

.matrix__title {
  font-size: 14px;
  margin-bottom: 24px;
  font-weight: 800;
  text-align: left;
  transform: translateX(-32px);
}

.matrix__yaxis--title,
.matrix__xaxis--title {
  font-size: 12px;
  position: absolute;
}

.matrix__yaxis--title {
  transform: rotate(-90deg);
  top: calc(50% - 12px);
  left: -60px;
}

.matrix__xaxis--title {
  position: relative;
  text-align: center;
  padding-top: 16px;
  right: -8%;
}

.table__yaxis--name {
  padding-right: 12px;
  font-size: 11px;
}

.table__xaxis--name {
  padding-top: 12px;
  font-size: 11px;
}

::-webkit-scrollbar {
  height: 8px;
  border-radius: 8px;
  width: 8px;
}

::-webkit-scrollbar-track {
  background: transparent;
  margin-left: 24px;
}

::-webkit-scrollbar-thumb {
  background: #bdc5cc;
  border-radius: 8px;
  transition: 0.4s;
}

::-webkit-scrollbar-thumb:hover {
  background: #95b0c7;
}

</style>