<template>
  <section class="table__container">
    <Loading v-if="isLoading" :is-done="isDone" />

    <!-- Modal de erro relacionado com as colunas selecionadas no cluster -->
    <ModalError v-if="showModal" :message="errorMessage" @close-modal="handleShowModal" />

    <div v-if="!isLoading">
      <div class="table__box" :class="{nodata: noData}">
        <div class="table__wrapper">
          <table>
            <span class="dropdown__title" v-if="!noData">{{$t('table.dropdown[0]')}}</span>
            <span class="dropdown__title" v-if="isICP">{{$t('table.dropdown[2]')}}</span>
            <!-- Cabeçalho da tabela (nomes das colunas e botões) -->
            <tr>
              <th v-for="name in columnsName" :key="name">
                <div class="tablehead__options">
                  <!-- Target button (select coluna principal): -->
                  <div v-if="gotTarget" class="tablehead__mainColumn">
                    <input
                      type="radio"
                      name="mainColumn"
                      :value="name"
                      :id="'mc' + name"
                      @input="event => targetColumn = event.target.value"
                    />
                    <label :for="'mc' + name" class="checkmark"></label>
                  </div>
                  <!-- Switch green/red (selecionar ou descartar coluna) -->
                  <div class="tablehead__switch">
                    <input
                      type="checkbox"
                      name="switchColumn"
                      :id="'sw' + name"
                      :value="name"
                      v-model="droppedColumns"
                    />
                    <label :for="'sw' + name" class="checkmark"></label>
                  </div>
                </div>
                <!-- Estilização: uma coluna selecionada como alvo não pode ser marcada como descartada (red switch) -->
                <span :title="name" :class="{columnError: (targetColumn === name && droppedColumns.includes(name)), targetColumn: (targetColumn === name && !droppedColumns.includes(name))}">
                  {{ name }}
                </span>
                <!-- Data type dropdown -->
                <select class="tablehead__dropdown" v-model="selectedDataTypes[name]">
                  <option v-for="option in dataTypesOptions" :key="option" :value="option">{{option}}</option>
                </select>

                <!-- DROPDOWN ICP --> 
                <select class="tablehead__dropdown" v-if="isICP" :class="{dropdownError: verificarDropdownRepetido(name)}"
                v-model="selectedDropdownOptions[name]">
                  <option value="">-</option>
                  <option v-for="linha in dropdown_icp" :key="linha" :value="linha">
                    {{linha}}
                  </option>
                </select>

              </th>
            </tr>
            <!-- Corpo da tabela (dados das colunas renderizados por linha) -->
            <tr v-for="(row, rowIndex) in rowData" :key="rowIndex">
              <td v-for="(data, dataIndex) in rowData[rowIndex]" :key="dataIndex" :title="data">
                {{ data }}
              </td>
            </tr>
          </table>
        </div>
      </div>
  
      <div class="buttons__wrapper">
        <button class="button" @click="handleConfirm">{{$t('table.botao')}}</button>
      </div>
    </div>
  </section>
</template>

<script>
import Loading from '../elements/Loading.vue';
import ModalError from '../elements/ModalError.vue';
import { useAiStore } from '../../stores/ai';
import { useUserStore } from '../../stores/user';
import { storeToRefs } from 'pinia';
export default {
  name: "DataChoice",
  components: {Loading, ModalError},
  setup() {
    const aiStore = useAiStore();
    const { currentComponent, targetColumn, selectedColumns, analysisType, objectParameters, resultDisplay, setModalErrorAI } = storeToRefs(aiStore);

    const userStore = useUserStore();
    const {fileName, originalFileName, user_id, comp_id, transf_id, dataset_id, isSessionExpired, } = storeToRefs(userStore);
    const {trigSessVerify} = userStore;
    return {
      currentComponent,
      fileName,
      originalFileName,
      user_id,
      comp_id,
      transf_id, 
      dataset_id,
      targetColumn,
      selectedColumns,
      analysisType,
      objectParameters, 
      resultDisplay,
      isSessionExpired,
      trigSessVerify,
      setModalErrorAI,
    }
  },
  data() {
    return {
      // Exibir tela de carregamento:
      isLoading: false,
      // Exibir modal de erro:
      showModal: false,
      // Mensagem de erro a ser exibida no modal:
      errorMessage: "Tente novamente em alguns instantes.",
      
      columnsName: [], // Nomes das colunas
      rowData: [], // Array de arrays (dados da tabela divididos por linhas)

      noData:true, // Tabela sem dados (efeito gradiente loading)

      gotTarget:this.analysisType.target,
      // Colunas a serem descartadas:
      droppedColumns: [],
      // Opções do dropdown de tipo de dado:
      dataTypesOptions: [],
      // Objeto onde as keys são os nomes das colunas e os values são os seus tipos de dados selecionados no dropdown:
      selectedDataTypes: {},

      dropdown_icp: [],
      isICP: false, 
      selectedDropdownOptions: {}, //Atrr selecionados
      repeatedAtrr: [], 

    };
  },
  beforeUpdate() {
    /* Todas as operações a baixo são feitas ao mudar algum valor no componente */

    /* Pegando as chaves(Atrr) dos dropdowns no objeto selectedDropdownOptions */
    let objKeys = Object.keys(this.selectedDropdownOptions)
    let values = []

    /* Pegando apenas o valor dessas chaves e adicionando em um vetor */
    for(let i = 0 ; i < objKeys.length ; i ++){
      values.push(this.selectedDropdownOptions[objKeys[i]])
    }

    /* criando um vetor com somente os valores de chaves repetidos */
    let repeated = this.updateRepeatedAtrr(values)

    console.log(repeated)
    this.repeatedAtrr = [...repeated]
  },
  methods: {
    /* Função q recebe o nome da coluna e verifica se o valor de sua chave está dentro
    do vetor de atributos repetidos */
    verificarDropdownRepetido(nome){
      let aux = false
      this.repeatedAtrr.forEach((el)=>{
        if(el === this.selectedDropdownOptions[nome]){
          aux = true
        }
      })
      return aux
    },
    updateRepeatedAtrr(vetor){
      /* Função que recebe um vetor e retorna um vetor sem valores duplicados */
      let repeated = []
      vetor.filter((e , i)=>{
        if(vetor.length <= 1) return 
        if(vetor.indexOf(e) !== i){
          repeated.push(e)
        }
        return vetor.indexOf(e) == i;
      })
      /* Se o valor selecionado for o '-'(null) ñ adicionar no vetor de repetidos */
      let v = repeated.filter((n) => n !== "")
      return v;
    },
    handleShowModal() {
      this.showModal = !this.showModal;
    },
    imprime(){
      console.log("dropdown ICP selecionados:")
      console.log(this.selectedDropdownOptions)
    },
    handleConfirm() {

      //Se tiver algum dropdown ICP repetido mostrar modal de erro
      if(this.isICP === true){
        console.log("Chegou nesse laço if")
        console.log(this.repeatedAtrr)
        
        /* Varre o objeto repeatedAtrr e adiciona o valor das chaves em um vetor */
        let objKeys = Object.keys(this.repeatedAtrr)
        let values = []
        for(let i = 0 ; i < objKeys.length ; i ++){
          values.push(this.repeatedAtrr[objKeys[i]])
        }

        /* Se o vetor tiver algum valor, há dropdowns duplicados e deve mostrar o modal de erro */
        if(values.length > 0){
          this.errorMessage = this.$i18n.t("modalError.errors[0]")
          this.handleShowModal();
          return
        }
      }

      //procurando por atributos com valor null nos dropdowns selecionados e removendo
      let atributosDropdownIcp = Object.keys(this.selectedDropdownOptions)
      for (let i = 0; i < atributosDropdownIcp.length; i++) {
        if(this.selectedDropdownOptions[atributosDropdownIcp[i]] === ""){
          delete this.selectedDropdownOptions[atributosDropdownIcp[i]]
        }
      }

      // Se nenhuma coluna tiver sido marcada como alvo ou se a coluna alvo tiver sido marcada como descartada (red switch), exibir erro:
      if(this.gotTarget){
        if (this.targetColumn === "" || this.droppedColumns.includes(this.targetColumn)) {
          this.errorMessage = this.$i18n.t("modalError.errors[3]")
          this.handleShowModal();
          return
        }
      } 
      this.isLoading = true
      
      // Filtrar apenas os datatypes de colunas não dropadas
      const filteredDataType = 
      Object.entries(this.selectedDataTypes)
        .filter(array => !this.droppedColumns.includes(array[0]))

      // Armazenar na store as colunas selecionadas e seus datatypes:
      this.selectedColumns = Object.fromEntries(filteredDataType);
      console.log('Colunas Filtradas na store: '+this.selectedColumns)

      let url = process.env.VUE_APP_URL + '/AITableConfirm';
      let selectedData = {fname:this.fileName, dataset_id:this.dataset_id,
       user_id:this.user_id, comp_id:this.comp_id, selectedDataTypes:this.selectedColumns,
       displays:this.objectParameters.display_parameters, targetColumn:this.targetColumn ,
       icp_analysis_column:this.selectedDropdownOptions};
      console.log(JSON.stringify(selectedData))
      this.trigSessVerify();
      fetch(url, {
        method:'POST',
        body:JSON.stringify(selectedData),
        headers: new Headers({
          "content-type":"application/json"
        })
      })
      .then((res) =>res.json())
      .then((content) => {
        console.log(content)

        if(content.statusError != 2000000){
          let url2 = process.env.VUE_APP_URL + '/getError';
          let nb = {"errorID": content.statusError , "lang": "pt"}

          fetch(url2 , {
            method: 'POST',
            body: JSON.stringify(nb),
            headers: new Headers({
              "content-type": "application/json"
            })
          })
          .then(res => res.json())
          .then((content) => {
            console.log(content)
            this.setModalErrorAI.msg = content.errorText
            this.setModalErrorAI.title = content.errorTitle
          })


          this.setModalErrorAI.show = true
          console.log("Realmente deu erro: "+content.statusError)
        }
        // Caso exista 'displays' a serem exibidos na Parameters.vue
        if(Object.keys(content.displays).length){
          // Pegar as options e inserir em cada dropdown do 'display' da Parameters:
          let response = content.displays
          console.log(response)
          for (var parameter in response) {
            let index = this.objectParameters.displayParameters.required.dropdowns.findIndex(item => item.reference == parameter)
            this.objectParameters.displayParameters.required.dropdowns[index].options = response[parameter]
          }

        }
        this.transf_id=content.transf_id;
        this.currentComponent = 'Parameters';
      })
    },
  },
  mounted() {
    // Resetar coluna alvo e colunas selecionadas da store:
    this.targetColumn = "";
    this.selectedColumns = ""; 
    console.log('this.analysisType = ' + this.analysisType.target)
    console.log('this.gotTarget = ' + this.gotTarget)
    this.gotTarget = this.analysisType.target;
    console.log('this.gotTarget2 = ' + this.gotTarget)
    // Montar o objectParameters para renderizar dinamicamente os elementos da Parameters.vue:
    let url2 = process.env.VUE_APP_URL + '/getParameters';
    let chosenAnalysis = {analysis:this.analysisType.type}
    fetch(url2, {
      method:'POST',
      body:JSON.stringify(chosenAnalysis),
      headers: new Headers({
        "content-type":"application/json"
      })
    })
    .then((res) =>res.json())
    .then((content) => {
      console.log(content)
      this.objectParameters = content;
    })

    // Receber o resultado da leitura do arquivo enviado para o bucket e montar a renderização da tabela:
    let url = process.env.VUE_APP_URL + '/AITablePreview';
    console.log('(stored) file name: '+this.fileName)
    console.log('(stored) user_id: '+this.user_id)
    console.log('(stored) comp_id: '+this.comp_id)
    
    let linkQs = {fname:this.fileName, analysis:this.analysisType.type, original_fname:this.originalFileName, user_id:this.user_id, comp_id:this.comp_id};
    console.log('links: '+JSON.stringify(linkQs))
    this.trigSessVerify();
    fetch(url, {
      method:'POST',
      body:JSON.stringify(linkQs),
      headers: new Headers({
        "content-type":"application/json"
      })
    })
    .then((res) =>res.json())
    .then((content) => {
      if(content.invalidSession){
        this.isSessionExpired = true;
      } else{
        this.noData = false;
        console.log("Mounted:", content)
        this.dataset_id = content.dataset_id;
        this.columnsName = content["Name of Columns"];
        this.rowData = content["Dataframe"];

        if(content.errorAPI === true){
          console.log("Erro no backend da AI")
          this.setModalErrorAI.show = true
          this.setModalErrorAI.msg = "Error"
          this.setModalErrorAI.tittle = "Oopss, something went wrong"
        }

        if(content["DropdownICP"]){
          this.dropdown_icp = content["DropdownICP"];
          console.log("É UMA ANÁLISE DE ICP")
          this.isICP = true;
        }

        // this.selectedColumns = content["Name of Columns"];
        this.dataTypesOptions = content["Dropdown"];
        console.log('this.dataTypesOptions: '+JSON.stringify(this.dataTypesOptions))
        this.selectedDataTypes = content["Types"];

        /* types.forEach((type, index) => {
          const column = this.columnsName[index];
          this.selectedDataTypes[column] = type
        }); */
      }
    })
  }
};
</script>

<style scoped>
.table__container {
  margin: 0 auto;
  margin-top: 32px;
  animation: surgir 1s;
  width: 100%;
  min-width: 70vw;
  max-width: min-content;
}

@media screen and (max-width: 767px) {
  .table__container {
    min-width: 100%;
  }
}

@keyframes surgir {
  from {
    opacity: 0.5;
    transform: translateY(32px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.table__titulo {
  font-weight: 600;
  margin-bottom: 32px;
}

.table__box {
  width: 100%;
  background-color: #fff;
  border-radius: 6px;
  padding: 24px 16px 16px 0px;
  max-width: calc(100vw - 96px - 48px - 16px);
  box-shadow: 0px 6px 8px -6px rgba(24, 39, 75, 0.12), 0px 8px 16px -6px rgba(24, 39, 75, 0.08);
}

.table__wrapper {
  width: 100%;
  height: 50vh;
  overflow: scroll;
}

.table__box.nodata {
  padding: 16px;
}

.table__box.nodata > .table__wrapper {
  display: inline-block;
  position: relative;
  overflow: hidden;
  border-radius: 4px;
}

.table__box.nodata > .table__wrapper::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(
    90deg,
    rgba(228, 235, 242, 0) 0,
    rgba(213, 224, 238, 0.2) 20%,
    rgba(213, 224, 238, 0.4) 60%,
    rgba(228, 235, 242, 0)
  );
  animation: shimmer 2s infinite;
  content: '';
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

::-webkit-scrollbar {
  height: 8px;
  border-radius: 4px;
  width: 8px;
}

::-webkit-scrollbar-track {
  background: transparent;
  margin-left: 24px;
}

::-webkit-scrollbar-thumb {
  background: #bdc5cc;
  border-radius: 4px;
  transition: 0.4s;
}

::-webkit-scrollbar-thumb:hover {
  background: #95b0c7;
}

.buttons__wrapper {
  display: flex;
  justify-content: center;
  margin-top: 32px;
  gap: 24px;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary);
  border: 2px solid var(--primary);
  font-family: inherit;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  height: 40px;
  width: 100%;
  max-width: 220px;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.2s ease;
}

.button.disabled {
  opacity: 0.4;
  cursor: not-allowed;
  pointer-events: none;
}

.button:hover {
  background-color: var(--secundary-3);
  border-color: var(--secundary-3);
}

.button__secundary {
  background-color: transparent;
  color: var(--primary);
}

.button.button__secundary:hover {
  background-color: transparent;
  border-color: var(--secundary-3);
  color: var(--secundary-3);
}

@media screen and (max-width: 767px) {
  .table__box {
    max-width: calc(100vw - 32px);
  }

  .buttons__wrapper {
    gap: 12px;
  }
}

/* Tabela */

table {
  width: 100%;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  padding-left: 64px;
  position: relative;
}

tr {
  display: block;
}

tr:nth-child(odd) {
  background-color: rgba(228, 235, 242, 0.4);
}

th,
td {
  min-width: 120px;
  max-width: 120px;
  /* height: 40px; */
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 16px 8px;
  white-space: nowrap;
}

th {
  font-weight: 600;
  text-transform: uppercase;
  height: auto;
  padding-top: 0;
  padding-bottom: 8px;
  white-space: nowrap;
}

th > span {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Inputs customizados */

.tablehead__options {
  display: flex;
  align-items: center;
  width: fit-content;
  margin-left: auto;
  margin-bottom: 10px;
}

.tablehead__mainColumn > input,
.tablehead__switch > input {
  display: none;
}

.tablehead__mainColumn input ~ .checkmark {
  display: block;
  height: 16px;
  width: 16px;
  background: url(../../assets/img/table/targetOff.svg) no-repeat center /
    contain;
  cursor: pointer;
  margin-right: 6px;
  transition: 0.4s ease;
}

.tablehead__mainColumn .checkmark:hover {
  filter: brightness(0.6);
}

.tablehead__mainColumn input:checked ~ .checkmark {
  background-image: url(../../assets/img/table/targetOn.svg);
}

.tablehead__switch input ~ .checkmark {
  display: block;
  height: 12px;
  width: 24px;
  background: url(../../assets/img/table/switchOn.svg) no-repeat center /
    contain;
  cursor: pointer;
  transition: 0.1s linear;
}

.tablehead__switch input:checked ~ .checkmark {
  background-image: url(../../assets/img/table/switchOff.svg);
}

.tablehead__dropdown {
  display: block;
  border: 1px solid rgba(223, 223, 223, 0.8);
  border-radius: 4px;
  padding: 8px 20px 8px 8px;
  margin: 8px 0;
  cursor: pointer;
  transform: translateX(-7px);
  width: calc(100% + 7px);
  position: relative;
  outline: none;
  -moz-appearance:none;
  -webkit-appearance: none; 
  appearance: none;
  background: url(../../assets/img/table/arrowDown.svg) no-repeat center / contain;
  background-size: 9px;
  background-position: right 6px center;
  line-height: 16px;
}

.tablehead__dropdown.dropdownError {
  border-color: rgba(255, 0, 0, 0.4);
  background-color: rgba(255, 0, 0, 0.04);
  color: #db0606;
}

span.columnError {
  color: #db0606;
}

span.targetColumn {
  color: var(--primary);
}

.dropdown__title {
  position: absolute;
  font-size: 10px;
  max-width: 44px;
  text-align: center;
  top: 57px;
  left: 12px;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 600;
}

.dropdown__title:nth-of-type(2) {
  top: 98px;
}


</style>