// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/img/ai/icon-help.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".ai__container[data-v-60be62c0]{margin-top:32px;text-align:center;font-size:16px;animation:surgir-60be62c0 1s}@keyframes surgir-60be62c0{0%{opacity:.5}to{opacity:1}}.ai__title[data-v-60be62c0]{font-weight:600;font-size:2em}.ai__description[data-v-60be62c0]{font-weight:400;font-size:1.25em;margin-top:8px}.ai__description>strong[data-v-60be62c0]{color:var(--primary);font-weight:600;cursor:pointer;white-space:nowrap}.ai__description button[data-v-60be62c0]{cursor:pointer;height:20px;width:20px;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 50%/contain;border:0;transform:translate3d(8px,3px,0);transition:.4s ease}.ai__description>strong:hover button[data-v-60be62c0]{transform:translate3d(8px,3px,0) scale(1.2)}@media screen and (max-width:767px){.ai__container[data-v-60be62c0],.analysis__card[data-v-60be62c0]{font-size:12px}}", ""]);
// Exports
module.exports = exports;
