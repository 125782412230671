// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/img/ai/icon-help.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".container[data-v-45e6e675]{position:relative}.title[data-v-45e6e675]{font-size:24px;border-top:2px solid #e2e2e2;padding-top:28px;margin:32px 0;font-weight:600}.back-button[data-v-45e6e675]{position:absolute;left:0;top:26px;background:transparent;border:0;cursor:pointer}h3 button[data-v-45e6e675],label button[data-v-45e6e675]{cursor:pointer;height:20px;width:20px;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 50%/contain;border:0;transition:.4s ease;position:relative;left:9px;top:3px}.graph_container[data-v-45e6e675]{background-color:#fff;width:100%;height:500px;border-radius:16px;padding:32px;display:flex;flex-direction:column;gap:32px}.more_details[data-v-45e6e675]{margin:50px 0}.more_details button[data-v-45e6e675]{margin-top:10px;background:transparent;border:2px solid var(--primary);color:var(--primary);padding:10px}.table_box[data-v-45e6e675]{background-color:#fff;border-radius:16px;padding:32px;display:flex;flex-direction:column;gap:32px;width:100%;overflow:scroll}[data-v-45e6e675]::-webkit-scrollbar{height:8px;border-radius:8px;width:8px}[data-v-45e6e675]::-webkit-scrollbar-track{background:transparent}[data-v-45e6e675]::-webkit-scrollbar-thumb{background:#bdc5cc;border-radius:8px;-webkit-transition:.4s;transition:.4s}[data-v-45e6e675]::-webkit-scrollbar-thumb:hover{background:#95b0c7}table[data-v-45e6e675]{width:100%;font-family:Montserrat,sans-serif;font-size:14px;position:relative}tr[data-v-45e6e675]{display:inline-block}tr[data-v-45e6e675]:nth-child(2n){background-color:rgba(228,235,242,.4)}td[data-v-45e6e675],th[data-v-45e6e675]{min-width:180px;max-width:180px;text-align:left;overflow:hidden;text-overflow:ellipsis;padding:16px 8px}td[data-v-45e6e675],th[data-v-45e6e675]{white-space:nowrap}th[data-v-45e6e675]{font-weight:600;text-transform:uppercase;height:auto}td>input[data-v-45e6e675]{background-color:transparent;border:0;border-bottom:1px solid #a2a4a4;max-width:100%;outline:0;padding:4px}", ""]);
// Exports
module.exports = exports;
