<template>
  <div :class="heatmapWrap">
    <apexchart type="heatmap" :options="options" :series="series"></apexchart>
  </div>
</template> 

<script>
import VueApexCharts from "vue3-apexcharts";
import { useViewSelector } from "../../stores/viewSelector";
import { useUserStore } from '../../stores/user';
import { storeToRefs } from 'pinia';
export default{
  name: 'heatMapChart',
  components:{
    apexchart: VueApexCharts,
  },
  setup() {
    const viewSelector = useViewSelector();
    const {currentView} = storeToRefs(viewSelector);
    
    const userStore = useUserStore();
    const {user_id, comp_id, isSessionExpired} = storeToRefs(userStore);
    const { trigSessVerify } = userStore;

    return {
      user_id,
      comp_id,
      currentView,      
      isSessionExpired,
      trigSessVerify,
    }
  },
  data(){
    return {
      absData:[],
      title: {
        text: 'Heatmap'
      },
      noData:{
        text:'Loading...',
      },
      series:[{
            data: [],
            name: '',
          }, 
      ],
    }
  },  
  watch: {
    translation(t) {
      this.options = {
       ...this.options,
       ...{
          title: {
            text: t.title,
          },
        }
      }
    }
  },
  computed:{
    translation: function() {
      return ({
        title: this.$i18n.t("graphs.heat.title"),
        tooltip: [
          this.$i18n.t("graphs.heat.tooltip[0]"),
          this.$i18n.t("graphs.heat.tooltip[1]"),
          this.$i18n.t("graphs.heat.tooltip[2]")
        ]
      })
    },
    options: function(){
      return {
        chart: {
          type:'heatmap',
          id: 'vueHMChart',
          width:'100%',
          height:'100%',
        },
        xaxis: {
          type:'categories',
          categories:[]
        },
        colors:['#016833',],
        enableShades:false,
        dataLabels:{
          enabled:true,
          background:{
            enabled:true,
            padding:10,
          },
          style:{
            fontSize:'9px',
          },
          formatter:function(val){
            return val + '%'
          }
        },
        title: {
          text: this.translation.title,
        },
        tooltip: {
          enabled:true,
          x: {
            show: true,
            formatter: (value) => `${this.translation.tooltip[0]} ${value}`
          },
          y:{
            title: {
              formatter: seriesName => `Cohort ${seriesName}:`
            },
            formatter: (value, obj) => `${this.absData[obj.seriesIndex][obj.dataPointIndex]} ${this.translation.tooltip[1]} (${value}% ${this.translation.tooltip[2]})`,
          },
        },
        responsive: [
          {
            breakpoint: 640,
            options: {
              dataLabels:{
                enabled: false,
              },
              grid: {
                padding: {
                  bottom: -12
                }
              },
              chart: {
                toolbar: {
                  tools: {
                    zoom: false,
                    zoomin: false,
                    zoomout: false,
                    pan: false,
                    reset: false,
                  }
                },
              },
              yaxis: {
                show: false,
              },
            }
          }
        ]
      } 
    }
  },
  mounted() {
    let url = process.env.VUE_APP_URL + '/heatmap';
    let nB = {u_id:this.user_id ,c_id:this.comp_id }
    fetch(url, {
      method:'POST',
      body:JSON.stringify(nB),
      headers: new Headers({
        "content-type":"application/json"
      })
    })
    .then((res) => res.json())
    .then((content) => {
      console.table(content);
      if(content.invalidSession){
        this.currentView = 'Login';
      }else{
        this.absData = content.absData;
        this.options = {
        ...this.options,
        ...{
            xaxis:{
              categories: content.categories,


            },
          }
        }
        content.series.forEach((el, i) => {
          this.series[i] = el
        });
      }
    })
    .catch((err) => {console.log('Erro, '+err)}); 
  },
}
</script>

<style>
.heatmapWrap{
  width: 100%;
  height: 100%;
  position: relative;
  padding: 0px;
  margin: 0px;
}
</style>