<template>
  <section class="upload__container">
    <Loading v-if="!form" :is-done="false" />
    <div v-if="form" class="upload">
      <!-- Área de drag and drop de arquivos -->
      <div class="upload__drop" :class="{ 'upload__drop--active': isDroping }" @dragenter.prevent="toggleIsDroping" @dragleave.prevent="toggleIsDroping" @dragover.prevent @drop.prevent="drop">
        <img
          src="../../assets/img/fileUpload/feather_upload-cloud.svg"
          alt="Upload icon"
        />
        <p>{{$t('upload.drop[0]')}}</p>
        <label title="Select file" for="file">{{$t('upload.drop[1]')}}</label>
        <input type="file" name="file" id="file" accept=".xlsx, .csv, .parquet, .pkl, .pickle" @input="onFileSelected" />
      </div>
      <!-- Lista de arquivos carregados e suas infos -->
      <div class="upload__files" v-if="filesList !== []">
        <div
          class="upload__file"
          v-for="file in filesList"
          :key="file.lastModified"
        >
          <div class="upload__file--icon">
            <img
              src="../../assets/img/fileUpload/akar-icons_file.svg"
              alt="File icon"
            />
          </div>

          <div class="upload__file--info">
            <div class="file__info">
              <span class="file__info--name">
                {{ file.name }}
              </span>
              <span>{{ (file.size / 1024).toFixed(2) }}kB</span>
            </div>
            <div class="file__progress">
              <progress :value="file.progress" max="100">
                {{ file.progress }}%
              </progress>
            </div>
          </div>

          <div class="upload__file--button">
            <button @click="removeFile(file)">
              <img
                src="../../assets/img/fileUpload/trash-icon.svg"
                alt="Button icon"
              />
            </button>
          </div>
        </div>
      </div>

      <button
        :class="{ disabled: disabledButton }"
        :disabled="disabledButton"
        @click="sendFile"
      >
        Upload
      </button>

      <div v-if="sent" class="upload__gif">
        <img :src="require(`../../assets/img/fileUpload/loading.gif`)" />
      </div>
    </div>
  </section>
</template>

<script>
import { storeToRefs } from 'pinia';
import { useAiStore } from '../../stores/ai';
import { useUserStore } from '../../stores/user';
import Loading from '../elements/Loading.vue';
export default {
  name: "Upload",
  components: {Loading},
  setup() {
    const aiStore = useAiStore();
    const { currentComponent } = storeToRefs(aiStore);

    const userStore = useUserStore();
    const {bucket, fileName, user_id, comp_id, empresa, originalFileName, isSessionExpired} = storeToRefs(userStore);
    const { trigSessVerify } = userStore;
    return {
      currentComponent, 
      bucket, 
      fileName, 
      user_id, 
      comp_id, 
      empresa,
      originalFileName,
      isSessionExpired,
      trigSessVerify,
    }
  },
  data() {
    return {
      filesList: [],
      form: true, // Exibir upload form (true) ou loading (false)
      sent: false,
      isDroping: false,
    };
  },
  methods: {
    toggleIsDroping() {
      this.isDroping = !this.isDroping
    },
    drop(event) {
      this.toggleIsDroping();
      const file = event.dataTransfer.files[0];
      this.filesList.push(file);
    },
    sendFile() {
      let dataForm = new FormData();
      let file = this.filesList[0];
      this.originalFileName = file.name
      dataForm.append("fileName", file.name);
      console.log(this.originalFileName);
      dataForm.append("file", file);
      dataForm.append("u_id", this.user_id);
      dataForm.append("c_id", this.comp_id);
      dataForm.append("company", this.empresa);
      console.log(file);
      this.form = false;
      let url = process.env.VUE_APP_URL + '/AIupload';
      this.trigSessVerify();
      fetch(url, {
        method:'POST',
        body:dataForm,
      })
      .then((res) => res.json())
      .then((content) => {
        console.log(JSON.stringify(content))
        if(content.invalidSession){
          this.isSessionExpired = true;
        }else {
          this.bucket = content.bName;
          this.fileName = content.filename;
          console.log(this.bucket)
          console.log('AI file named: '+this.fileName)
          this.currentComponent = 'DataChoice'
        }
      })
    },
    onFileSelected(event) {
      const file = event.target.files[0];
      if (file) {
        console.log(file);
        this.filesList.push(file);
      }
    },
    removeFile(targetFile) {
      this.filesList = this.filesList.filter((file) => file.name !== targetFile.name);
    },
  },
  computed: {
    disabledButton: function () {
      return this.filesList.length !== 1;
    },
  },
};
</script>

<style scoped>
.upload__container {
  margin: auto;
  margin-top: 32px;
  max-width: 560px;
  animation: surgir 1s;
}

@keyframes surgir {
  from {
    opacity: 0.5;
    transform: translateY(32px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.upload__titulo {
  font-weight: 600;
  margin-bottom: 32px;
}

.upload {
  text-align: center;
  padding-bottom: 80px;
  position: relative;
}

.upload__drop {
  height: 290px;
  box-sizing: border-box;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='16' ry='16' stroke='%23009F53' stroke-width='4' stroke-dasharray='16' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 16px;
  transition: .4s ease;
}

.upload__drop--active {
  background-color: rgba(39, 94, 246, 0.2);
}

.upload__drop > p {
  margin-top: 32px;
  margin-bottom: 16px;
  font-weight: 500;
  color: var(--primary);
}

.upload__drop > label,
.upload > button {
  border: 2px solid var(--primary);
  color: var(--primary);
  font-family: inherit;
  font-weight: 600;
  font-size: 14px;
  background: transparent;
  border-radius: 4px;
  width: 100%;
  max-width: 200px;
  height: 40px;
  cursor: pointer;
  display: grid;
  place-items: center;
  margin: 0 auto;
}

input[type="file"] {
  display: none;
}

.upload__files {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  margin-bottom: 24px;
}

.upload > button {
  border: 0;
  background-color: var(--primary);
  color: #fff;
  transition: .4s ease;
}

.upload > button:hover {
  background-color: var(--secundary-3);
}

.upload > button.disabled {
  visibility: hidden;
}

.upload__file {
  background: #ffffff;
  border-radius: 16px;
  min-height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
}

.upload__file--info {
  width: 100%;
  margin-left: 16px;
  margin-right: 40px;
}

.file__info {
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.file__info--name {
  display: block;
  margin-right: 8px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

.upload__gif {
  max-width: 40px;
  margin-top: 16px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
}

.upload__gif > img {
  width: 100%;
}

@media screen and (max-width: 480px) {
  .file__info--name {
    max-width: 80px;
  }
}

@media screen and (max-width: 360px) {
  .file__info--name {
    max-width: 40px;
  }
}

.file__progress > progress {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  margin-top: 14px;
}

.file__progress > progress::-webkit-progress-bar {
  background: #ececec;
  border-radius: 8px;
  height: 8px;
}

.file__progress > progress::-webkit-progress-value {
  background: var(--primary);
  border-radius: 8px;
  height: 8px;
}

.file__progress > progress::-moz-progress-bar {
  background: #ececec;
  border-radius: 8px;
  height: 8px;
}

.upload__file--button > button {
  background: transparent;
  border: 0;
  cursor: pointer;
}
</style>