<template>
    <apexchart type="line" :options="options" :series="series"></apexchart>
</template> 

<script>
import VueApexCharts from "vue3-apexcharts";
import { useViewSelector } from "../../stores/viewSelector";
import { useUserStore } from '../../stores/user';
import { storeToRefs } from 'pinia';
export default{
  name: 'mixSurvivalChart',
  components:{
    apexchart: VueApexCharts,
  },
  setup() {
    const viewSelector = useViewSelector();
    const {currentView} = storeToRefs(viewSelector);

    const userStore = useUserStore();
    const {user_id, comp_id, isSessionExpired} = storeToRefs(userStore);
    const { trigSessVerify } = userStore;
 

    return {
      user_id,
      comp_id,
      currentView,
      isSessionExpired,
      trigSessVerify,
    }
  },
  data(){
    return {
      options: {
        chart: {
          type:'line',
          id: 'mixSurvivalChart',
          width:'100%',
        },
        xaxis: {
          type:'categories',
          categories:[],
          tickAmount: 10,
        },
        colors:['#7789A4','#0B9719','#BA2D0D'],
        yaxis: [
          {
            seriesName: 'Chance de Sobrevivência',
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
            },
            title: {
              text: "Chance de Sobrevivência",
            },
            tickAmount: 3,
            min: () => Math.floor(Math.min(...this.series[0].data)/0.2)*0.2,
            max: 1,
            labels: {
              formatter: (val) => `${(val*100 || 0).toFixed(0)}%` 
            },
          },
          {
            seriesName: 'Churn (%)',
            opposite: true,
            tickAmount: 4,
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
            },
            title: {
              text: "Churn (%)",
            },
            labels: {
              formatter: (val) => `${(val || 0).toFixed(0)}%` 
            },
          },
        ],
        dataLabels:{
          enabled:true,
          enabledOnSeries: [1]
        },
        title: {
          text: "Análise da Curva de Sobrevivência",
        },
        responsive: [
          {
            breakpoint: 640,
            options: {
              chart: {
                toolbar: {
                  tools: {
                    zoom: false,
                    zoomin: false,
                    zoomout: false,
                    pan: false,
                    reset: false,
                  }
                },
              },
              stroke: {
                width: 2,
              },
              dataLabels: {
                enabled: false,
              },
              xaxis: {
                type:'categories',
                categories:[],
                tickAmount: 10,
                labels: {
                  style: {
                    fontSize: '11px',
                  }
                },
              },
              yaxis: [
                {
                  tickAmount: 3,
                  // min: () => Math.floor(Math.min(...this.series[0].data)/0.2)*0.2,
                  max: 1,
                  title: {
                    text: undefined,
                  },
                  labels: {
                    formatter: (val) => `${(val*100 || 0).toFixed(0)}%` 
                  },
                },
                {
                  opposite: true,
                  tickAmount: 4,
                  title: {
                    text: undefined,
                  },
                  labels: {
                    formatter: (val) => `${(val || 0).toFixed(0)}%` 
                  },
                }
              ],
            }
          }
        ]
      },
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 10,
        },
      },
      noData:{
        text:'Loading...',
      },
      legend: {
        position: 'right',
        offsetY: 40,
      },
      series: [],
    }
  },  

  computed: {
    translation: function() {
      return ({
        title: this.$i18n.t("graphs.mix.title"),
        serie: this.$i18n.t("graphs.mix.serie"),
      })
    }
  },
  watch: {
    translation(t) {
      this.options = {
       ...this.options,
       ...{
          title: {
            text: t.title,
          },
          yaxis: [
            {
              ...this.options.yaxis[0],
              seriesName: t.serie,
              title: {
                text: t.serie,
              },
            },
            {
              ...this.options.yaxis[1],
            }
          ],
        }
      }

      this.series[0].name = t.serie;
    }
  },
    
  mounted() {
    let url = process.env.VUE_APP_URL + '/survival';
    let nB = {u_id:this.user_id ,c_id:this.comp_id }
    fetch(url, {
      method:'POST',
      body:JSON.stringify(nB),
      headers: new Headers({
        "content-type":"application/json"
      })
    })
    .then((res) =>res.json())
    .then((content) => {
      console.log(content);
      console.log(JSON.stringify(content));
      if(content.invalidSession){
        this.currentView = 'Login';
      }else{
        this.options = {
        ...this.options,
        ...{
            xaxis:{
              categories: content.categories 
            },
            title: {
              text: this.translation.title,
            },
            yaxis: [
              {
                ...this.options.yaxis[0],
                seriesName: this.translation.serie,
                title: {
                  text: this.translation.serie,
                },
              },
              {
                ...this.options.yaxis[1],
              }
            ],
          }
        }

        content.series.forEach((el, i) => {
          this.series[i] = el
        });

        this.series[0].name = this.translation.serie;
      }
    })
    .catch((err) => {console.log('Erro, '+err)}); 
    
  },
}
</script>


<style>
</style>