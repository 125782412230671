<template>
  <article class="manual__container">
    <div class="title__wrapper">
      <button title="Back to previous page" aria-label="Back to previous page" class="button__back" @click="backPage">
        <img src="../../../assets/img/ai/icon-back-arrow.svg" alt="Back to previous page">
      </button>
      <h3 class="manual__title">{{$t('newPrediction.entryManually.title')}}</h3>
    </div>
  
    <div class="table__container">
      <div class="table__wrapper">
        <table>
          <tr>
            <th v-for="column in tableContent.columnsName" :key="column">
              <span :title="column[0]">{{column[0]}}</span>
              <span :title="column[1]">{{column[1]}}</span>
            </th>
          </tr>
          <tr v-for="(row, i) in tableContent.rowsData" :key="row" class="table__rowdata">
            <button class="delete__button" title="Delete row" aria-label="Delete row" @click="handleDeleteRow(i)"></button>
            <td v-for="(data, j) in row" :key="data" :title="data">
              <input type="text" @blur="e => tableContent.rowsData[i][j] = e.target.value" placeholder="Insert a value" :value="data">
            </td>
          </tr>
        </table>
      </div>
      <button @click="handleNewRow" class="newrow__button" title="Add new row" aria-label="Add new row">
        <img src="../../../assets/img/ai/icon-plus.svg" alt="Add new row">
      </button>
    </div>
    <div class="table__buttons">
      <button @click="handlePredict" class="predict__button">
        {{$t('newPrediction.entryManually.btnConfirm')}}
      </button>
    </div>
  </article>
</template>

<script>
import { storeToRefs } from 'pinia';
import { useAiStore } from '../../../stores/ai';
import { useUserStore } from '../../../stores/user';

export default {
  name: "EntryManually",
  components: {},
  setup() {
    const aiStore = useAiStore();
    const { showEntryMenu, showDataEntry, selectedColumns, targetColumn } = storeToRefs(aiStore);
    const { handlePredictAPI } = aiStore;

    const userStore = useUserStore();
    const { user_id, empresa, comp_id, } = storeToRefs(userStore);
    return {
      showEntryMenu,
      showDataEntry,
      selectedColumns, 
      targetColumn,
      handlePredictAPI,
      user_id,
      empresa,
      comp_id,
    }
  },
  data() {
    return {
      tableContent: {
        columnsName: [],
        rowsData: [],
      },

      /* Para o modal de erro */
      errorMessage: "",
    }
  },
  methods: {
    backPage() {
      this.showEntryMenu = true;
    },
    // Deletar linha:
    handleDeleteRow(index) {
      this.tableContent.rowsData.splice(index, 1);
    },
    // Criar nova linha:
    handleNewRow() {
      let newRow = [];

      for (let i = 0; i < Object.entries(this.selectedColumns).length; i++) {
        newRow.push('');
      }

      this.tableContent.rowsData.push(newRow)
    },
    handlePredict() {
      this.tableContent.rowsData = this.tableContent.rowsData.filter(row => !row.every(data => data === ""))
      if (this.tableContent.rowsData.length !== 0) {
        this.tableContent.user_id = this.user_id;
        this.tableContent.company_id = this.comp_id;
        this.tableContent.company = this.empresa;
        this.tableContent.pipeline = 'mlManualEntry'
        this.handlePredictAPI(this.tableContent);
      } else {
        alert("Please insert some data!")
        this.handleNewRow();
      }
    },
  },

  mounted() {
    this.tableContent.columnsName =  Object.entries(this.selectedColumns)
    this.handleNewRow();
  }
}
</script>

<style scoped>
.title__wrapper {
  position: relative;
  max-width: fit-content;
  margin: auto;
  display: flex;
  align-items: center;
  margin-bottom: 64px;
}

.button__back {
  background-color: transparent;
  border: 0;
  position: absolute;
  top: -2px;
  left: -64px;
  cursor: pointer;
}

.manual__title {
  font-size: 24px;
  font-weight: 500;
  color: #6b6b6b;
}

.table__container {
  margin: auto;
  background-color: #FFF;
  padding: 24px;
  padding-bottom: 8px;
  border-radius: 16px;
  width: max-content;
  margin-bottom: 32px;
}

.table__wrapper {
  max-width: 70vw;
  max-height: 50vh;
  overflow: scroll;
}

table {
  border-collapse: collapse;
  font-size: 14px;
}

tr {
  display: block;
  padding-left: 40px;
  position: relative;
}

tr > .delete__button {
  display: block;
  border: 0;
  background: url(../../../assets/img/fileUpload/trash-icon.svg) no-repeat center / contain;
  width: 16px;
  height: 16px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 8px;
  cursor: pointer;
}

tr:nth-child(even) {
  background-color: rgba(228, 235, 242, 0.4);
}

td input {
  border: 0;
  border-bottom: 1px solid #A2A4A4;
  background-color: transparent;
  outline: 0;
  width: 90%;
  text-overflow: ellipsis;
  padding: 4px 0;
}

th input {
  font-weight: bold;
  padding: 4px;
}

td, th {
  min-width: 180px;
  max-width: 180px;
  text-align: left;
  overflow: hidden;
  padding: 16px 8px;
  text-overflow: ellipsis;
}

th > span:nth-child(2) {
  color: #999;
  font-weight: 500;
  margin-top: 6px;
  display: block;
}

::-webkit-scrollbar {
  height: 8px;
  border-radius: 8px;
  width: 8px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #bdc5cc;
  border-radius: 8px;
  transition: 0.4s;
}

::-webkit-scrollbar-thumb:hover {
  background: #95b0c7;
}

.table__buttons {
  display: flex;
  gap: 24px;
  justify-content: center;
}

.table__buttons > button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  font-size: 14px;
  height: 40px;
  width: 180px;
  border-radius: 8px;
  cursor: pointer;
  transition: .4s ease;
}


.predict__button {
  border: 0;
  background-color: var(--primary);
  color: #FFF;
  font-weight: 500;
}

.predict__button:hover {
  background-color: var(--secundary-3);
}

.newrow__button {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 16px auto;
  background-color: transparent;
  border: 2px solid var(--primary);
  cursor: pointer;
}

.newrow__button > img {
  filter: brightness(0) saturate(100%) invert(29%) sepia(96%) saturate(1531%) hue-rotate(212deg) brightness(93%) contrast(107%);
  max-width: 18px;
}

</style>