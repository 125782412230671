<template>
  <section class="settings__container">
    <button class="button__back" @click="redirectTo('AiChoose')" title="Back to previous page" aria-label="Back to previous page" >
      <img src="../../assets/img/ai/icon-back-arrow.svg" alt="Back to previous page">
    </button>
    <div class="settings__wrapper">
      <h2 class="settings__title">{{$t("settings.title")}}</h2>
      <fieldset>
        <div class="settings__field">
          <input id="firstname" v-model="firstname" readonly>
          <label for="firstname" readonly>{{$t("settings.labels[0]")}}</label>
        </div>
        <div class="settings__field">
          <input id="lastname" v-model="lastname" readonly>
          <label for="lastname">{{$t("settings.labels[1]")}}</label>
        </div>

        <div class="settings__field">
          <input id="email" v-model="userData.email" readonly>
          <label for="email">{{$t("settings.labels[2]")}}</label>
        </div>

        <!-- <div class="settings__field">
          <input id="phone" v-model="userData.phone">
          <label for="phone">{{$t("settings.labels[3]")}}</label>
        </div> -->

        <div class="settings__field">
          <ChooseLang />
          <label for="changelang" class="settings__lang">{{$t("settings.labels[4]")}}</label>
        </div>

        <div class="settings__field">
          <div class="change-pass__field">
            <button class="change-pass__button"  @click="formNewPass">{{ showChangePass ? $t("settings.changePass[1]") : $t("settings.changePass[0]") }}</button>
            
            <div class="container__inputs__password" v-if="showChangePass">
              <label class="pass__label">{{$t("settings.labels[6]")}}</label>
              <div class="input__wrapper">
                <input :type="showPassword ? 'text' : 'password'" id="password" v-model="changePassword.current">
                <button @click="toggleShowPassword" :class="{hide: showPassword}" title="Change password visibility"></button>
              </div>

              <label class="pass__label">{{$t("settings.labels[7]")}}</label>
              <div class="input__wrapper">
                <input :type="showPassword ? 'text' : 'password'" id="password" v-model="changePassword.new" minlength="8" @focusin.once="badPass = true">
                <button @click="toggleShowPassword" :class="{hide: showPassword}" title="Change password visibility"></button>
              </div>
              <p class="pass__rules" v-if="badPass">
                {{$t("settings.changePass[2]")}}
                <ul class="rules">
                  <li class="min__digits"  :class="{ok : changePassword.new.length >= 8}">-{{$t("settings.changePass[3]")}}</li>
                  <li class="min__letters" :class="{ok : hasLetter}">-{{$t("settings.changePass[4]")}}</li>
                  <li class="min__numbers" :class="{ok : hasNumber}">-{{$t("settings.changePass[5]")}}</li>
                </ul>
              </p>
              <br><br>
              <button class="settings__button" :class="{disable : goodToGoForm}" @click="handleSaveChanges">{{$t("settings.button")}}</button>
            </div>
          </div>
  
          <label :class="{labelPass : showChangePass}">{{$t("settings.labels[5]")}}</label>
        </div>
      </fieldset>
      <div class="container_warnings">
        <p v-if="changesOk" class="warning good">{{$t("settings.errorMsg[1]")}}</p>
        <p v-if="showError" class="warning bad">{{$t("settings.errorMsg[0]")}}</p>
      </div>
    </div>
  </section>
</template>

<script>
import { storeToRefs } from 'pinia';
import { usePageSelector } from '../../stores/pageSelector';
import { useUserStore } from '../../stores/user';
import ChooseLang from "../elements/ChooseLang.vue"
export default {
  name: 'Settings',
  components: {ChooseLang},
  setup() {
    const pageSelector = usePageSelector();
    const { redirectTo, requestPageElements } = pageSelector;

    const userStore = useUserStore();
    const {user_id, firstname, lastname, isSessionExpired, } = storeToRefs(userStore);
    const { trigSessVerify, } = userStore;
    return {
      redirectTo,
      requestPageElements,
      user_id,
      firstname, 
      lastname,
      isSessionExpired,
      trigSessVerify,
    }
  },
  data(){
    return{
      userData: {
        email: '',
        // phone: '',
      },
      
      storedElements: [],
      showChangePass: false,
      changePassword: {
        current: '',
        new: '',
      },

      // Variável pras regras de senhas
      badPass: false,
      hasNumber: false,
      hasLetter: false,

      showError: false,
      changesOk: false,
      showPassword: false,

      okBtn: false,
    }
  },
  computed: {
    goodToGoForm(){
      // Se os inputs de redefinição de senha estiverem ativos, bloqueia o botão de confirmar
      if(this.badPass === false && this.okBtn === true){
        return false
      }
      else {
        return true
      }
    }
  },
  watch:{
    'changePassword.new'(newValue){
      if(/[a-zA-Z]/.test(newValue)){
        this.hasLetter = true
      }
      else{
        this.hasLetter = false
      }
      if(/[0-9]/.test(newValue)){
        this.hasNumber = true
      }
      else{
        this.hasNumber = false
      }
      if(this.hasLetter && this.hasNumber && newValue.length >= 8){
        this.badPass = false
        this.okBtn = true
      }
      else{
        this.badPass = true
        this.okBtn = false
      }
    },
   },
  methods: {
    formNewPass(){
      this.showChangePass = !this.showChangePass

      this.changePassword.current = ''
      this.changePassword.new = ''

      this.showError = false
      this.changesOk = false
    },
    toggleShowPassword() {
      this.showPassword = !this.showPassword
    },
    handleSaveChanges() {
      console.log('saved changes: ', this.userData)
      let url = process.env.VUE_APP_URL + '/updateProfile';

      let nB = {u_id:this.user_id,email:this.userData.email, pass:this.changePassword, }
      fetch(url, {
        method:'POST',
        body:JSON.stringify(nB),
        headers: new Headers({
          "content-type":"application/json"
        })
      })
      .then((res) => res.json())
      .then((content) => {
        if(content.invalidSession){
          this.isSessionExpired = true;
        }else if (!content.profileUpdate) {
          this.trigSessVerify()
          this.showError = true;
          return
        }else{
          this.trigSessVerify()
          this.changesOk = true
          this.showError = false
          this.showChangePass = false
          this.changePassword.new = ''
          this.changePassword.current = ''


          console.log('Update Successfull')
        }
      })
      },

  },
  mounted(){
    let url = process.env.VUE_APP_URL + '/settings';
    fetch(url, {
      method:'GET',
    })
    .then((res) => res.json())
    .then((content) => {
      if(content.invalidSession){
          this.isSessionExpired = true;
      }else{
        //Set NULL value to variables on back end 
        this.trigSessVerify()
        this.firstname = content.firstname;
        this.lastname = content.lastname;
        this.userData.email = content.email;
      }
    })
    this.requestPageElements('Settings')
      .then(elements => {
        this.storedElements = elements
        console.log(this.storedElements);
      })
  },
}
</script>

<style scoped>
.settings__container {
  margin: auto;
  margin-top: 32px;
  max-width: 640px;
  animation: surgir 1s;
  position: relative;
}
@keyframes surgir {
  from {
    opacity: 0.5;
    transform: translateY(32px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.button__back {
  background-color: transparent;
  border: 0;
  position: absolute;
  top: 30px;
  left: 90px;
  cursor: pointer;
}

.settings__title {
  font-weight: 600;
  /* margin-bottom: 32px; */
  text-align: center;
}
.settings__wrapper {
  background-color: #FFF;
  border-radius: 16px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  box-shadow: 0px 6px 8px -6px rgba(24, 39, 75, 0.12), 0px 8px 16px -6px rgba(24, 39, 75, 0.08);
}
fieldset {
  padding: 0;
  border: 0;
  /* max-width: 75%; */
}
legend {
  margin-bottom: 16px;
  /* font-weight: 600; */
  font-size: 18px;
  text-align: center;
}
.settings__field {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  margin-top: 24px;
  padding-right: 29%;
}
.settings__field:first-of-type {
  margin-top: 0;
}
label {
  font-weight: 600;
  margin-right: 24px;
  font-size: 14px;
  white-space: nowrap;
}
input, .change-pass__button {
  padding: 10px;
  border-radius: 8px;
  border: 1px solid rgb(197, 190, 190);
  font-size: 14px;
  max-width: 240px;
  width: 100%;
}
input:focus {
  outline: 1px solid var(--primary);
}
input:focus ~ label {
  color: var(--primary);
}
.change-pass__button {
  border-color: #8a898f;
  background: #8a898f;
  color: #FFF;
  font-weight: 500;
  cursor: pointer;
  transition: .4s ease;
}
.change-pass__button:hover {
  background: #555458;
}
.settings__button {
  background-color: var(--primary);
  color: #FFF;
  border: 0;
  height: 40px;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  max-width: 240px;
  width: 100%;
  margin: auto;
  transition: .4s ease;
}
.settings__button:hover {
  background-color: var(--secundary-3);
}
.input__wrapper {
  position: relative;
  display: flex;
  align-items: center;
}
.input__wrapper > button {
  position: absolute;
  display: block;
  right: 16px;
  height: 20px;
  border: 0;
  width: 20px;
  background: url(../../assets/img/eye-off-line.svg) no-repeat center / contain;
  cursor: pointer;
}
.input__wrapper > button.hide {
  background-image: url(../../assets/img/eye-line.svg);
}
.change-pass__field {
  width: 100%;
  max-width: 240px;
  /* background-color: red; */
}
.pass__label {
  display: block;
  margin-top: 16px;
  margin-bottom: 8px;
  font-weight: 400;
}
.change-pass__field input {
  border: 1px solid #949494;
  height: 40px;
  padding: 16px;
  border-radius: 6px;
  outline: 0;
  font-size: 14px;
  width: 100%;
}

.labelPass{
  align-self: flex-start;
  position: relative;
  top: 12px;
  /* background-color: green; */
}

.container_warnings .warning{
  text-align: center;
  font-weight: 500;
}

.container_warnings .warning.good{
  color: var(--primary);
}

.container_warnings .warning.bad{
  color: red;
}

.settings__button.disable{
  opacity: .5;
  pointer-events: none;
}

.pass__rules{
  margin-top: 10px;
  font-size: 0.85rem;
  color: #b6b3b3;
}

.ok{
  color: var(--primary);
  text-decoration: line-through;
}


.change-pass__field input.error,
.change-pass__field input.error::placeholder,
.change-pass__field label.error {
  color: red;
  border-color: red;
}
@media screen and (max-width: 767px) {
  .settings__field {
    flex-direction: column-reverse;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;
    padding: 0;
  }
  fieldset {
    margin: auto;
    max-width: 240px;
    width: 100%;
  }
  label {
    margin: 0;
  }
}
</style>