<template>
  <Loading v-if="isLoadingResult" />
  <article class="entry__container" v-else>
    <!-- Menu de escolha de tipo de entrada de dados -->
    <div class="entry__menu" v-if="showEntryMenu">
      <h3 class="entry__question">{{ $t('dataEntry.title') }}</h3>
      <button class="menu__button" @click="selectEntryType('EntryUpload')">
        <div class="button__icon">
          <img src="../../../assets/img/ai/icon-button-upload.svg" alt="Icon upload">
        </div>
        <span>{{ $t('dataEntry.options[0]') }}</span>
      </button>
      <!-- Desativado até segunda ordem 
        <button class="menu__button" @click="selectEntryType('EntryPaste')" v-if="!isFirefoxBrowser">
        <div class="button__icon">
          <img src="../../../assets/img/ai/icon-button-paste.svg" alt="Icon paste">
        </div>
        <span>{{ $t('dataEntry.options[1]') }}</span>
      </button> -->
      <button class="menu__button" @click="selectEntryType('EntryManually')">
        <div class="button__icon">
          <img src="../../../assets/img/ai/icon-button-insert.svg" alt="Icon insert">
        </div>
        <span>{{ $t('dataEntry.options[2]') }}</span>
      </button>
    </div>
    <!-- Renderização da opção escolhida -->
    <component :is="dataEntryType" v-else></component>

  </article>
</template>

<script>
import EntryUpload from "./EntryUpload.vue";
import EntryPaste from "./EntryPaste.vue";
import EntryManually from "./EntryManually.vue";
import Loading from "../../elements/Loading.vue";

import { useAiStore } from '../../../stores/ai';
import { storeToRefs } from 'pinia';

export default {
  name: "DataEntry",
  components: { EntryUpload, EntryPaste, EntryManually, Loading },
  setup() {
    const aiStore = useAiStore();
    const { dataEntryType, showEntryMenu, isLoadingResult } = storeToRefs(aiStore);

    return {
      dataEntryType,
      showEntryMenu,
      isLoadingResult,
    }
  },
  methods: {
    selectEntryType(type) {
      this.dataEntryType = type;
      this.showEntryMenu = false;
    }
  },
  computed: {
    isFirefoxBrowser: function () {
      // Firefox has no support to paste Excel data
      return navigator.userAgent.indexOf('Firefox') > -1
    }
  },
  unmounted() {
    this.showEntryMenu = true;
  }
};
</script>

<style scoped>

.entry__container {
  margin: auto;
}

.entry__menu {
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 468px;
  margin: auto;
}

.entry__question {
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 8px;
}

.menu__button {
  display: grid;
  grid-template-columns: 64px auto;
  background-color: #FFF;
  border-radius: 8px;
  border: 0;
  height: 64px;
  width: 100%;
  align-items: center;
  cursor: pointer;
  transition: .4s ease;
}

.menu__button:hover {
  transform: scale(1.1);
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.menu__button > span {
  font-weight: 600;
  transform: translateX(-8%);
}

.button__icon {
  background-color: var(--secundary-3);
  height: 100%;
  display: grid;
  place-content: center;
  border-radius: 8px 0 0 8px;
}

.button__icon > img {
  width: 100%;
  max-width: 24px;
}

</style>