// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/img/ai/icon-helper.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/img/main/close.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".modal__overlay[data-v-47775109]{background-color:rgba(0,0,0,.4);position:fixed;right:0;top:0;height:100vh;width:calc(100vw - 96px);z-index:20;display:flex;align-items:center;justify-content:center;animation:esmaecer-47775109 .3s;padding:16px}@keyframes esmaecer-47775109{0%{opacity:0}to{opacity:1}}.modal__container[data-v-47775109]{background-color:#fff;width:100%;max-width:640px;min-height:280px;border-radius:16px;display:grid;grid-template-areas:\"banner\" \"content\";grid-template-rows:120px 1fr;box-shadow:0 6px 8px -6px rgba(24,39,75,.12),0 8px 16px -6px rgba(24,39,75,.08);animation:crescer-47775109 .3s}@keyframes crescer-47775109{0%{transform:scale(0)}to{transform:scale(1)}}.modal__banner[data-v-47775109]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 50%/contain;background-size:64px;background-color:var(--secundary-3);border-radius:16px 16px 0 0;grid-area:banner;position:relative;padding:24px}.modal__closeButton[data-v-47775109]{height:32px;width:32px;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat 50%/contain;border:0;position:absolute;top:16px;right:16px;cursor:pointer}.modal__content[data-v-47775109]{display:flex;flex-direction:column;grid-area:content;padding:32px;margin:auto;width:100%}.modal__title[data-v-47775109]{color:var(--secundary-3);font-weight:600;font-size:24px;text-align:center;margin-bottom:24px}.modal__message[data-v-47775109]{font-size:16px;line-height:22px;text-align:center;white-space:pre-line}.modal__button[data-v-47775109]{border:0;background-color:var(--primary);color:#fff;border-radius:8px;height:40px;width:100%;max-width:200px;margin:auto;font-size:14px;font-weight:500;cursor:pointer;transition:.4s ease;margin-top:32px}.bigText[data-v-47775109]{text-align:justify}.modal__button[data-v-47775109]:hover{background-color:var(--secundary-3)}@media screen and (max-width:767px){.modal__overlay[data-v-47775109]{width:100vw}}", ""]);
// Exports
module.exports = exports;
