<template>
  <section class="dashboard__container">
    <h2 class="dashboard__titulo">KPIs</h2>

    <Cards />

    <h2 class="dashboard__titulo" style="margin: 40px 0;">{{$t('dashboard.label')}}</h2>

    <!-- Enquanto a lista de gráficos estiver vazia (efeito carregando): -->
    <div class="dashboard dashboard__loading" v-if="graphsList.length === 0">
      <div class="dashboard__grafico" v-for="n in 4" :key="n"></div>
    </div>

    <!-- Gráficos da empresa: -->
    <div class="dashboard">
      <div class="dashboard__grafico" v-for="graph in graphsList" :key="graph">
        <component :is="graph"></component>
      </div>
    </div>
  </section>
</template>

<script>
import barChart from "../graphs/bar.vue"; // MRR
import lineChart from "../graphs/line.vue"; // Churn Percentual
import survivalMix from "../graphs/mix.vue" // Survival Analysis
import aCustom from "../graphs/barACtms.vue"; // Active Customers
import heatChart from "../graphs/heat.vue"; // Cohort Client Start
import heatSegChart from "../graphs/heatSeg.vue"; // Cohort Segments
import bubbleAvgT from "../graphs/bubble.vue"; // Average Purchase Value
import barMRRMoveChart from "../graphs/barStackNeg.vue"; // MRR Movement

import ltChart from "../graphs/lt.vue"; // Average Client Lifetime
import ltvChart from "../graphs/ltv.vue"; // Client Lifetime Value
import churnPeriodChart from "../graphs/churnPeriod.vue"; // Churn Value on Period
import accChurnChart from "../graphs/accChurn.vue"; // Accumulated Churn Value
import newCustomersChart from "../graphs/newCustomers.vue"; // New Customers on Period
import custChurnChart from "../graphs/custChurn.vue"; // Customer Churn on Period
import survivalCohortsChart from "../graphs/survivalCohorts.vue"; // Survival Curve Cohorts
import avgTicketChart from "../graphs/avgTicket.vue"; // Average Ticket

import Cards from '../elements/Cards.vue';
import { storeToRefs } from 'pinia';
import { usePageSelector } from '../../stores/pageSelector';

export default {
  name: 'Analytics',
  components:{
    barChart,
    lineChart,
    aCustom,
    heatChart,
    heatSegChart,
    bubbleAvgT,
    survivalMix,
    barMRRMoveChart,
    ltChart,
    ltvChart,
    churnPeriodChart,
    accChurnChart,
    newCustomersChart,
    custChurnChart,
    survivalCohortsChart,
    avgTicketChart,
    Cards,
  },
  setup() {
    const pageSelector = usePageSelector();
    const { allowedPages } = storeToRefs(pageSelector);
    const { requestPageElements, getAllowedPages } = pageSelector;


    return {
      allowedPages,
      requestPageElements,
      getAllowedPages,
    }
  },
  data() {
    return {
      graphsList: [],
    }
  },
  methods: {
    getHomeElements() {
      this.pageSelector.requestPageElements('Home').then((elements)=>{
        this.storedElements = elements
        console.log('getHomeElements: ' + this.storedElements);
      });
    }
  },
  async mounted() {

    if (this.allowedPages.length === 0) {
      await this.getAllowedPages()
    } 
    // this.getHomeElements();

    // Array que define a ordem que os gráficos aparecerão na tela:
    let reference = ['Cards', 'barChart', 'barMRRMoveChart', 'aCustom', 'newCustomersChart', 'avgTicketChart', 'bubbleAvgT', 'ltvChart', 'ltChart', 'churnPeriodChart', 'custChurnChart', 'lineChart', 'accChurnChart', 'heatChart', 'heatSegChart', 'survivalMix', 'survivalCohortsCharts'];

    this.requestPageElements('Analytics')
      .then(elements => {
        let receivedGraphs = elements.map(el => el.name);
        this.graphsList = reference.filter(graph => receivedGraphs.includes(graph))
      })  
    
  },
        //Alterar a ordem dos graficos para
        // MRR, MRR Movement,
        // Active Customers, New Customers on Period,
        // Average Ticket, Bubble, 
        // Client Lifetime Value - LTV, Average Client Lifetime - LT,
        // Churn Value on Period, Customer Churn on Period, 
        // Churn Percentual, Accumulated Churn Value,
        // Cohort Client Start, Cohort Segments,  
        // Survival Analysis, Survival Curve Cohorts, 
}
</script>

<style scoped>
 .dashboard__container {
    margin-top: 32px;
    animation: surgir 1s;
  }

  @keyframes surgir {
    from {
      opacity: 0.5;
      transform: translateY(32px);
    }

    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .dashboard__titulo {
    font-weight: 600;
    margin-bottom: 32px;
    text-align: center;
  }

  .dashboard {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    box-shadow: 0px 6px 8px -6px rgba(24, 39, 75, 0.12), 0px 8px 16px -6px rgba(24, 39, 75, 0.08);
  }

  .dashboard__grafico {
    background-color: #FFF;
    /* border-radius: 16px; */
    width: calc(50% - 12px);
    padding: 16px;
    box-shadow: 0px 6px 8px -6px rgba(24, 39, 75, 0.12), 0px 8px 16px -6px rgba(24, 39, 75, 0.08);
  }

  .dashboard__loading > .dashboard__grafico {
    height: 400px;
    display: inline-block;
    position: relative;
    overflow: hidden;
  }

  .dashboard__loading > .dashboard__grafico::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(228, 235, 242, 0) 0,
      rgba(213, 224, 238, 0.3) 20%,
      rgba(213, 224, 238, 0.6) 60%,
      rgba(228, 235, 242, 0)
    );
    animation: shimmer 2s infinite;
    content: '';
  }
  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }

  @media screen and (max-width: 1280px) {
    .dashboard__grafico {
      width: calc(100vw - 64px - 96px);
      margin: auto;
    }
  }

  @media screen and (max-width: 767px) {
    .dashboard__grafico {
      width: calc(100vw - 32px);
      margin: auto;
    }
  }
</style>