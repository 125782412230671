<template> 
  <!-- Usado somente como modal helper nas AI New Prection (Entry Paste)  -->
  <section class="modal__overlay">
    <div class="modal__container">
      <div class="modal__banner">
        <button title="Close modal" aria-label="Close modal" class="modal__closeButton" @click="closeModal"></button>
      </div>
      <div class="modal__content">
        <h3 class="modal__title">{{setModalHelperScroll.title}}</h3>

        <div class="carousel-view">
          <transition-group class='carousel' tag="div">
            <div v-for="(slide,index) in setModalHelperScroll.slides" class='slide' :key="index">
              <h4>{{ slide.title }} 
                <br><br>
                <p class="texto_traduzido">{{ slide.msg }}</p>
              </h4>
            </div>
          </transition-group>
          <div class='carousel-controls'>
            <button class='carousel-controls__button left' @click="previous"><img src="../../assets/img/modalHelperScroll/left_arrow.svg" alt=""/></button>
            <button class='carousel-controls__button right' @click="next"><img src="../../assets/img/modalHelperScroll/right_arrow.svg" alt=""/></button>
          </div>
        </div>
        
        <button class="modal__button" @click="closeModal">{{ $t("newPrediction.modalHelp.btn") }}</button>
      </div>
    </div>
  </section>
</template>

<script>
import { storeToRefs } from 'pinia';
import { useAiStore } from '../../stores/ai';

export default {
  name: "ModalHelperScroll",

  setup() {
    const aiStore = useAiStore();
    const { setModalHelperScroll } = storeToRefs(aiStore);

    return {
      setModalHelperScroll
    }
  },
  methods: {
    closeModal() {
      this.setModalHelperScroll.show = false;
    },
    next () {
        const first = this.setModalHelperScroll.slides.shift()
        this.setModalHelperScroll.slides = this.setModalHelperScroll.slides.concat(first)
      },
      previous () {
        const last = this.setModalHelperScroll.slides.pop()
        this.setModalHelperScroll.slides = [last].concat(this.setModalHelperScroll.slides)
      },
  },
}
</script>

<style scoped>
.modal__overlay {
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  width: calc(100vw - 96px);
  z-index: 20;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: .3s esmaecer;
  padding: 16px;
}

@keyframes esmaecer {
  from {
    opacity: 0
  }
  to {
    opacity: 1;
  }
}

.modal__container {
  background-color: #FFF;
  width: 100%;
  max-width: 640px;
  min-height: 280px;
  border-radius: 16px;
  display: grid;
  grid-template-areas: 'banner' 'content';
  grid-template-rows: 120px 1fr;
  box-shadow: 0px 6px 8px -6px rgba(24, 39, 75, 0.12), 0px 8px 16px -6px rgba(24, 39, 75, 0.08);
  animation: .3s crescer;
}

@keyframes crescer {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

.modal__banner {
  background: url(../../assets/img/ai/icon-helper.svg) no-repeat center / contain;
  background-size: 64px;
  background-color: var(--secundary-3);
  border-radius: 16px 16px 0 0;
  grid-area: banner;
  position: relative;
  padding: 24px;
}

.modal__closeButton {
  height: 32px;
  width: 32px;
  background: url(../../assets/img/main/close.svg) no-repeat center / contain;
  border: 0;
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
}

.modal__content {
  display: flex;
  flex-direction: column;
  grid-area: content;
  padding: 32px;
  margin: auto;
  width: 100%;
  overflow: hidden;
}

.modal__title {
  color: var(--secundary-3);
  font-weight: 600;
  font-size: 24px;
  text-align: center;
  margin-bottom: 24px;
}

.modal__message {
  font-size: 16px;
  line-height: 22px;
  text-align: center;
}

.modal__button {
  border: 0;
  background-color: var(--primary);
  color: #FFF;
  border-radius: 8px;
  height: 40px;
  width: 100%;
  max-width: 200px;
  margin: auto;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: .4s ease;
  margin-top: 20px;
}

.modal__button:hover {
  background-color: var(--secundary-3);
}

@media screen and (max-width: 767px) {
  .modal__overlay {
    width: 100vw;
  }
}

.carousel-view{
  width: 100%;
  min-height: 60px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.carousel{
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.slide {
  flex: 0 0 20em;
  min-width: 500px;
  height: 120px;
  font-weight: 500;
  text-align: justify;
  transition: transform 0.3s ease-in-out;
}

.slide h4{
  color: var(--primary);
  font-size: 18px;
  margin-bottom: 15px;
}

.slide h4 p{
  font-weight: 400;
  font-size: 15px;
  color: black;
}

.slide:first-of-type {
  opacity: 0;
}
.slide:last-of-type {
  opacity: 0;
}


.carousel-controls{
  margin-top: 20px;
  display: flex;
  gap: 30px;
}

.carousel-controls__button{
  border: 0;
  cursor: pointer;
  background: none;
}

.texto_traduzido{
  white-space: pre-line;
}

</style>