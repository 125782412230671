<template>
  <apexchart :options="options" :series="series"></apexchart>
</template> 

<script>
import { useViewSelector } from "../../stores/viewSelector";
import { storeToRefs } from 'pinia';
import VueApexCharts from "vue3-apexcharts";
import { useUserStore } from '../../stores/user';
export default{
  name: 'aiCategSplit',
  components:{
    apexchart: VueApexCharts,
  },
  setup() {
    const viewSelector = useViewSelector();
    const {currentView} = storeToRefs(viewSelector);

    const userStore = useUserStore();
    const {analysis} = storeToRefs(userStore);

    return {
      currentView,
      analysis,
    }
  },
  data(){
    return {
      options: {
        chart: {
          type:'bar',
          id: 'aiCategSplit',
          stacked: true,
          width:'100%',
        },
        xaxis: {
          type:'datetime',
          categories:[],
        },
        yaxis: {
          labels: {
            formatter: val => val.toFixed()
          }
        },
        dataLabels:{
          enabled:false,
        },
        plotOptions: {
          bar: {
            borderRadius: 2,
            barWidth: '20%',
            columnWidth: '20%',
          },
        },
        title: {
          text: "Category Prediction Split",
        },
        noData:{
          text: 'No predictions have been run yet.',
          align: 'center',
          verticalAlign: 'bottom',
          offsetX: 0,
          offsetY: 28,
          style: {
            color: '#01009F',
            fontSize: '14px',
            fontFamily: 'Montserrat'
          }
        },
        responsive: [
          {
            breakpoint: 1023,
            options: {
              chart: {
                toolbar: {
                  tools: {
                    zoom: false,
                    zoomin: false,
                    zoomout: false,
                    pan: false,
                    reset: false,
                  }
                },
              },
            }
          }
        ],
      },
      series: [{
          data: [],
          name: '',
        },
      ],
    }
  },  
  mounted() {
    
    let url = process.env.VUE_APP_URL + '/aiCategSplit';

    let nB = {'a_id': this.analysis}
    fetch(url, {
      method:'POST',
      body:JSON.stringify(nB),
      headers: new Headers({
        "content-type":"application/json"
      })
    })
    .then((res) =>res.json())
    .then((content) => {
      console.log(content);
      if(content.invalidSession){
        this.currentView = 'Login';
      } else if (content.errorAPI) {
        console.warn('Empty response: /aiCategSplit')
      } else{
        this.options = {
        ...this.options,
        ...{
            xaxis:{
              categories: content.categories
            }
          }
        }
        
        content.series.forEach((el, i) => {
          this.series[i] = el
        });
        
      }
    })
    .catch((err) => {console.log('Erro, '+err)}); 
    
  }, 
}
</script>


<style>
  .wrap {
    display: flex;
    justify-content: center;
}
</style>
