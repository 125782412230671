<template>
  <section class="predapi__container">
    <h3 class="predapi__title">{{ $t('predictAPI.title') }}<button title="Help" aria-label="Help" @click="handleHelp('predictAPI_title')" :class="{reativar_animacao: cursorStoped}"></button></h3>
    <button title="Back to previous page" aria-label="Back to previous page" class="predapi__back-button" @click="backPage">
      <img src="../../assets/img/ai/icon-back-arrow.svg" alt="Back to previous page">
    </button>

    <div class="predapi__box">
      <div class="box__field">
        <label for="adress">{{ $t('predictAPI.labels[0]') }}<button title="Help" aria-label="Help" @click="handleHelp('predictAPI_address')" :class="{reativar_animacao: cursorStoped}"></button></label>
        <input type="text" id="adress" v-model="infoAPI.address" readonly>
        <button title="Copy to clipboard" aria-label="Copy to clipboard" @click="copyToClipboard(infoAPI.address)">
          <img src="../../assets/img/ai/icon-copy.svg" alt="Copy to clipboard">
        </button>
      </div>

      <div class="box__field">
        <label for="adress">{{ $t('predictAPI.labels[1]') }}<button title="Help" aria-label="Help" @click="handleHelp('predictAPI_key')" :class="{reativar_animacao: cursorStoped}"></button></label>
        <input type="text" id="adress" v-model="infoAPI.key" readonly>
        <button title="Copy to clipboard" aria-label="Copy to clipboard" @click="copyToClipboard(infoAPI.key)">
          <img src="../../assets/img/ai/icon-copy.svg" alt="Copy to clipboard">
        </button>
      </div>

      <div class="box__field">
        <label for="adress">{{ $t('predictAPI.labels[2]') }}<button title="Help" aria-label="Help" @click="handleHelp('predictAPI_EXrequest')" :class="{reativar_animacao: cursorStoped}"></button></label>
        <input type="text" id="adress" v-model="infoAPI.request" readonly>
        <button title="Copy to clipboard" aria-label="Copy to clipboard" @click="copyToClipboard(infoAPI.request)">
          <img src="../../assets/img/ai/icon-copy.svg" alt="Copy to clipboard">
        </button>
      </div>

      <div class="box__field">
        <label for="adress">{{ $t('predictAPI.labels[3]') }}<button title="Help" aria-label="Help" @click="handleHelp('predictAPI_fields')" :class="{reativar_animacao: cursorStoped}"></button></label>
        <div class="table__wrapper">
          <table>
            <tr>
              <th>{{ $t('predictAPI.tableLabels[0]') }}</th>
              <th>{{ $t('predictAPI.tableLabels[1]') }}</th>
            </tr>
            <tr v-for="row in predictList" :key="row">
              <td :title="row[0]">{{row[0]}}</td>
              <td :title="row[1]">{{row[1]}}</td>
            </tr>
          </table>
        </div>
      </div>

    </div>
  </section>
</template>

<script>
import { storeToRefs } from 'pinia';
import { useAiStore } from '../../stores/ai'

export default {
  setup() {
    const aiStore = useAiStore();
    const { currentModelResults, selectedColumns, APItoken, setModalHelper} = storeToRefs(aiStore);

    return {
      currentModelResults,
      selectedColumns,
      APItoken,
      setModalHelper,
    }
  },
  methods: {
    handleHelp(whatHelp) {

      let titulo = ""
      let msg = ""
      let largeText = false

      if(whatHelp === "predictAPI_title"){
        titulo = this.$i18n.t("predictAPI.help.api_title.title")
        msg = this.$i18n.t("predictAPI.help.api_title.msg")
        largeText = true
      }
      else if(whatHelp === "predictAPI_address"){
        titulo = this.$i18n.t("predictAPI.help.api_address.title")
        msg = this.$i18n.t("predictAPI.help.api_address.msg")
      }
      else if(whatHelp === "predictAPI_key"){
        titulo = this.$i18n.t("predictAPI.help.api_key.title")
        msg = this.$i18n.t("predictAPI.help.api_key.msg")
      }
      else if(whatHelp === "predictAPI_EXrequest"){
        titulo = this.$i18n.t("predictAPI.help.example_request.title")
        msg = this.$i18n.t("predictAPI.help.example_request.msg")
      }
      else{
        titulo = this.$i18n.t("predictAPI.help.fields.title")
        msg = this.$i18n.t("predictAPI.help.fields.msg")
      }
      
      /* Cuidar ao fazer essa atribuição de objetos via destructuring!
         Pode ocorrer erros caso os nomes do atributo do objeto sejam
         iguais aos nomes das variáveis, por isso, sempre use variáveis com
         nome diferente para funcionar corretamente
      */
      this.setModalHelper = {
        title: titulo,
        message: msg,
        show: true,
        bigText: largeText,
      }
    },
    copyToClipboard(text) {
      navigator.clipboard.writeText(text)
      // alert("Successfully copied: " + text)
    },
    backPage() {
      this.currentModelResults = 'Metrics';
    }
  },
  data() {
    return {
      infoAPI: {
        address: "https://airpoon-predict-api-bedx6umdma-ue.a.run.app/model_predict",
        key: "",
        request: "",
      },
      predictList: [],
      mouseX: "",
      mouseY: "",
      cursorStoped: false
    }
  },
  mounted() {
    this.predictList = Object.entries(this.selectedColumns);
    this.infoAPI.key = this.APItoken;

    /* Mouse tracking para saber se o usuário está com mouse inativo */
    /* Eu e Merino achamos bem desnecessário, ver com o Lucca */
    window.addEventListener('mousemove' , (e)=>{
      this.mouseX = e.clientX
      this.mouseY = e.clientY

      this.cursorStoped = false

      let timer = setTimeout(()=>{
        let oldMouse = e.clientX
        if(this.mouseX !== oldMouse){
          this.cursorStoped = false
          clearTimeout(timer)
        }else{
          this.cursorStoped = true
        }
      },20000)
    })
  },
}
</script>

<style scoped>
.predapi__container {
  position: relative;
}

.predapi__title {
  font-size: 24px;
  border-top: 2px solid #E2E2E2;
  padding-top: 28px;
  margin: 32px 0;
  font-weight: 600;
}

.predapi__back-button {
  position: absolute;
  left: 0px;
  top: 26px;
  background: transparent;
  border: 0;
  cursor: pointer;
}

.predapi__box {
  background-color: #FFF;
  border-radius: 16px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  box-shadow: 0px 6px 8px -6px rgba(24, 39, 75, 0.12), 0px 8px 16px -6px rgba(24, 39, 75, 0.08);

}

.box__field {
  display: grid;
  grid-template-areas: 'label label' 'input button';
  grid-template-rows: auto auto;
  grid-template-columns: 1fr auto;
  gap: 16px;
  align-items: center;
}

.box__field > label {
  grid-area: label;
  font-size: 16px;
  font-weight: 600;
  text-align: left;
}

.box__field > input {
  grid-area: input;
  border: 1px solid #E8E8E8;
  border-radius: 6px;
  padding: 16px;
}

.box__field > input:focus {
  outline: none;
}

.box__field > button {
  grid-area: button;
  border: 0;
  height: 48px;
  width: 48px;
  cursor: pointer;
  border-radius: 50%;
  background-color: transparent;
}

.box__field > button:hover {
  background-color: rgba(228, 235, 242, 0.3);
}

@media screen and (max-width: 480px) {
  .box__field:not(.box__field:last-child) {
    grid-template-areas: 'label button' 'input input';
  }
}

::-webkit-scrollbar {
  height: 8px;
  border-radius: 8px;
  width: 8px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #bdc5cc;
  border-radius: 8px;
  transition: 0.4s;
}

::-webkit-scrollbar-thumb:hover {
  background: #95b0c7;
}

.table__wrapper {
  overflow-y: scroll;
  max-height: 40vh;
  width: 100%;
  max-width: min-content;
  padding-right: 8px;
}

table {
  width: 100%;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  position: relative;
}

tr {
  display: inline-block;
}

tr:nth-child(even) {
  background-color: rgba(228, 235, 242, 0.4);
}

th,
td {
  min-width: 252px;
  max-width: 252px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 16px 8px;
  white-space: nowrap;
}

th {
  font-weight: 600;
  text-transform: uppercase;
  height: auto;
  white-space: nowrap;
}

h3 button , label button{
  cursor: pointer;
  height: 20px;
  width: 20px;
  background: url(../../assets/img/ai/icon-help.svg) no-repeat center / contain;
  border: 0;
  transition: 0.4s ease;
  position: relative;
  left: 9px;
  top: 3px;
  animation: destacarIcone 2s ease 400ms ;
}

label button{
  width: 18px;
  height: 18px;
  left: 9px;
  top: 4px;
}

h3 > button:hover  {
  transform: scale(1.2);
}

label button:hover{
  transform: scale(1.2);
}

@keyframes destacarIcone {
  0%{
    transform: scale(1);
  }
  75%{
    transform: scale(1.2);
  }
  100%{
    transform: scale(1);
  }
}

.reativar_animacao{
  animation: destacarIcone 2s ease infinite;
}

</style>