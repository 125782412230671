import { defineStore } from "pinia";
import { useViewSelector as viewSelector } from "./viewSelector";

export const useAiStore = defineStore("aiStore", {
  state: () => {
    return {
      // Lista de tipos de análises disponíveis para serem selecionados (chega do banco):
      analysisTypeList: [{}],

      // Tipo de análise selecionado pelo usuário:
      analysisType: {
        id: null,
        name: "My analysis",
        type: null,
        description: null,
        icon: null,
        target:null,
        progress: 0,
      },
      
      // Histórico e paginação de análises criadas anteriormente:
      prevAnalysisList: [],

      // Nomes dos passos e respectivos componentes utilizados durante o pipe de AI:
      stepList: [
        { step: "File upload", component: "Upload" },
        { step: "Data choice", component: "DataChoice" },
        { step: "Parameters", component: "Parameters" },
        { step: "Processing", component: "Processing" },
        { step: "Result", component: "Result" },
      ],

      currentComponent: "Upload", // Componente do passo atual que está sendo exibido na Run.vue
      currentModelResults: "Metrics", // Componente atual (Metrics | PredictNow | PredictAPI) que está sendo exibido na ModelResults.vue
      targetColumn: "", // Coluna alvo selecionada na DataChoice.vue
      selectedColumns: "", // Colunas selecionadas na DataChoice.vue

      APItoken: "", // Token do PredictAPI

      // NewPrediction states:
      showEntryMenu: true, // Exibir o menu de opções de entradas de dados? (File upload, Paste data, Insert manually)
      dataEntryType: "", // Tipo de entrada de dados selecionado
      showDataEntry: true, // Exibe telas relacionadas à entrada de dados, senão exibe a tela de Results
      isLoadingResult: false, // O resultado ainda está sendo processado?
      isUploadPredict: false, // Flag para monitorar se o usuário chegou no componente EntryPaste através do File Upload.
      fileUploadData: {}, // Dados de tabela recebidos a partir de um File Upload Predict
      tableContentSent: {}, // Dados enviados para a PredictAPI
      resultPredictAPI: [], // Resultado do predict que chega da PredictAPI
      resultFileName: null, // Nome do Arquivo de Resultados criado e enviado pelo Abner
      predOrigFileName: null, // Nome original do Arquivo enviado no EntryUpload
      downloadLink: null, // Link para o download do arquivo com o resultado das previsões

      objectParameters: {}, // Armazena dados que chegam do banco, relacionados ao componente Parameters.vue
      setModalHelper: {
        title: "Lorem ipsum",
        message: "",
        show: false,
        bigText: false,
      },

      setModalHelperScroll: {
        title: "Modal com scroll",
        slides: [],
        show: false,
      },

      setModalDataGenius: {
        title: '',
        msg: '',
        show: false,
      },

      setModalErrorAI: {
        title: '',
        msg: '',
        show: false,
      },

      cameFromQuickActions: 0,

      quickLatestAnalysis: false, //Clicou no card da quickaction pulou o preenchimento das últimas análises do bacana
    };
  },

  actions: {
    async handlePredictAPI(tableContent) {
      this.isLoadingResult = true;
      this.tableContentSent = tableContent;
      console.log(JSON.stringify(this.tableContentSent))
      // Transformando columnsName e rowsData em columnData, para atender o formato requerido pela PredictAPI:
      let toSendValues = {};
      tableContent.columnsName.forEach((column, i) => {
        let columnData = []
        tableContent.rowsData.forEach(row => {
          let data = row[i] && row[i].replace(",",".")
          columnData.push(data)
        })
        toSendValues[column[0]] = columnData
      })
      console.log('toSendValues API: ', toSendValues)

      // PredictAPI request:
      let url = process.env.VUE_APP_URL + '/OneClickAPI'
      console.log('Valores a serem enviados: ', toSendValues)
      console.log('API Token: '+JSON.stringify(this.APItoken))
      let nB = {'data':toSendValues, 'api_key':this.APItoken, 'fileName':this.tableContentSent.fileName, 'originalFileName':this.predOrigFileName, 
      'user_id':this.tableContentSent.user_id, 'company_id':this.tableContentSent.company_id, 'company':this.tableContentSent.company, 'pipeFlow':this.tableContentSent.pipeline }   
      
      console.log(JSON.stringify(nB))
      try {
        let result = await fetch(url, {
          method:'POST',
          body:JSON.stringify(nB),
          headers: new Headers({
            "content-type":"application/json"
          })
        })
        const content = await result.json();
        console.log(JSON.stringify(content))
        this.downloadLink = content.url
        console.log(this.downloadLink)
        this.predOrigFileName = null;
        if (content.invalidSession) {
          viewSelector().currentView = "Login"; //Mostrar modal de reativação da sessão
        } else if (content.errorAPI) {

          // Ao invés de aparecer um alert() como estava antigamente
          // agr vai aparecer o modal helper bonitnho pra dizer q deu merda nessa função
          this.setModalHelper = {
            title: "Deu erro na função handlePredictAPI()",
            message: 'Mandou a tabela pela EntryManually, chamou a função do pinia enviando a tabela para ser tratada e mostrar os resultados mas deu ruim',
            show: true,
            bigText: true,
          }
          
          this.isLoadingResult = false;
          this.showEntryMenu = true;
        } else {
          // Salvando e exibindo resultados do predict:
          this.resultPredictAPI = content.data;
          this.resultFileName = content.file_name;
          this.showDataEntry = false;
          this.isLoadingResult = false;
          console.log("resultPredictAPI: ", this.resultPredictAPI);
        }
      } catch (error) {
        console.error(error)
      }
    },
  },

  getters: {
    // Índice do passo atual do pipe de AI:
    currentIndex: function (state) {
      const target = state.stepList.find(
        (el) => el.component === state.currentComponent
      );
      return state.stepList.indexOf(target);
    },
  },
});
/*
analysisTypeList: [
        {
          id: 2,
          name: "Churn",
          description:
            "Predict customer retention based on past performance",
          icon: require("../assets/img/ai/analyses/icon-churn.svg"),
          progress: 0,
        },
        {
          id: 3,
          name: "Sales Forecast",
          description:
            "Predict future sales",
          icon: require("../assets/img/ai/analyses/icon-forecast.svg"),
          progress: 0,
        },
        {
          id: 4,
          name: "Persona",
          description:
            "Automated persona discovery",
          icon: require("../assets/img/ai/analyses/icon-persona.svg"),
          progress: 0,
        },
        {
          id: 5,
          name: "ICP",
          description:
            "Find out who are your best clients",
          icon: require("../assets/img/ai/analyses/icon-icp.svg"),
          progress: 0,
        },
        {
          id: 6,
          name: "Sales Prospects",
          description:
            "Predict sales conversion potention",
          icon: require("../assets/img/ai/analyses/icon-prospects.svg"),
          progress: 0,
        },
        {
          id: 7,
          name: "A/B testing",
          description:
            "Predict test viability on broader cohorts and reduce experiment costs and risk",
          icon: require("../assets/img/ai/analyses/icon-abtesting.svg"),
          progress: 0,
        },
        {
          id: 8,
          name: "Upsell/Cross-sell",
          description:
            "Predict purchase susceptibility",
          icon: require("../assets/img/ai/analyses/icon-upcross.svg"),
          progress: 0,
        },
        {
          id: 9,
          name: "Remarketing viability",
          description:
            "Predict remarketing campaigns success",
          icon: require("../assets/img/ai/analyses/icon-remarketing.svg"),
          progress: 0,
        },
        {
          id: 10,
          name: "Employee Turnover",
          description:
            "Predict employee retention over a given timeframe",
          icon: require("../assets/img/ai/analyses/icon-employee.svg"),
          progress: 0,
        },
        {
          id: 0,
          name: "Classification",
          description:
            "Standard classification modeling",
          icon: require("../assets/img/ai/analyses/icon-classification.svg"),
          progress: 0,
        },
        {
          id: 1,
          name: "Regression",
          description:
            "Standard regression modeling",
          icon: require("../assets/img/ai/analyses/icon-regression.svg"),
          progress: 0,
        },
        {
          id: 11,
          name: "Clustering",
          description:
            "Standard clustering modeling",
          icon: require("../assets/img/ai/analyses/icon-clustering.svg"),
          progress: 0,
        },
      ],
*/