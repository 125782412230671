<template>
  <section class="modal__overlay">
    <div class="modal__container">
      <div class="modal__banner">
      
      </div>
      <div class="modal__content">
        <h3 class="modal__title">{{$t('modalSession.title')}}</h3>
        <p class="modal__message">{{$t('modalSession.message')}}</p>
        <label for="password" :class="{error: showError}">{{$t('modalSession.user')}}: <strong>{{firstname || 'Unknown'}}</strong></label>
        <label for="password" :class="{error: showError}">{{$t('modalSession.label')}}: {{ showError ? $t('modalSession.error') : ''}}</label>
        <div class="input__wrapper">
          <input :type="showPassword ? 'text' : 'password'" id="password" :placeholder="$t('modalSession.placeholder')" v-model="typedPassword" :class="{error: showError}">
          <button @click="toggleShowPassword" :class="{hide: showPassword}" title="Change password visibility"></button>
        </div>
        <button class="modal__continue" @click="handleContinue" :class="{disabledSubmit: lockForm || typedPassword.length == 0}">{{$t('modalSession.buttons[0]')}}</button>
        <button class="modal__back" @click="handleBack">{{$t('modalSession.buttons[1]')}}</button>
      </div>
    </div>
  </section>
</template>

<script>
import { storeToRefs } from 'pinia';
import { useViewSelector } from '../../stores/viewSelector';
import { useUserStore } from '../../stores/user';

export default {
  name: "ModalSession",
  setup() {
    const viewSelector = useViewSelector();
    const { currentView } = storeToRefs(viewSelector);

    const userStore = useUserStore();
    const { user_id, comp_id, isSessionExpired, firstname, isSubscription, statusSub } = storeToRefs(userStore);
    const {trigSessVerify} = userStore;

    return {
      user_id,
      comp_id,
      currentView,
      isSessionExpired,
      firstname,
      isSubscription,
      statusSub,
      trigSessVerify,
    }
  },
  methods: {
    toggleShowPassword() {
      this.showPassword = !this.showPassword
    },
    handleContinue() {
      //if pass is ok, close modal, session was updated
      //else request input again
      if (!this.lockForm) {
        const url = process.env.VUE_APP_URL + '/reactivateSession';
        let nB = {pass:this.typedPassword, u_id:this.user_id, c_id:this.comp_id};
        fetch(url, {
          method:'POST',
          body:JSON.stringify(nB),
          headers: new Headers({
            "content-type":"application/json"
          })
        })
        .then((res) => res.json())
        .then((content) => {
          console.log(content)
          if(content.sessionUpdated){
            if(content.isSubscription){
              this.isSubscription = content.isSubscription
              this.statusSub = content.statusSub
            }
            this.trigSessVerify();
            console.log('Sessao atualizada')

            this.isSessionExpired = false;
          } else {
            console.log('Senha incorreta, por favor tente novamente')
            this.showError = true;
          }
          this.lockForm = false;
        })
        .catch((err) => {
          this.lockForm = false;
          console.log(err)
        });
      }
      console.log('Senha digitada: ', )
      // this.isSessionExpired = false;
    },
    handleBack() {
      this.currentView = "Login";
      this.isSessionExpired = false;
    }
  },
  data() {
    return {
      showPassword: false,
      showError: false,
      typedPassword: '',
      lockForm:false,
    }
  }
}
</script>

<style scoped>
  .modal__overlay {
    background-color: rgba(0, 0, 0, 0.4);
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100vw;
    z-index: 28;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: .3s esmaecer;
    padding: 16px;
  }

  @keyframes esmaecer {
    from {
      opacity: 0
    }
    to {
      opacity: 1;
    }
  }

  .modal__container {
    background-color: #FFF;
    width: 100%;
    max-width: 455px;
    min-height: 280px;
    border-radius: 16px;
    display: grid;
    grid-template-areas: 'banner' 'content';
    grid-template-rows: 120px 1fr;
    box-shadow: 0px 6px 8px -6px rgba(24, 39, 75, 0.12), 0px 8px 16px -6px rgba(24, 39, 75, 0.08);
    animation: .3s crescer;
  }

  @keyframes crescer {
    from {
      transform: scale(0);
    }
    to {
      transform: scale(1);
    }
  }

  .modal__banner {
    background: url(../../assets/img/icon-session.svg) no-repeat center / contain;
    background-size: 72px;
    background-color: var(--secundary-3);
    border-radius: 16px 16px 0 0;
    grid-area: banner;
    position: relative;
    padding: 24px;
  }

  .modal__content {
    display: grid;
    place-content: center;
    grid-area: content;
    padding: 32px;
    text-align: center;
    margin: auto;
  }

  .modal__title {
    color: var(--secundary-3);
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 16px;
  }

  .modal__message {
    line-height: 24px;
    font-size: 16px;
    color: #5a5a5a;
  }

  .modal__content label:first-of-type {
    margin-top: 32px;
  }

  .modal__content label {
    font-size: 14px;
    text-align: left;
    margin-bottom: 8px;
    color: #5a5a5a;
  }

  label > strong {
    color: var(--secundary-3);
    font-weight: 500;
    text-transform: capitalize;
  }

  .input__wrapper {
    position: relative;
    display: flex;
    align-items: center;
  }

  .input__wrapper > button {
    position: absolute;
    display: block;
    right: 16px;
    height: 20px;
    border: 0;
    width: 20px;
    background: url(../../assets/img/eye-off-line.svg) no-repeat center / contain;
    cursor: pointer;
  }

  .input__wrapper > button.hide {
    background-image: url(../../assets/img/eye-line.svg);
  }

  .modal__content input {
    border: 1px solid #949494;
    height: 40px;
    padding: 16px;
    border-radius: 6px;
    outline: 0;
    font-size: 14px;
    width: 100%;
  }

  .modal__content input.error,
  .modal__content input.error::placeholder,
  .modal__content label.error {
    color: red;
    border-color: red;
  }

  .modal__continue {
    background-color: var(--primary);
    border: 0;
    color: #FFF;
    font-weight: 500;
    font-size: 14px;
    height: 40px;
    border-radius: 6px;
    margin: 24px 0;
    cursor: pointer;
    transition: .4s ease;
  }

  .modal__continue:hover {
    background-color: var(--secundary-3);
  }

  .modal__continue.disabledSubmit {
    pointer-events: none;
  }

  .modal__back {
    border: 0;
    background-color: transparent;
    font-size: 14px;
    font-weight: 600;
    color: var(--primary);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
    cursor: pointer;
    transform: translateX(-10px);
  }

  .modal__back::before {
    content: '';
    background: url(../../assets/img/ai/right-arrow.svg) no-repeat center / contain;
    height: 16px;
    width: 16px;
    display: inline-block;
    filter: brightness(0) saturate(100%) invert(35%) sepia(59%) saturate(7470%) hue-rotate(223deg) brightness(102%) contrast(93%);
    transform: rotate(-180deg);
  }

  @media screen and (max-width: 480px) {
    .modal__title {
      font-size: 20px;
    }

    .modal__message {
      font-size: 14px;
    }
  }

</style>