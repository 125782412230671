<template>
  <main class="container">

    <section class="acesso">
      <component :is="currentForm">

      </component>
    </section>

  </main>
</template>

<script>
import LoginForm from './LoginForm.vue';
import RecoveryForm from './RecoveryForm.vue';
import EnterCode from './EnterCode.vue';
import ConfirmNewPassword from './ConfirmNewPassword.vue';

import { usePageSelector } from '../../stores/pageSelector';
import { storeToRefs } from 'pinia';

export default {
  components: { LoginForm, RecoveryForm , EnterCode , ConfirmNewPassword},
  name: "Login",
  setup() {
    const pageSelector = usePageSelector();
    const { currentForm } = storeToRefs(pageSelector);
    return{
      currentForm
    }  
  },
  data(){
    return {
      login: true,
      recoveryCode: false,
    }
  }
};
</script>

<style scoped>
.container {
  /* height: 100vh; */
  background-color: red;
}

/* Lado direito */
.acesso {
  max-width: 100%;
  min-height: 100vh;
  height: 100%;
  /* height: 100vh; */
  display: grid;
  place-items: center;
  padding: 24px;
  background-color: var(--secundary-1);
}
@media screen and (max-width: 1023px) {
  .container {
    grid-template-areas: 
      "intro" 
      "acesso";
  }
  .intro, .acesso {
    width: 100vw;
  }
  .intro {
    padding-bottom: 80px;
  }
  .intro__logo {
    margin: auto;
    max-width: 110px;
    padding: 32px 0;
  }
  .intro__logo > .logo {
    display: block;
    margin: auto;
  }
  .intro__logo > .marca, .intro__titulo, .intro__descricao {
    display: none;
  }
  .acesso {
    border-radius: 32px 32px 0 0;
    background-color: #FFF;
    transform: translateY(-40px);
  }
}
</style>