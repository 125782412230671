<template>
  <section class="tabular__container">
    <div class="title__wrapper">
      <button title="Back to previous page" aria-label="Back to previous page" class="button__back" @click="backPage">
        <img src="../../../assets/img/ai/icon-back-arrow.svg" alt="Back to previous page">
      </button>
      <h2 class="tabular__title">
        {{ $t('newPrediction.container_title') }} <strong>{{showDataEntry ? dataEntry_label : results_label}}</strong>
        <button title="Help" aria-label="Help" @click="handleScrollHelp()" v-if="showDataEntry"></button>
      </h2>
    </div>

    <!-- Exibir telas de entrada de dados ou resultados: -->
    <DataEntry v-if="showDataEntry" />
    <Results :isLoading="isLoading" v-else />
  </section>
</template>

<script>
import { storeToRefs } from 'pinia';
import { useAiStore } from '../../../stores/ai';
import DataEntry from "./DataEntry.vue";
import Results from "./Results.vue";
import { usePageSelector } from '../../../stores/pageSelector';

export default {
  components: {DataEntry, Results},
  setup() {
    const aiStore = useAiStore();
    const { showDataEntry, showEntryMenu , setModalHelperScroll} = storeToRefs(aiStore);

    const pageSelector = usePageSelector();
    const { redirectTo } = pageSelector;

    return {
      showDataEntry,
      showEntryMenu,
      redirectTo,
      setModalHelperScroll,
    }
  },
  data(){
    return {
      dataEntry_label: this.$i18n.t("newPrediction.labels_title_container[0]"),
      results_label: this.$i18n.t("newPrediction.labels_title_container[1]")
    }
  },
  methods: {
    handleScrollHelp(){
      this.setModalHelperScroll  = {
        title: this.$i18n.t("newPrediction.modalHelp.title"),
        slides: [
          {
            title: this.$i18n.t("newPrediction.modalHelp.slides[0].title"),
            msg: this.$i18n.t("newPrediction.modalHelp.slides[0].msg"),
          },
          {
            title: this.$i18n.t("newPrediction.modalHelp.slides[1].title"),
            msg: this.$i18n.t("newPrediction.modalHelp.slides[1].msg"),
          },
          {
            title: this.$i18n.t("newPrediction.modalHelp.slides[2].title"),
            msg: this.$i18n.t("newPrediction.modalHelp.slides[2].msg"),
          }
        ],
        show: true
      }

    },
    backPage() {
      this.redirectTo('AiModelResults')
      this.showEntryMenu = true;
      this.showDataEntry = true;
    },
    toggleShowDataEntry() {
      this.showDataEntry = !this.showDataEntry
    },
  },
  unmounted() {
    this.showDataEntry = true;
    this.showEntryMenu = true;
  }
}
</script>

<style scoped>

.tabular__container {
  margin: auto;
  margin-top: 32px;
  animation: surgir 1s;
  text-align: center;
}

.title__wrapper {
  position: relative;
  max-width: fit-content;
  margin: auto;
  display: flex;
  align-items: center;
}

.button__back {
  background-color: transparent;
  border: 0;
  position: absolute;
  top: 0px;
  left: -64px;
  cursor: pointer;
}

/* .button__back > img {
  filter: brightness(0) saturate(100%) invert(12%) sepia(48%) saturate(5140%) hue-rotate(237deg) brightness(99%) contrast(153%);
} */

.tabular__title {
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 32px;
}

.tabular__title strong {
  font-weight: 600;
  color: var(--primary);
}

@keyframes surgir {
  from {
    opacity: 0.5;
    transform: translateY(32px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

h2 button{
  cursor: pointer;
  height: 20px;
  width: 20px;
  background: url(../../../assets/img/ai/icon-help.svg) no-repeat center / contain;
  border: 0;
  transition: 0.4s ease;
  position: relative;
  left: 9px;
  top: 3px;
  transition: all 1s ease;
}

h2 button:hover{
  transform: scale(1.2);
}
</style>