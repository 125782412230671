<template>
  <form @submit.prevent='login' id="form" action=/login method=post>
    <div class="form__logo">
      <img src="../../assets/img/brand/aihuk_logo_full_black.svg" alt="Airpoon">
    </div>
    <div class="form__header">
      <!-- <span>{{$t('login.form[0]')}}</span> -->
      <h2>{{$t('login.form[1]')}}</h2>
    </div>

    <span class="form__error" v-if="showError">
      {{$t('login.error')}}
    </span>

    <label for="email">{{$t('login.email')}}</label>
    <input type="email" v-model="formData.email" name="email" id="email" class="input" required>

    <label for="password">{{$t('login.form[2]')}}</label>
    <input type="password" v-model="formData.pass" name="password" id="password" class="input" required>

    <div class="options">
      <label for="remember" class="remember">
        <input v-model="memoUser" @click="rememberMe" type="checkbox" name="remember" id="remember">
        {{$t('login.form[3]')}}
      </label>

      <a class="forgot" @click="currentForm = 'RecoveryForm'">{{$t('login.form[4]')}}</a>
    </div>

    <button type="submit" class="botao" :class="{disabledSubmit: lockForm}" style="background-color: #009f53;">
      {{$t('login.form[5]')}}
    </button>
    <!-- <a href="https://signup.airpoon.com" class="notRegisteredLink">{{$t('login.form[6]')}}</a> -->
  </form>
</template>

<script>
import { useViewSelector } from "../../stores/viewSelector";
import { useUserStore } from "../../stores/user";
import { usePageSelector } from '../../stores/pageSelector';
import { storeToRefs } from 'pinia'; 

export default {
  name: "LoginForm",
  el:'#form',
    data() {
      return {
        // Valores digitados pelo usuário:
        formData:{
          email: '',
          pass: '',
        },
        memoUser: '',
        lockForm:'', // Flag para evitar multiplos clicks
        showError:'', // Exibir erro em caso de credenciais incorretas
      }
    },
    setup() {
      const viewSelector = useViewSelector();
      const { currentView, } = storeToRefs(viewSelector);

      const userStore = useUserStore();
      const {user_id, comp_id, isSubscription, statusSub, multiple_comps } = storeToRefs(userStore);

      const pageSelector = usePageSelector();
      const { currentForm } = storeToRefs(pageSelector);
      return {
        currentView,
        user_id,
        comp_id,
        isSubscription,
        statusSub,
        multiple_comps,
        currentForm
      }
    },
    methods:{
      login(){
      if (!this.lockForm) {
        this.lockForm = true;
        const url = process.env.VUE_APP_URL + '/login';
        const e_mail = this.formData.email;
        const pass = this.formData.pass;
        let nB = {e_mail, pass};
        fetch(url, {
          method:'POST',
          body:JSON.stringify(nB),
          headers: new Headers({
            "content-type":"application/json"
          })
        })
        .then((res) => res.json())
        .then((content) => {
          console.log(JSON.stringify(content, '\n', 2));
          if(content.subdLogin){
            this.user_id = content.uid
            this.comp_id = content.cid
            this.isSubscription = content.isSubscription
            this.statusSub = content.statusSub
            this.multiple_comps = content.mltp_comp;
            this.currentView='Main';
          }else if(content.logged){
            this.multiple_comps = content.mltp_comp
            this.user_id = content.uid
            this.comp_id = content.cid
            this.isSubscription = false   
            this.currentView='Main';
          }else if(content.login && content.accChecked){
            this.multiple_comps = content.mltp_comp
            this.user_id = content.uid
            this.currentView='Companies';
          }else if(content.missingConfirmation){
            console.log('AAAAAAAAAAAAAAAAAAAAAAAAAAA Conta não verificada')
            console.log(content.uid)
            this.user_id = content.uid
            this.currentView='ConfirmNewAccount';
          }else{
            console.log('Tentativa de Login Falhou')
            this.showError = true;
          }
          this.lockForm = false;
        })
        .catch((err) => {
          this.lockForm = false;
          console.log(err)
        });
      }
    },
    rememberMe() {
      if (this.memoUser && this.formData.email !== null) {
        localStorage.username = this.formData.email;
        localStorage.checkbox = true;
      } else {
        localStorage.username = "";
        localStorage.checkbox = "";
      }
    },
    },
    watch: {
      // A cor vermelha dos inputs desaparece caso o usuário altere o que foi digitado:
      'formData.email'() {
        if (this.showError) {
          this.showError = false;
        }
      },
      'formData.pass'() {
        if (this.showError) {
          this.showError = false;
        }
      },
    },
    mounted(){
      if(this.memoUser){
        if (localStorage.checkbox && localStorage.checkbox !== "") {
          this.memoUser = true;
          this.formData.email = localStorage.username;
        } else {
          this.memoUser = false;
          this.formData.email = "";
        }
      }
    },
}
</script>

<style scoped>
  form {
    background-color: #FFF;
    padding: 40px;
    box-shadow: 0px 6px 8px -6px rgba(24, 39, 75, 0.12), 0px 8px 16px -6px rgba(24, 39, 75, 0.08);
    /* border-radius: 16px; */
    max-width: 500px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
    font-size: 18px;
    position: relative;
  }

  .form__logo {
    /* display: none; */
    margin: 0 auto;
    width: 200px;
  }

  .form__logo img{
    width: 100%;
  }

  .form__header{
    text-align: center;
  }

  .form__header > h2 {
    margin-bottom: 20px;
    font-size: 32px;
    margin-top: 8px;
  }

  .input, .botao {
    display: block;
    width: 100%;
    height: 60px;
    border-radius: 8px;
  }

  .input {
    border: 1px solid #E8E8E8;
    outline-color: var(--secundary-2);
    padding: 16px;
    font-size: 16px;
  }

  .input:first-of-type {
    margin-bottom: 16px;
  }

  .options {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
    font-size: 16px;
    flex-wrap: wrap;
    gap: 16px;
  }

  .remember {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  .remember > input {
    height: 18px;
    width: 18px;
  }

  .forgot {
    text-decoration: none;
    color: var(--primary);
  }

  .forgot:hover {
    text-decoration: underline;
  }

  .botao {
    border: 0;
    font-size: 18px;
    color: #FFF;
    font-weight: 700;
    background-color: var(--primary);
    margin-top: 10px;
    cursor: pointer;
    transition: .4s ease;
  }

  .botao.disabledSubmit {
    pointer-events: none;
  }

  .botao:hover {
    background-color: var(--secundary-3);
  }

  .form__error {
    color: #f62727;
    position: absolute;
    top: 120px;
    font-style: italic;
  }

.notRegisteredLink{
  font-size: 0.9em;
  text-align: center;
  width: max-content;
  color: var(--primary);
  margin: 0 auto;
}

  @media screen and (max-width: 1023px) {

    form {
      box-shadow: none;
      padding: 64px 16px 40px 16px;
    }

    .form__logo {
      display: block;
      margin: auto;
      margin-bottom: 32px;
    }

    .form__header{
      text-align: center;
    }

    .form__error {
      color: #f62727;
      position: absolute;
      top: 220px;
      font-style: italic;
    }
  }
</style>