<template>
  <section class="prednow__container">
    <h3 class="prednow__title">{{$t('predictNow.title')}}<button title="Help" aria-label="Help" @click="handleHelp()"></button></h3>
    <button title="Back to previous page" aria-label="Back to previous page" class="prednow__back-button" @click="backPage">
      <img src="../../assets/img/ai/icon-back-arrow.svg" alt="Back to previous page">
    </button>

    <div class="prednow__box">
      <div class="table__wrapper">
        <table>
          <tr>
            <th>{{$t('predictNow.tableHeads[0]')}}</th>
            <th>{{$t('predictNow.tableHeads[1]')}}</th>
            <th>{{$t('predictNow.tableHeads[2]')}}</th>
          </tr>
          <tr v-for="n in predictList" :key="n[0]">
            <td :title="n[0]">{{n[0]}}</td>
            <td :title="n[1]">{{n[1]}}</td>
            <td>
              <input type="text" placeholder="Enter a value" v-model="typedValues[n[0]]" @input="handleInput($event, n[0])">
            </td>
          </tr>
        </table>
      </div>

      <table class="table__result" v-if="showResult">
        <div v-if="isLoading" class="result__loading">
          <img src="../../assets/img/ai/icon-loading-blue.svg" alt="Loading">
        </div>
        <tr v-else>
          <td>Result</td>
          <td>Calculated</td>
          <td>{{APIresult}}</td>
        </tr>
      </table>

      <div class="prednow__buttons">
        <button @click="clearValues">{{$t('predictNow.btns[0]')}}</button>
        <button @click="predictNow">{{$t('predictNow.btns[1]')}}</button>
      </div>
    </div>
  </section>
</template>

<script>
import { storeToRefs } from 'pinia';
import { useAiStore } from '../../stores/ai';
import { useUserStore } from '../../stores/user';
export default {
  setup() {
    const aiStore = useAiStore();
    const { currentModelResults, targetColumn, selectedColumns, APItoken, isSessionExpired,  setModalHelper} = storeToRefs(aiStore);   
    const userStore = useUserStore();
    const { trigSessVerify } = userStore;
    const {user_id, empresa, comp_id, analysis,} = storeToRefs(userStore);
    return {
      currentModelResults,
      targetColumn,
      selectedColumns,
      APItoken,
      user_id, 
      empresa,
      comp_id, 
      analysis,
      isSessionExpired,
      trigSessVerify,
      setModalHelper,     
    }
  },
  methods: {
    handleHelp() {

      let titulo = this.$i18n.t("predictNow.modalHelp.title")
      let msg = this.$i18n.t("predictNow.modalHelp.msg")

      /* Cuidar ao fazer essa atribuição de objetos via destructuring!
        Pode ocorrer erros caso os nomes do atributo do objeto sejam
        iguais aos nomes das variáveis, por isso, sempre use variáveis com
        nome diferente para funcionar corretamente
      */
      this.setModalHelper = {
        title: titulo,
        message: msg,
        show: true,
        bigText: true,
      }
    },
    handleInput(e, column) {
      // Desagrupar valores separados por vírgula e organizar no formato que a PredictAPI solicita:
      const values = e.target.value.split(',');
      this.toSendValues[column] = values;
    },
    backPage() {
      this.currentModelResults = 'Metrics';
    },
    clearValues() {
      this.toSendValues = {};
      this.typedValues = {};
    },
    predictNow() {
      const missingValues = Object.keys(this.typedValues).length < Object.keys(this.selectedColumns).length;
      // Se há menos valores digitados do que solicitados:
      if (missingValues) {
        alert('Please enter data in all fields! Try again.')
        return
      }

      this.showResult = true;
      this.isLoading = true;

      let url = process.env.VUE_APP_URL + '/OneClickAPI'
      console.log('Valores a serem enviados: '+JSON.stringify(this.toSendValues))
      console.log('API Token: '+JSON.stringify(this.APItoken))
      let nB = {'data':this.toSendValues, 'api_key':this.APItoken, 'user_id':this.user_id, 'company': this.empresa, 'company_id': this.comp_id, 'pipeFlow':'mlOneClick' }
      this.trigSessVerify();
      fetch(url, {
        method:'POST',
        body:JSON.stringify(nB),
        headers: new Headers({
          "content-type":"application/json"
        })
      })
      .then((res) =>res.json())
      .then((content) => {
        console.log(JSON.stringify(content))
        if(content.invalidSession){
          this.isSessionExpired = true;
        } else if (content.errorAPI) {
          alert('Oops, something wrong happened. Try again.')
          this.showResult = false;
          this.isLoading = false;
        } else {
          this.isLoading = false
          this.APIresult = content.data.toString().replaceAll(',', ', ')
          console.log('API Result = '+this.APIresult)
        }
      })
      .catch((err) => {console.log('Erro, '+err)});
    },
  },
  data() {
    return {
      showResult: false,
      isLoading: true,
      predictList: [],
      toSendValues: {},
      typedValues: {},
      key: "",
      APIresult:[],
    }
  },
  mounted() {
    this.predictList = Object.entries(this.selectedColumns)
    let url = process.env.VUE_APP_URL + '/APIgetTk'
    console.log('anal id: '+this.analysis)
    let nB = {'a_id':this.analysis, 'u_id': this.user_id, 'c_id': this.comp_id}
    this.trigSessVerify();
    fetch(url, {
      method:'POST',
      body:JSON.stringify(nB),
      headers: new Headers({
        "content-type":"application/json"
      })
    })
    .then((res) =>res.json())
    .then((content) => {
      console.log(JSON.stringify(content))
      if(content.invalidSession){
        this.isSessionExpired = true;
      } else {
        this.APItoken = content.key
      }
    })
    .catch((err) => {console.log('Erro, '+err)}); 
  },
}
</script>

<style scoped>
.prednow__container {
  position: relative;
}

.prednow__title {
  font-size: 24px;
  border-top: 2px solid #E2E2E2;
  padding-top: 28px;
  margin: 32px 0;
  font-weight: 600;
}

.prednow__back-button {
  position: absolute;
  left: 0px;
  top: 26px;
  background: transparent;
  border: 0;
  cursor: pointer;
}

.prednow__box {
  background-color: #FFF;
  border-radius: 16px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
  box-shadow: 0px 6px 8px -6px rgba(24, 39, 75, 0.12), 0px 8px 16px -6px rgba(24, 39, 75, 0.08);
}

::-webkit-scrollbar {
  height: 8px;
  border-radius: 8px;
  width: 8px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #bdc5cc;
  border-radius: 8px;
  transition: 0.4s;
}

::-webkit-scrollbar-thumb:hover {
  background: #95b0c7;
}

.table__wrapper {
  overflow-y: scroll;
  max-height: 40vh;
  width: 100%;
  max-width: calc(100vw - 96px);
}

table {
  width: 100%;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  position: relative;
}

tr {
  display: inline-block;
}

tr:nth-child(even) {
  background-color: rgba(228, 235, 242, 0.4);
}

th,
td {
  min-width: 180px;
  max-width: 180px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 16px 8px;
  white-space: nowrap;
}

th {
  font-weight: 600;
  text-transform: uppercase;
  height: auto;
  white-space: nowrap;
}

td > input {
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid #A2A4A4;
  max-width: 100%;
  outline: 0;
  padding: 4px;
}

.prednow__buttons {
  display: flex;
  gap: 24px;
  justify-content: center;
  flex-wrap: wrap;
}

.prednow__buttons > button {
  width: 200px;
  height: 40px;
  border-radius: 8px;
  border: 2px solid var(--primary);
  color: white;
  font-weight: 500;
  background-color: var(--primary);
  cursor: pointer;
  transition: .4s ease;
  font-size: 14px;
}

.prednow__buttons > button:hover {
  background-color: var(--secundary-3);
  border-color: var(--secundary-3);
}

.prednow__buttons > button:first-of-type {
  background: transparent;
  color: var(--primary);
} 

.prednow__buttons > button:first-of-type:hover {
  color: var(--secundary-3);
} 

.table__result > tr {
  background-color: #F4F7FA;
  transform: translateX(-4px);
}

.result__loading {
  max-width: 28px;
  margin: auto;
}

@keyframes girar {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.result__loading > img {
  width: 100%;
  height: 48px;
  animation: girar 1.5s infinite linear;
}

h3 button{
  cursor: pointer;
  height: 20px;
  width: 20px;
  background: url(../../assets/img/ai/icon-help.svg) no-repeat center / contain;
  border: 0;
  transition: 0.4s ease;
  position: relative;
  left: 9px;
  top: 3px;
}

h3 > button:hover  {
  transform: scale(1.2);
}


</style>