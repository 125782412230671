<template>
  <ModalSession v-if="isSessionExpired" />
  <component :is="currentView"></component>
</template>

<script>
import { useViewSelector } from "./stores/viewSelector";
import { useUserStore } from './stores/user';
import { storeToRefs } from 'pinia'; 

import Login from './components/views/Login.vue'
import Main from './components/views/Main.vue'
import Companies from './components/views/Companies.vue'
import ConfirmNewAccount from './components/views/ConfirmNewAccount.vue'
import ModalSession from './components/elements/ModalSession.vue'
// import SignupPage from './components/pages/SignupPage.vue'
export default {
  name: 'App',
  data(){
    return{
       selectCompany:false,
    }
  },
  setup() {
      const viewSelector = useViewSelector();
      const { currentView } = storeToRefs(viewSelector);
      
      const userStore = useUserStore();
      const { user_id, comp_id, isSessionExpired, isSubscription, statusSub, multiple_comps } = storeToRefs(userStore);

      return {
        currentView,
        isSessionExpired,
        user_id,
        comp_id,
        isSubscription,
        statusSub,
        multiple_comps, 
      }
    },
  mounted(){
    // Set main lang
    let langUrl = process.env.VUE_APP_URL + '/getLang';
    
    //GET Lang on First Page Load, Set Language (before login) ? 
    fetch(langUrl, {
      method:'GET',
    })
    .then((res) => res.json())
    .then((content) => {
      console.log('mounted App.vue check');
      console.log(content.lang)
      console.log(JSON.stringify(content.lang))
      const storedLang = content.lang;
      if (storedLang) {
        console.log('inside if storedLang: '+storedLang)
        this.currentLang = storedLang;
        this.$root.$i18n.locale = this.currentLang;
      } else {
        const browserLang = navigator.language || navigator.browserLanguage;
        this.currentLang = browserLang;
        console.log('inside else browserLang: '+this.currentLang)
        this.$root.$i18n.locale = this.currentLang;
      }
    })

    //Verify if user have an active session
    




    // console.log(process.env.DB_HOST)
    // console.log(process.env.PORT)
    // console.log(process.env.DB_NAME)
    // console.log(process.env.DB_USER)
    // console.log(process.env.DB_PASS)
    // console.log(process.env.VUE_APP_URL)
    // console.log(process.env.ANALYTICS)
    // console.log(process.env.ML)
    // console.log(process.env.PREDICT)
    // console.log(process.env.TK_GenDownload)

    let url = process.env.VUE_APP_URL + '/verification';
    //Adicionar flag de conta não confirmada, redirecionar para tela de confirmação 
    fetch(url, {
      method:'GET',
    })
    .then((res) => res.json())
    .then((content) => {
      if(content.activeSession === true){
        if(content.mltp_comp){
          this.multiple_comps = true;
          console.log('Entrei mltp if: ' + this.multiple_comps)
        }else{
          this.multiple_comps = false;
        }
        if(content.isSubscription){
          this.isSubscription = content.isSubscription
          this.statusSub = content.statusSub
        }
        console.log(JSON.stringify(content))
        console.log(JSON.stringify(content.userInfo[0]))
        this.user_id = content.userInfo[0].user_id;
        this.comp_id = content.userInfo[0].comp_id;
        this.currentView='Main';
      }else {
        if(content.missingTk === true){
          this.currentView='ConfirmNewAccount'
          this.user_id = content.u_id;
        }else if(content.noCompanySelected === true){
          this.currentView='Companies';
          this.user_id = content.u_id;
        }else{
          this.currentView = 'Login';
        }
      }
    }).catch((err) => {console.log('Erro, '+err)}); 
  },
  methods:{
  },
  components: {
    Login,
    Main,
    Companies,
    // SignupPage,
    ModalSession,
    ConfirmNewAccount,
  },
}
</script>

<style>
  
</style>