<template>
  <section class="modal__overlay">
    <div class="modal__container">
      <div class="modal__banner">
        <button title="Close modal" aria-label="Close modal" class="modal__closeButton" @click="closeModal"></button>
      </div>
      <div class="modal__content">
        <h3 class="modal__title">{{setModalHelper.title}}</h3>
        <p class="modal__message" :class="{bigText: setModalHelper.bigText}" v-html="setModalHelper.message"></p>
        
        <button class="modal__button" @click="closeModal">{{$t('modalHelper.button')}}</button>
      </div>
    </div>
  </section>
</template>

<script>
import { storeToRefs } from 'pinia';
import { useAiStore } from '../../stores/ai';
export default {
  name: "ModalHelper",
  setup() {
    const aiStore = useAiStore();
    const { setModalHelper } = storeToRefs(aiStore);

    return {
      setModalHelper
    }
  },
  methods: {
    closeModal() {
      this.setModalHelper.show = false;
    },
  },
}
</script>

<style scoped>
.modal__overlay {
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  width: calc(100vw - 96px);
  z-index: 20;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: .3s esmaecer;
  padding: 16px;
}

@keyframes esmaecer {
  from {
    opacity: 0
  }
  to {
    opacity: 1;
  }
}

.modal__container {
  background-color: #FFF;
  width: 100%;
  max-width: 640px;
  min-height: 280px;
  border-radius: 16px;
  display: grid;
  grid-template-areas: 'banner' 'content';
  grid-template-rows: 120px 1fr;
  box-shadow: 0px 6px 8px -6px rgba(24, 39, 75, 0.12), 0px 8px 16px -6px rgba(24, 39, 75, 0.08);
  animation: .3s crescer;
}

@keyframes crescer {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

.modal__banner {
  background: url(../../assets/img/ai/icon-helper.svg) no-repeat center / contain;
  background-size: 64px;
  background-color: var(--secundary-3);
  border-radius: 16px 16px 0 0;
  grid-area: banner;
  position: relative;
  padding: 24px;
}

.modal__closeButton {
  height: 32px;
  width: 32px;
  background: url(../../assets/img/main/close.svg) no-repeat center / contain;
  border: 0;
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
}

.modal__content {
  display: flex;
  flex-direction: column;
  grid-area: content;
  padding: 32px;
  margin: auto;
  width: 100%;
}

.modal__title {
  color: var(--secundary-3);
  font-weight: 600;
  font-size: 24px;
  text-align: center;
  margin-bottom: 24px;
}

.modal__message {
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  white-space: pre-line;
}

.modal__button {
  border: 0;
  background-color: var(--primary);
  color: #FFF;
  border-radius: 8px;
  height: 40px;
  width: 100%;
  max-width: 200px;
  margin: auto;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: .4s ease;
  margin-top: 32px;
}

.bigText{
  text-align: justify;
}

.modal__button:hover {
  background-color: var(--secundary-3);
}

@media screen and (max-width: 767px) {
  .modal__overlay {
    width: 100vw;
  }
}
</style>