<template>
  <ModalEdit v-if="showEditModal" @close-modal="toggleShowEditModal" @analysis-updated="updateAnalysisName" :fields="{[fieldModalEdit] : selectedPrevAnalysis.name}" :title="titleModalEdit" :analysisID="selectedPrevAnalysis.id" />
  <section class="results__container">
    <h2 class="results__title">{{ $t('modelResults.title') }}</h2>
    <button title="Back to previous page" aria-label="Back to previous page" class="predapi__back-button" @click="redirectTo('AiChoose')">
      <img src="../../assets/img/ai/icon-back-arrow.svg" alt="Back to previous page">
    </button>

    <!-- Card com informações da análise atual -->
    <div class="results__infos">
      <div>
        <div class="infos__name">
          <h3 class="infos__title">{{selectedPrevAnalysis.name}}</h3>
          <button title="Edit analysis name" aria-label="Edit analysis name" class="edit-button" @click="toggleShowEditModal">
            <img src="../../assets/img/ai/icon-edit.svg" alt="Info">
          </button>
        </div>
        <h2 class="infos__subtitle">{{selectedPrevAnalysis.type}}</h2>
        <ul>
          <li class="infos__item" v-for="info in Object.entries(selectedPrevAnalysis.infos)" :key="info[0]" :title="info[0]">
            <strong>{{ getTranslatedLabelsFromAnalysisCard(info[0]) }}:</strong> {{info[1]}}
          </li>
        </ul>
      </div>
      <!-- <img src="../../assets/img/ai/ai-hand.svg" alt="AI hand"> -->
    </div>
    
    <!-- Metrics, PredictNow, PredictAPI: -->
    <component :is="currentModelResults"></component>
  </section>
</template>

<script>
import { storeToRefs } from 'pinia';
import { useAiStore } from '../../stores/ai';
import { usePageSelector } from '../../stores/pageSelector';
import { useUserStore } from '../../stores/user';
import Metrics from "./Metrics.vue";
import PredictAPI from "./PredictAPI.vue";
import PredictNow from "./PredictNow.vue";
import Explainer from './Explainer.vue';
import ModalEdit from "../elements/ModalEdit.vue";

export default {
  name: "ModelResults",
  components: { Metrics, PredictAPI, PredictNow, ModalEdit, Explainer },
  setup() {
    const aiStore = useAiStore();
    const { currentComponent, currentModelResults, prevAnalysisList, selectedColumns, targetColumn, APItoken, quickLatestAnalysis, } = storeToRefs(aiStore);
    
    const pageSelector = usePageSelector();
    const { redirectTo } = pageSelector;

    const userStore = useUserStore();
    const {user_id, comp_id, analysis, transf_id, isSessionExpired,} = storeToRefs(userStore)
    const { trigSessVerify, } = userStore;

    return {
      currentComponent,
      currentModelResults,
      redirectTo,
      analysis,
      prevAnalysisList,
      selectedColumns,
      targetColumn,
      user_id,
      comp_id,
      APItoken,
      transf_id,
      trigSessVerify,
      isSessionExpired,
      quickLatestAnalysis,
    }
  },
  data() {
    return {
      selectedPrevAnalysis: { infos: {} },
      showEditModal: false,
      fieldModalEdit: this.$i18n.t("modalEdit.fields[0]"),
      titleModalEdit: this.$i18n.t("modalEdit.titles[0]"),
    }
  },
  methods: {
    toggleShowEditModal() {
      this.showEditModal = !this.showEditModal
    },
    updateAnalysisName(newName) {  
      // Atualizar nome da análise no front após edição e atualização no banco:
      this.selectedPrevAnalysis.name = newName;
      this.toggleShowEditModal()
    },
    getTranslatedLabelsFromAnalysisCard(label){
      switch (label){
        case 'dataset': return this.$i18n.t('modelResults.infoCards[0]') 
        case 'transformations': return this.$i18n.t('modelResults.infoCards[1]') 
        case 'created at': return this.$i18n.t('modelResults.infoCards[2]') 
        case 'creator': return this.$i18n.t('modelResults.infoCards[3]') 
        case 'status': return this.$i18n.t('modelResults.infoCards[4]') 
      }
    }
  },
  mounted() {
    console.log(this.quickLatestAnalysis)
    if(this.quickLatestAnalysis){
      // Caso a gente tenha pulado a tela de Choose, pois é lá que preenchemos a 'this.prevAnalysisList'
      console.log('ENTREI PORRAAAA, MOUNT ANALYSIS')
      let url3 = process.env.VUE_APP_URL + '/mountAnalysis'
      let nB2 = {'a_id':this.analysis, 'u_id':this.user_id, /*this.comp_id*/ }
      this.trigSessVerify();
      fetch(url3, {
        method:'POST',
        body:JSON.stringify(nB2),
        headers: new Headers({
          "content-type":"application/json"
        })
      })
      .then((res) =>res.json())
      .then((content) => {
        console.log(JSON.stringify(content))
        console.log(JSON.stringify(content.analysis))
        if(content.invalidSession){
          this.isSessionExpired = true;
        }else {
          this.selectedPrevAnalysis = content.analysis
          console.log(JSON.stringify(this.selectedPrevAnalysis))
          console.log('CUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUU')          
        }
      })
      .catch((err) => {console.log('Erro, '+err)});
    }
    else{
      // Buscar informações da análise selecionada: (caso siga o flow padrão)
      this.selectedPrevAnalysis = this.prevAnalysisList.find(el => el.id == this.analysis);
    }

    // Buscar selectedColumns da análise atual e salvar na store (para exibir nas telas de predicts):
    let url = process.env.VUE_APP_URL + '/analysisColumnsType'
    console.log('transf_id: '+this.transf_id)
    let nB = {'transf_id':this.transf_id}
    this.trigSessVerify();
    fetch(url, {
      method:'POST',
      body:JSON.stringify(nB),
      headers: new Headers({
        "content-type":"application/json"
      })
    })
    .then((res) =>res.json())
    .then((content) => {
      console.log('analysisColumnsType: ', JSON.stringify(content));
      if(content.invalidSession){
          this.isSessionExpired = true;
      }else {
        let filtered = Object.entries(content).filter(array => array[0] != this.targetColumn)
        this.selectedColumns = Object.fromEntries(filtered);
      }
    })
    

    


    // Obter API Token:
    let url2 = process.env.VUE_APP_URL + '/APIgetTk'
    let nB2 = {'a_id':this.analysis, 'u_id': this.user_id, 'c_id': this.comp_id}
    this.trigSessVerify();
    fetch(url2, {
      method:'POST',
      body:JSON.stringify(nB2),
      headers: new Headers({
        "content-type":"application/json"
      })
    })
    .then((res) =>res.json())
    .then((content) => {
      console.log(JSON.stringify(content))
      if(content.invalidSession){
        this.isSessionExpired = true;
      } else {
        this.APItoken = content.key
      }
    })
    .catch((err) => {console.log('Erro, '+err)});
  },
  unmounted() {
    this.currentModelResults = "Metrics"

    // resetando a flag
    this.quickLatestAnalysis = false
  }
}
</script>

<style scoped>
.results__container {
  margin: auto;
  margin-top: 32px;
  max-width: 640px;
  animation: surgir 1s;
  text-align: center;
  font-size: 16px;
  position: relative;
  
}

.predapi__back-button {
  position: absolute;
  left: 0px;
  top: 2px;
  background: transparent;
  border: 0;
  cursor: pointer;
}

@keyframes surgir {
  from {
    opacity: 0.5;
    transform: translateY(32px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.results__title {
  font-size: 2em;
  font-weight: 600;
}

.results__infos {
  box-shadow: 0px 6px 8px -6px rgba(24, 39, 75, 0.12), 0px 8px 16px -6px rgba(24, 39, 75, 0.08);
  background-color: #FFF;
  border-radius: 6px;
  padding: 32px;
  margin: 32px 0;
  text-align: left;
  display: flex;
  justify-content: space-between;
}

.results__infos > img {
  padding-left: 32px;
}

.infos__name {
  display: flex;
  gap: 8px;
  align-items: center;
  max-width: 100%;
  margin-bottom: 12px;
}
.edit-button {
  display: grid;
  place-content: center;
  width: 32px;
  height: 32px;
  cursor: pointer;
  background: transparent;
  border: 0;
  border-radius: 50%;
  transition: .4s ease;
}
.edit-button:hover {
  background-color: rgba(39, 94, 246, 0.08);
}
.infos__title {
  color: var(--secundary-3);
  font-size: 16px;
  font-weight: 600;
  max-width: 248px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.infos__subtitle {
  font-size: 14px;
  font-weight: 400;
  color: var(--secundary-3);
  margin-bottom: 24px;
}

.infos__item {
  font-size: 14px;
  line-height: 20px;
}
.infos__item > strong,
.infos__item[title="creator"] {
  text-transform: capitalize;
}
.infos__item::before {
  content: '';
  display: inline-block;
  height: 4px;
  width: 4px;
  background-color: var(--secundary-3);
  border-radius: 100%;
  margin-right: 8px;
  margin-bottom: 2px;
}

.infos__item:not(.infos__item:last-of-type) {
  margin-bottom: 16px;
}

@media screen and (max-width: 480px) {
  .results__infos > img {
    display: none;
  }
  .results__container {
    padding-top: 40px;
  }
  .results__title {
    font-size: 1.75em;
  }
}
</style>