import { defineStore } from "pinia";
import ModalSession from '../components/elements/ModalSession.vue'
export const useUserStore = defineStore("user", {
  state: () => {
    return {
      firstname: "",
      lastname: "",
      fullname: "",
      user_id: "",
      empresa: "",
      comp_id: "",
      bucket: "",
      originalFileName:"",
      anlyticsOrigFileName:"",
      fileName: "",
      dataset_id: "",
      transf_id: "",
      analysis: "", //Analysis ID
      isSessionExpired: false,
      timeoutID:0,
      isSubscription:false,
      statusSub:null,
      multiple_comps:false,
      emailForRESET: '', //Email no pinia pro fluxo de reset de senha
    };
  },
  components: {ModalSession},
  // could also be defined as
  // state: () => ({ count: 0 })
  actions: {
    //TriggerAreYouThere ? (netflix) - 10min before session expirement
    trigSessVerify(){
      clearTimeout(this.timeoutID)
      let timerCont = setTimeout(this.fetchVerify, 7205000)
      this.timeoutID = timerCont
      console.log('TimerCont: '+timerCont)
    },
    fetchVerify(){
      console.log('fetchVerify');
      let url = process.env.VUE_APP_URL + '/verification';
      fetch(url, {
        method:'GET',
      })
      .then((res) => res.json())
      .then((content) => {
        if(content.activeSession === true){
          console.log('Sessão ok')
          return true;
        } else{
          if(content.noCompanySelected === true){
            this.currentView='Companies';
          }else{
            this.isSessionExpired = true;
          }
        }
      }).catch((err) => {console.log('Erro, '+err)});
    },
    clearTrigger(){
      clearTimeout(this.timeoutID)
    },
  },
});
