<template>
  <section>
    <!-- <ModalDataGenius v-if="setModalDataGenius.show"/> -->
    <!-- Botões dos predicts: -->
    <div class="results__buttons">
      <button @click="showResult('PredictAPI')">
        <img src="../../assets/img/ai/icon-brackets.svg" alt="Predict API">
        {{ $t('metrics.btns[0]') }}
      </button>
      <button @click="showResult('PredictNow')" :class="{disable: canCreateAnalysis}">
        <img src="../../assets/img/ai/icon-cursor.svg" alt="One click">
        {{ $t('metrics.btns[1]') }}
      </button>
      <button @click="redirectTo('AiNewPrediction')" :class="{disable: canCreateAnalysis}">
        <img src="../../assets/img/ai/icon-plus.svg" alt="One click">
        {{ $t('metrics.btns[2]') }}
      </button>
      <!-- Botão do explainer comentado, pois feature ñ está pronta ainda -->
      <!-- <button @click="showResult('Explainer')" :class="{disable: canCreateAnalysis}">
        <img src="../../assets/img/ai/icon-plus.svg" alt="One click">
        {{ $t('metrics.btns[3]') }}
      </button> -->
    </div>

    <!-- Cards das métricas numéricas: -->
    <div class="results__metrics">
      <h3 class="metrics__title">{{ $t('metrics.title[0]') }}</h3>
      <ul>
        <li class="metrics__card" v-for="metric in metricsList" :key="metric[0]">
          <span class="metrics__value">
            {{
              metric[1]
            }}
          </span>
          <span class="metrics__name">{{metric[0]}}</span>
        </li>
      </ul>
    </div>

    <ul class="results__graphs">
      <!-- Category Regression `Scatter Plot with Line` Chart: -->
      <li v-if='tableCLT' class="graph">
        <tableCluster />
      </li>
      <li v-if='icp' class="graph">
        <tableIcp />
      </li>
      <li v-if='scatter' class="graph">
        <scatterPlot />
      </li>
      <!-- Category Prediction Chart: -->
      <li v-if='bar' class="graph">
        <barChart />
      </li>
      <!-- Confusion Matrix Chart: -->
      <li v-if='matrix' class="graph matrix">
        <div class="graph__matrix">
          <ConfusionMatrix />
        </div>   
      </li>
    </ul>

    <div class="results__better">
      <h3 class="better__title">
        {{ $t('metrics.dataGenius[0]') }}
      </h3>
      <p class="better__desc">{{ $t('metrics.dataGenius[1]') }}</p>
      <div class="better__buttons">
        <!-- <button>Guides</button> -->
        <button @click="showModalDataGenius">{{ $t('metrics.dataGenius[2]') }}</button>
      </div>
    </div>
  </section>
</template>

<script>
import { storeToRefs } from 'pinia';
import { useAiStore } from '../../stores/ai';
import { useUserStore } from '../../stores/user';
import { usePageSelector } from '../../stores/pageSelector';

import barChart from "./AIbar.vue";
import ConfusionMatrix from "./Matrix.vue";
import scatterPlot from "./scatterPlot.vue";
import tableCluster from "./tableCluster.vue";
import tableIcp from "./TableIcp.vue";

// import ModalDataGenius from '../elements/ModalDataGenius.vue';

export default {
  setup() {
    const aiStore = useAiStore();
    const { currentModelResults , setModalDataGenius} = storeToRefs(aiStore);

    const userStore = useUserStore();
    const {analysis, isSessionExpired, isSubscription} = storeToRefs(userStore)
    const {trigSessVerify, statusSub} = userStore

    const pageSelector = usePageSelector();
    const { redirectTo } = pageSelector;
    return {
      currentModelResults,
      setModalDataGenius,
      analysis,
      redirectTo,
      isSessionExpired,
      trigSessVerify,
      statusSub,
      isSubscription,
    }
  },
  components:{
    barChart,
    ConfusionMatrix,
    scatterPlot,
    tableCluster,
    tableIcp,

    // ModalDataGenius,
  },
  data() {
    return {
      scatter: false,
      bar: false,
      matrix: false,
      tableCLT: false,
      icp:false,
      metricsList: [],

    }
  },
  methods: {
    // Atualizar o componente que está sendo renderizado na ModelResult:
    showResult(result) {
      console.log(result)
      this.currentModelResults = result;
    },
    showModalDataGenius(){
      this.setModalDataGenius.show = true
      this.setModalDataGenius.title = this.$i18n.t('metrics.dataGeniusModal[0]')
      this.setModalDataGenius.msg = this.$i18n.t('metrics.dataGeniusModal[1]')
    }
  }, 
  computed: {
    canCreateAnalysis(){
      if(this.isSubscription){
        switch (this.statusSub){
          case 'past_due': return false;
  
          case 'active': return false;
  
          // (incomplete, incomplete_expired, canceled, unpaid) 
          default: return true;
        }
      }else{
        return false
      }
    },
  },
  mounted(){
    let url = process.env.VUE_APP_URL + '/AIMetricResult'
    console.log('anal id: '+this.analysis)
    let nB = {'a_id':this.analysis}
    this.trigSessVerify();
    fetch(url, {
      method:'POST',
      body:JSON.stringify(nB),
      headers: new Headers({
        "content-type":"application/json"
      })
    })
    .then((res) =>res.json())
    .then((content) => {
      console.log(JSON.stringify(content));
      if(content.invalidSession){
          this.isSessionExpired = true;
      }else if (content.error) {
        console.error("Erro ao pegar os dados dos gráficos na /AIMetricResult")
        return
      } else{
          console.log(content)
          console.log(JSON.stringify(content))
          // console.log(JSON.stringify(content.metricsArray))
          this.metricsList = content.metricsArray;
          // console.log(JSON.stringify(this.metricsList))
          this.tableCLT = content.graphs.importance_columns;
          this.scatter = content.graphs.scatterPlot;
          this.bar = content.graphs.AIbar;
          this.matrix = content.graphs.matrix;
          this.icp = content.graphs.icp_analysis;
          // console.log(this.matrix)
          // console.log(this.scatter)
          // console.log(this.bar)
      } 
    }).catch(err => {
      console.log(err)
    })
  },
}
</script>

<style scoped>

.results__buttons > button.disable{
  opacity: 0.4;
  cursor: not-allowed;
  pointer-events: none;
}

.results__buttons,
.better__buttons {
  display: flex;
  gap: 16px;
  justify-content: center;
}

.results__buttons > button,
.better__buttons > button {
  background-color: var(--primary);
  color: #FFF;
  font-weight: 600;
  font-size: 14px;
  padding: 10px;
  height: 40px;
  width: 100%;
  max-width: 200px;
  border: 0;
  border-radius: 4px;
  cursor: pointer;
  transition: .4s ease;
  white-space: nowrap;
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: center;

}

@media screen and (max-width: 600px) {
  .results__buttons,
  .better__buttons {
    gap: 16px;
    flex-wrap: wrap;
  }
}

.results__buttons > button:hover {
  background-color: var(--secundary-3);
} 

.metrics__title {
  font-size: 24px;
  border-top: 2px solid #E2E2E2;
  padding-top: 28px;
  margin: 32px 0;
  font-weight: 600;
}

.results__metrics > ul {
  display: flex;
  justify-content: center;
  gap: 24px;
  flex-wrap: wrap;
}

.metrics__card {
  min-width: 88px;
  width: max-content;
  padding: 0 10px;
  height: 88px;
  background-color: #FFF;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 6px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  box-shadow: 0px 6px 8px -6px rgba(24, 39, 75, 0.12), 0px 8px 16px -6px rgba(24, 39, 75, 0.08);
}

.metrics__value {
  font-weight: 600;
  font-size: 20px;
}

.metrics__name {
  font-weight: 500;
  font-size: 12px;
  color: #888;
}

.results__graphs {
  margin: 40px 0;
  display: flex;
  flex-direction: column;
  gap: 40px;

}

.graph {
  background-color: #FFF;
  border-radius: 0px;
  min-height: 380px;
  padding: 24px;
  box-shadow: 0px 6px 8px -6px rgba(24, 39, 75, 0.12), 0px 8px 16px -6px rgba(24, 39, 75, 0.08);
}

.graph.matrix {
  width: 100%;
  min-height: auto;
  margin: auto;
  display: grid;
  place-content: center;
  box-shadow: 0px 6px 8px -6px rgba(24, 39, 75, 0.12), 0px 8px 16px -6px rgba(24, 39, 75, 0.08);
}

.graph__matrix {
  overflow-x: scroll;
  overflow-y: hidden;
  transform: translateY(4px);
}

.results__better {
  margin: 64px 0 40px 0;
}

.better__title {
  font-weight: 600;
  font-size: 24px;
}

.better__desc {
  font-weight: 400;
  font-size: 16px;
  margin-top: 16px;
  margin-bottom: 24px;
}

.better__buttons > button {
  background: transparent;
  border: 2px solid var(--primary);
  color: var(--primary);
}

.better__buttons > button:hover {
  color: var(--secundary-3);
  border-color: var(--secundary-3);
}

::-webkit-scrollbar {
  height: 8px;
  border-radius: 4px;
  width: 8px;
}

::-webkit-scrollbar-track {
  background: transparent;
  margin-left: 24px;
}

::-webkit-scrollbar-thumb {
  background: #bdc5cc;
  border-radius: 4px;
  transition: 0.4s;
}

::-webkit-scrollbar-thumb:hover {
  background: #95b0c7;
}

</style>