<template>
  <ModalEdit v-if="showEditModal" @close-modal="toggleShowEditModal" @analysis-updated="updateAnalysisName" :fields="{[fieldModalEdit] : selectedPrevAnalysis.name}" :title="titleModalEdit" :analysisID="selectedPrevAnalysis.id" />
  <section class="status__container">
    <h2 class="status__title">Model Status</h2>
    <button title="Back to previous page" aria-label="Back to previous page" class="predapi__back-button" @click="backPage">
      <img src="../../assets/img/ai/icon-back-arrow.svg" alt="Back to previous page">
    </button>

    <div class="results__infos">
      <div>
        <div class="infos__name">
          <h3 class="infos__title">{{selectedPrevAnalysis.name}}</h3>
          <button title="Edit analysis name" aria-label="Edit analysis name" class="edit-button" @click="toggleShowEditModal">
            <img src="../../assets/img/ai/icon-edit.svg" alt="Info">
          </button>
        </div>
        <h2 class="infos__subtitle">{{selectedPrevAnalysis.type}}</h2>
        <ul>
          <li class="infos__item" v-for="info in Object.entries(selectedPrevAnalysis.infos)" :key="info[0]" :title="info[0]">
            <strong>{{info[0]}}:</strong> {{info[1]}}
          </li>
        </ul>
      </div>
      <img src="../../assets/img/ai/ai-hand.svg" alt="AI hand">
    </div>

    <div class="status__graphs">
      <div class="status__graph">
        <aiDailyPred />
      </div>

      <div class="status__graph">
        <aiDailyReq />
      </div>

      <div class="status__graph" v-if="selectedPrevAnalysis.type === 'Classification'">
        <aiCategSplit />
      </div>
    </div>
  </section>
</template>

<script>
import { storeToRefs } from 'pinia';
import { useUserStore } from '../../stores/user';
import { useAiStore } from '../../stores/ai';

import aiDailyPred from '../graphs/aiDailyPred.vue';
import aiDailyReq from '../graphs/aiDailyReq.vue';
import aiCategSplit from '../graphs/aiCategSplit.vue';
import ModalEdit from "../elements/ModalEdit.vue";

export default {
  name: "ModelStatus",
  components: { aiDailyPred, aiDailyReq, aiCategSplit, ModalEdit },
  setup() {
    const userStore = useUserStore();
    const { analysis } = storeToRefs(userStore);
    const aiStore = useAiStore();
    const { prevAnalysisList } = storeToRefs(aiStore);
    return {
      analysis,
      prevAnalysisList,
    }
  },
  methods: {
    backPage() {
      this.$emit('back-page')
    },
    toggleShowEditModal() {
      this.showEditModal = !this.showEditModal
    },
    updateAnalysisName(newName) {  
      this.selectedPrevAnalysis.name = newName;
      this.toggleShowEditModal()
    },
  },
  data() {
    return {
      selectedPrevAnalysis: { infos: {} },
      showEditModal: false,
      fieldModalEdit: this.$i18n.t("modalEdit.fields[0]"),
      titleModalEdit: this.$i18n.t("modalEdit.titles[0]"),
    }
  },
  mounted() {
    this.selectedPrevAnalysis = this.prevAnalysisList.find(el => el.id == this.analysis);
  },
}
</script>

<style scoped>
.status__container {
  max-width: 1280px;
  margin: auto;
  position: relative;
}
.status__title {
  font-size: 32px;
  font-weight: 600;
}
.results__infos {
  background-color: #FFF;
  border-radius: 16px;
  padding: 32px;
  margin: 32px 0;
  text-align: left;
  display: flex;
  justify-content: space-between;
}
.results__infos > img {
  padding-left: 32px;
}
.infos__name {
  display: flex;
  gap: 8px;
  align-items: center;
  max-width: 100%;
  margin-bottom: 12px;
}
.edit-button {
  display: grid;
  place-content: center;
  width: 32px;
  height: 32px;
  cursor: pointer;
  background: transparent;
  border: 0;
  filter: brightness(0) saturate(100%) invert(15%) sepia(66%) saturate(5817%) hue-rotate(244deg) brightness(59%) contrast(135%);
  border-radius: 50%;
  transition: .4s ease;
}
.edit-button:hover {
  background-color: rgba(39, 94, 246, 0.08);
}
.infos__title {
  color: var(--secundary-3);
  font-size: 16px;
  font-weight: 600;
  max-width: 248px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.infos__subtitle {
  font-size: 14px;
  font-weight: 400;
  color: var(--secundary-3);
  margin-bottom: 24px;
}
.infos__item {
  font-size: 14px;
  line-height: 1.4;
}
.infos__item > strong,
.infos__item[title="creator"] {
  text-transform: capitalize;
}
.infos__item::before {
  content: '';
  display: inline-block;
  height: 4px;
  width: 4px;
  background-color: var(--secundary-3);
  border-radius: 100%;
  margin-right: 8px;
  margin-bottom: 2px;
}
.infos__item:not(.infos__item:last-of-type) {
  margin-bottom: 16px;
}
.status__graphs {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  justify-content: center;
}
.status__graph {
  background-color: #FFF;
  border-radius: 16px;
  width: calc(50% - 12px);
  padding: 24px;
}
.predapi__back-button {
  position: absolute;
  left: 0px;
  top: 2px;
  background: transparent;
  border: 0;
  cursor: pointer;
  transform: scale(1.2);
}
@media screen and (max-width: 767px) {
  .status__graphs {
    flex-direction: column;
  }
  .status__graph {
    width: calc(100vw - 32px);
  }
}
@media screen and (max-width: 480px) {
  .results__infos > img {
    display: none;
  }
}
</style>