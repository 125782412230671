<template>
  <div class="table__container">
    <div class="table_wrapper">
      <table>
        <tr>
          <th v-for="name in tableContent.columnsName" :key="name" :title="name">
            <span>{{name}}</span>
          </th>
        </tr>
        <tr v-for="(row) in tableContent.rowsData" :key="row">
          <td v-for="(data) in row" :key="data" :title="data">
            <span>{{data}}</span>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>

import { useViewSelector } from "../../stores/viewSelector";
import { useUserStore } from '../../stores/user';
import { storeToRefs } from 'pinia';


  export default{
    name: 'TableCluster',
    components: '',
    setup(){
      const viewSelector = useViewSelector();
      const {currentView} = storeToRefs(viewSelector);
      
      const userStore = useUserStore();
      const {analysis, isSessionExpired} = storeToRefs(userStore);
      const { trigSessVerify } = userStore;

      return {
        analysis,
        currentView,      
        isSessionExpired,
        trigSessVerify,
      }
    },

    data(){
      return{
        
        tableContent:{
          columnsName:[],
          rowsData: [],
        }
      }
    },
    mounted(){
      let url = process.env.VUE_APP_URL + '/AIResultClust';
      let nB = {'a_id':this.analysis}
      fetch(url , {
        method:'POST',
        body:JSON.stringify(nB),
        headers: new Headers({
          "content-type":"application/json"
        })
      })
      .then((res) => res.json())
      .then((content)=>{
        console.log(content)
        if(content.invalidSession){
          this.isSessionExpired = true;
        } else if (content.error || content.noRender) {
          return
        } else{
          this.tableContent.columnsName = content.name
          this.tableContent.rowsData = content.data
        }
      }).catch( (error) => console.log('ERRO: '+error))
    }
  }
</script>

<style scoped>
  .table__container {
    margin: auto;
    background-color: #FFF;
    padding: 24px;
    border-radius: 16px;
    width: 600px;
    height: 600px;
    margin-bottom: 32px;
    overflow: scroll;
  }
/* 
  .table__container::-webkit-scrollbar {
    width: 7px;
    height: 4px;
  }

  .table__container::-webkit-scrollbar-track{
    background: var(--secundary-1);
  }

  .table__container::-webkit-scrollbar-thumb{
    background: var(--secundary-3);
    border-radius: 8px;
    transition: .4s;
  }
 */
  .table__wrapper {
    max-width: 70vw;
    max-height: 60vh;
    overflow: scroll;
  }

  ::-webkit-scrollbar {
    height: 8px;
    border-radius: 8px;
    width: 8px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
    margin-left: 24px;
  }
  ::-webkit-scrollbar-thumb {
    background: #bdc5cc;
    border-radius: 8px;
    transition: 0.4s;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #95b0c7;
  }
  
  table{
    border-collapse: collapse;
    font-size: 14px;
  }

  tr{
    display: block;
  }

  tr:nth-child(even){
    background-color: rgba(228, 235, 242, 0.4);
  }

  td span{
    padding: 4px;
  }

  td , th{
    min-width: 180px;
    max-width: 180px;
    text-align: left;
    overflow: hidden;
    padding: 16px 8px;
    text-overflow: ellipsis;
  }
</style>