<template>
  <div :class="heatmapWrap">
    <apexchart type="heatmap" :options="options" :series="series"></apexchart>
  </div>
</template> 

<script>
import VueApexCharts from "vue3-apexcharts";
import { useViewSelector } from "../../stores/viewSelector";
import { useUserStore } from '../../stores/user';
import { storeToRefs } from 'pinia';
export default{
  name: 'heatMapChart',
  components:{
    apexchart: VueApexCharts,
  },
  setup() {
    const viewSelector = useViewSelector();
    const {currentView} = storeToRefs(viewSelector);
    
    const userStore = useUserStore();
    const {user_id, comp_id, isSessionExpired} = storeToRefs(userStore);
    const { trigSessVerify } = userStore;

    return {
      user_id,
      comp_id,
      currentView,      
      isSessionExpired,
      trigSessVerify,
    }
  },
  data(){
    return {
      absData:[],
      title: {
        text: 'Heatmap'
      },
      noData:{
        text:'Loading...',
      },
      series:[{
            data: [],
            name: '',
          }, 
      ],
    }
  },  
  watch: {
    translation(t) {
      this.options = {
       ...this.options,
       ...{
          title: {
            text: t.title,
          },
        }
      }
    }
  },
  computed:{
    translation: function() {
      return ({
        title: this.$i18n.t("graphs.heatSeg.title"),
      })
    },
    options: function(){
      return {
        chart: {
          type:'heatmap',
          id: 'vueHMSegChart',
          width:'100%',
          height:'100%',
        },
        xaxis: {
          type:'categories',
          categories:[]
        },
        colors:['#016833',],
        enableShades:false,
        dataLabels:{
          enabled:true,
          background:{
            enabled:true,
            padding:10,
          },
          style:{
            fontSize:'9px',
          },
          formatter:function(val){
            return val + '%'
          },
        },
        title: {
          text: this.translation.title,
        },
        tooltip:{
          enabled:true,
          y:{
            formatter: (value, obj) =>{
              return this.absData[obj.seriesIndex][obj.dataPointIndex]
            }
          },
        },
      } 
    }
  },

  mounted() {
    let url = process.env.VUE_APP_URL + '/segHeatmap';
    let nB = {u_id:this.user_id ,c_id:this.comp_id }
    fetch(url, {
      method:'POST',
      body:JSON.stringify(nB),
      headers: new Headers({
        "content-type":"application/json"
      })
    })
    .then((res) => res.json())
    .then((content) => {
      console.table(content);
      if(content.invalidSession){
        this.currentView = 'Login';
      }else{
        this.absData = content.absData
        this.options = {
        ...this.options,
        ...{
            xaxis:{
              categories: content.categories
            },
          }
        }
        //Resolver o bug da responsividade (heatmap)
        content.series.forEach((el, i) => {
          this.series[i] = el
        });
      }
    })
    .catch((err) => {console.log('Erro, '+err)}); 
  },
}
</script>

<style>
.heatmapWrap{
  width: 100%;
  height: 100%;
  position: relative;
  padding: 0px;
  margin: 0px;
}
</style>