<template>
  <apexchart type="bubble" :options="options" :series="series" v-show="renderChart"></apexchart>
</template> 

<script>
import VueApexCharts from "vue3-apexcharts";
import { useViewSelector } from "../../stores/viewSelector";
import { useUserStore } from '../../stores/user';
import { storeToRefs } from 'pinia';
export default{
  name: 'bubbleAvgTicketChart',
  components:{
    apexchart: VueApexCharts,
    },
  setup() {
    const viewSelector = useViewSelector();
    const {currentView} = storeToRefs(viewSelector);
    
    const userStore = useUserStore();
    const {user_id, comp_id, isSessionExpired} = storeToRefs(userStore);
    const { trigSessVerify } = userStore;

    return {
      user_id,
      comp_id,
      currentView,      
      isSessionExpired,
      trigSessVerify,
    }
  },
  data(){
    return{
      renderChart:false,
      xmax:30000,
      xmin:-100,
      absData:[],
      zData:[],
      series: [{
            name: "Cliente",
            data: {}
          }],
      
    }  
  },
  mounted() {

    let url = process.env.VUE_APP_URL + '/bubble';
    let nB = {u_id:this.user_id ,c_id:this.comp_id }
    fetch(url, {
      method:'POST',
      body:JSON.stringify(nB),
      headers: new Headers({
        "content-type":"application/json"
      })
    })
    .then((res) => res.json())
    .then((content) => {
      console.table(content)
      if(content.invalidSession){
        this.currentView = 'Login';
      }else if(!content.shallRender){
        console.log('Problemas calculando o Bubble, gráfico vazio')
      }else {
        this.renderChart = content.shallRender;
        this.series[0].data = content.analysis.data;
        this.absData = content.analysis.lista_ids;
        this.zData = content.analysis.feature;  
      } 
    })
    .catch((err) => {console.log('Erro, '+err)}); 
    
  },
  watch: {
    translation(t) {
      this.options = {
       ...this.options,
       ...{
          title: {
            text: t.title,
          },
        }
      }
    }
  },
  computed:{
    translation: function() {
      return ({
        title: this.$i18n.t("graphs.bubble.title"),
        tooltip: [
          this.$i18n.t("graphs.bubble.tooltip[0]"), 
          this.$i18n.t("graphs.bubble.tooltip[1]"), 
          this.$i18n.t("graphs.bubble.tooltip[2]"), 
          this.$i18n.t("graphs.bubble.tooltip[3]")]
      })
    },
    options: function() {
      return {
        chart: {
            type: 'bubble',
            width:'100%',
        },
        title: {
          text: this.translation.title,
        },
        responsive: [
          {
            breakpoint: 640,
            options: {
              chart: {
                toolbar: {
                  tools: {
                    zoom: false,
                    zoomin: false,
                    zoomout: false,
                    pan: false,
                    reset: false,
                  }
                },
              },
            }
          }
        ],
        plotOptions:{
          bubble:{
            minBubbleRadius:1,
            maxBubbleRadius:30,
          }
        },
        dataLabels: {
            enabled: false
        },
        fill: {
            opacity: 0.8
        },
        yaxis:{
          logarithmic:true,
          max: function(max){console.log('y - max: '+max);return(1.5*max);},
        },
        xaxis:{
          type:'numeric',
          tickAmout:'dataPoints',
          max: this.xmax,
          min: this.xmin,
        },
        tooltip:{
          enabled:true,
          custom: ({ series, seriesIndex, dataPointIndex}) =>{
            return (
              `
                <div class="arrow_box">
                  <p>${this.translation.tooltip[0]}: <strong>${this.series[0].data[dataPointIndex][0]}</strong></p>
                  <p>${this.translation.tooltip[1]}: <strong>${series[seriesIndex][dataPointIndex]}</strong></p>
                  <p>${this.translation.tooltip[2]}: <strong>${this.absData[dataPointIndex]}</strong></p>
                  <p>${this.translation.tooltip[3]}: <strong>${this.zData[dataPointIndex]}</strong></p>
                </div>
              `
              /*
              '<div class="arrow_box">' +
              "<span> Valor Total:" + 
              this.series[0].data[dataPointIndex][0]+
              "<br> <span> Média por Loja:" + 
              series[seriesIndex][dataPointIndex]+
              "<br> Cliente: " +
              this.absData[dataPointIndex] + 
              '<br> Nro de Lojas: ' +
              this.zData[dataPointIndex] +
              "</span>" +  
              
              "</div>"
              */
            );
          }
        },
      }
    }
  },
}
</script>


<style>
  * {
  font-family: 'Poppins', sans-serif;
}

#chart {
  max-width: 760px;
  margin: 35px auto;
  opacity: 0.9;
}

.arrow_box {
  position: relative;
  background: #fff;
  border: 1px solid #dcdcdc5c;
  border-radius: 4px;
  padding: 8px;
  font-size: 12px;
}

.arrow_box > p::before {
  content: '\2022';
  color: var(--secundary-3);
  margin-right: 4px;
  display: inline-block;
  font-size: 18px;
}

.arrow_box:after, .arrow_box:before {
  right: 100%;
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

#chart .apexcharts-tooltip {
  color: #fff;
  transform: translateX(10px) translateY(10px);
  overflow: visible !important;
  white-space: normal !important;
}

#chart .apexcharts-tooltip span {
  padding: 5px 10px;
  display: inline-block;
}
</style>

