<template>
  <h1>Scatter Chart</h1>

  <div class="chart">
    <apexchart type="line" height="350" :options="chartOptions" :series="series"></apexchart>
  </div>
</template>

<script>

import VueApexCharts from 'vue3-apexcharts'
import { useViewSelector } from "../../stores/viewSelector";
import { useUserStore } from '../../stores/user';
import { storeToRefs } from 'pinia';

export default{
  name: 'ScatterPlotPrev',
  components:{
    apexchart: VueApexCharts,
  },
  setup(){
    const viewSelector = useViewSelector();
    const {currentView} = storeToRefs(viewSelector);
    
    const userStore = useUserStore();
    const {analysis, isSessionExpired} = storeToRefs(userStore);
    const { trigSessVerify } = userStore;

    return {
      analysis,
      currentView,      
      isSessionExpired,
      trigSessVerify,
    }
  },
  data() {
      return {
        series: [{
          name: '',
          type: 'scatter',
          data: []
        }, {
          name: '',
          type: 'line',
          data: []
        }],
        chartOptions: {
          chart: {
            type: 'line',
            width:'100%',
            id:'scatterPlotReg'
          },
          fill: {
            type:'solid',
          },
          markers: {
            size: [4, 0]
          },
          tooltip: {
            shared: false,
            intersect: true,
          },
          legend: {
            show: false
          },
          xaxis: {
            type: 'numeric',
          }
        },
      }
    },
    mounted(){
      let url = process.env.VUE_APP_URL + '/AIResultReg';
      let nB = {'a_id':this.analysis}
      fetch(url , {
        method:'POST',
        body:JSON.stringify(nB),
        headers: new Headers({
          "content-type":"application/json"
        })
      })
      .then((res) => res.json())
      .then((content)=>{
        console.log(content)
        if(content.invalidSession){
          this.isSessionExpired = true;
        } else if (content.error || content.noRender) {
          return
        } else{
          console.log(content.scatter)
          console.log(content.line)
          this.series[0].data = content.scatter.data;
          this.series[0].name = content.scatter.name;
          this.series[1].data = content.line.data;
          this.series[1].name = content.line.name;
          
        }
      })
      .catch((err) => console.log('Error, ' + err))
    }

  }
</script>

<style scoped>

  .chart{
    margin: 0 auto;
    max-width: 1000px;
  }
</style>