<template>
  <select v-model="currentLang" id="changelang">
    <option :value="lang[0]" v-for="lang in languages" :key="lang[0]">{{lang[1]}}</option>
  </select>
</template>

<script>
import { useUserStore } from '../../stores/user';
import { storeToRefs } from 'pinia';

export default {
  setup() {
    const userStore = useUserStore();
    const {isSessionExpired} = storeToRefs(userStore);
    const { trigSessVerify } = userStore;
    return {
      isSessionExpired,
      trigSessVerify,
    }
  },
  name: "ChooseLang",
  data() {
    return {
      currentLang: "",
    }
  },
  computed: {
    // Alterar idioma das opções, de acordo com o idioma atual
    languages: function() {
      return this.currentLang === "pt"
       ? [["en", "Inglês"], ["pt", "Português"],]
       : [["en", "English"], ["pt", "Portuguese"],]
    }
  },
  watch: {
    // Monitorar se o idioma é alterado pelo dropdown:
    currentLang(val) {
      // Setar o valor do novo idioma escolhido na raiz do i18n:
      this.$root.$i18n.locale = val
      // Guardar no banco o idioma escolhido para logins futuros:
      let url = process.env.VUE_APP_URL + '/setPrefLang';
      console.log('val: '+val);
      let data = {val};
      console.log(JSON.stringify(data));
        fetch(url, {
          method:'POST',
          body:JSON.stringify(data),
          headers: new Headers({
            "content-type":"application/json"
          }),
        })
        .then((res) => res.json())
        .then((content) => {
          if(content.langUpdated){
            console.log(JSON.stringify(content))
          }
        })
      // localStorage.setItem('airpoonLang', val)
    }
  },
  mounted() {
    // Checar se já existe um idioma salvo no banco para este usuário:
    let url = process.env.VUE_APP_URL + '/prefLang';
    fetch(url, {
      method:'GET',
    })
    .then((res) => res.json())
    .then((content) => {
      console.log(content.lang)
      console.log(JSON.stringify(content.lang))
      const storedLang = content.lang;
      // Se existe idioma salvo, use-o:
      if (storedLang) {
        console.log('inside if storedLang'+storedLang)
        this.currentLang = storedLang;
        this.$root.$i18n.locale = this.currentLang;
      // Senão, pegue o idioma do navegador do usuário, use-o e guarde no banco para futuros logins:
      } else {
        const browserLang = navigator.language || navigator.browserLanguage;
        this.currentLang = browserLang;
        this.$root.$i18n.locale = this.currentLang;
        let val = browserLang.slice(0,2);
        let data = {val};
        let url = process.env.VUE_APP_URL + '/setPrefLang';
        fetch(url, {
          method:'POST',
          body:JSON.stringify(data),
          headers: new Headers({
            "content-type":"application/json"
          }),
        })
        .then((res) => res.json())
        .then((content) => {
          console.log(JSON.stringify(content))
        })
        // localStorage.setItem('airpoonLang', browserLang)
      }
    })
  }
}
</script>

<style scoped>
  select {
    font-size: 14px;
    padding: 0 10px;
    border-radius: 8px;
    position: relative;
    cursor: pointer;
    border: 1px solid rgb(197, 190, 190);
    height: 40px;
    outline: 0;
    font-weight: normal;
    -moz-appearance:none;
    -webkit-appearance: none; 
    appearance: none;
    background: url(../../assets/img/table/arrowDown.svg) no-repeat center / contain;
    background-size: 9px;
    background-position: right 8px center;
    width: 100%;
    max-width: 240px;
  }
</style>