<template>
  <div class="loading__box">
    <!-- Loading, estamos processando seu envio: -->
    <div class="loading__content" v-if="!isDone">
      <div class="loading__logo">
        <img src="../../assets/img/brand/aihuk_logo.svg" alt="Logo Airpoon">
      </div>
      <h2 class="loading__titulo">{{$t('loading.titulo[0]')}}</h2>
      <p class="loading__descricao">{{$t('loading.descricao')}}</p>
    </div>
    <!-- Carregamento concluído, com botão que redireciona para a Analytics (este modo é usado apenas na page Table.vue) -->
    <div class="concluido__content" v-if="isDone">
      <div class="concluido__logo">
        <img src="../../assets/img/concluido.svg" alt="Concluído">
      </div>
      <h2 class="concluido__titulo">{{$t('loading.titulo[1]')}}</h2>
      <button class="concluido__botao" @click="redirectTo('Analytics')">
        {{$t('loading.botao')}}
      </button>
    </div>
  </div>
</template>

<script>
import { usePageSelector } from '../../stores/pageSelector'
export default {
  name: "Loading",
  props: {
    isDone: Boolean,
  },
  setup() {
    const pageSelector = usePageSelector();
    const { redirectTo } = pageSelector;

    return {
      redirectTo
    }
  },
}
</script>

<style scoped>
  .loading__box {
    border-radius: 16px;
    background-color: #FFF;
    max-width: 560px;
    height: 320px;
    margin: auto;
    padding: 24px;
    text-align: center;
    display: grid;
    place-content: center;
    box-shadow: 0px 6px 8px -6px rgba(24, 39, 75, 0.12), 0px 8px 16px -6px rgba(24, 39, 75, 0.08);
    animation: surgir 1s;
  }

  @keyframes surgir {
    from {
      opacity: 0.5;
      transform: translateY(32px);
    }

    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .loading__content {
    max-width: 350px;
  }

  .loading__logo,
  .concluido__logo {
    max-width: 100px;
    margin: auto;
    margin-bottom: 24px;
    animation: pulsante .6s ease-in-out infinite alternate;
  }

  @keyframes pulsante {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(1.2);
    }
}

  .loading__logo > img,
  .concluido__logo > img {
    width: 100%;
  }

  .loading__titulo,
  .concluido__titulo {
    color: var(--primary);
    font-size: 28px;
    position: relative;
  }

  .loading__descricao {
    color: #696969;
    margin-top: 8px;
    font-size: 16px;
    line-height: 20px;
  }

  .concluido__logo {
    animation: chegada .4s;
  }

  @keyframes chegada {
    from {
      transform: rotate(45deg);
    }
    to {
      transform: rotate(0deg);
    }
  }

  .concluido__content {
    max-width: 360px;
  }

  .concluido__botao {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--primary);
    border: 2px solid var(--primary);
    font-family: inherit;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    height: 40px;
    width: 100%;
    max-width: 220px;
    border-radius: 8px;
    cursor: pointer;
    transition: 0.2s ease;
    margin: 24px auto 0 auto;
  }

  .concluido__botao:hover {
    background-color: var(--secundary-3);
    border-color: var(--secundary-3);
  }

  @media screen and (max-width: 480px) {
    .loading__titulo,
    .concluido__titulo {
      font-size: 24px;
    }

    .loading__descricao {
      font-size: 14px;
    }
  }
</style>