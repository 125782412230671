<template>
  <article class="results__container">
    <h3 class="results__time">
      {{predictionInfos.timestampStart}}: <span>{{predictionInfos.rowsPred > 1 ? predictionInfos.rowsPred + rowsTranslated : predictionInfos.rowsPred + rowTranslated}} {{predictTranslated}} {{predictionInfos.execTime}}s</span>
    </h3>
    <div class="table__container" v-if="tableContent.rowsData.length">
      <div class="table__wrapper">
        <table>
          <tr>
            <th v-for="name in tableContent.columnsName" :key="name" :title="name">
              {{name}}
            </th>
          </tr>
          <tr v-for="row in tableContent.rowsData" :key="row">
            <td v-for="data in row" :key="data" :title="data">
              {{data}}
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div class="table__buttons" v-if="tableContent.rowsData.length">
      <button @click="handleNewPrediction" class="table__button">
        <img src="../../../assets/img/ai/icon-plus.svg" alt="Icon plus">
        {{$t('resultsPredictAPI.btns[0]')}}
      </button>
      <!-- <button @click="handleCopy" class="table__button">
        <img src="../../../assets/img/ai/icon-copy.svg" alt="Icon copy">
        {{$t('resultsPredictAPI.btns[1]')}}
      </button> -->
      <button @click="handleDownload" class="table__button">
        <img src="../../../assets/img/ai/icon-download.svg" alt="Icon download">
        {{$t('resultsPredictAPI.btns[2]')}}
      </button>
      <!-- <button class="table__button" :href="downloadLink">
        <img src="../../../assets/img/ai/icon-download.svg" alt="Icon download">
        Download CSV
      </button> -->
    </div>
  </article>
</template>

<script>
import { storeToRefs } from 'pinia';
import { useAiStore } from '../../../stores/ai';
import { usePageSelector } from '../../../stores/pageSelector';
import { useUserStore } from '../../../stores/user';
export default {
  name: "TabularResults",
  setup() {
    const aiStore = useAiStore();
    const { showDataEntry, showEntryMenu, tableContentSent, resultPredictAPI, prevAnalysisList, fileUploadData, resultFileName, downloadLink } = storeToRefs(aiStore);

    const pageSelector = usePageSelector();
    const { currentView } = storeToRefs(pageSelector);



    const userStore = useUserStore();
    const { analysis, transf_id, isSessionExpired, } = storeToRefs(userStore);
    const {trigSessVerify, } = userStore;
    return {
      showDataEntry,
      showEntryMenu,
      tableContentSent,
      resultPredictAPI,
      currentView,
      analysis,
      transf_id,
      prevAnalysisList,
      fileUploadData,
      resultFileName,
      downloadLink,
      isSessionExpired,
      trigSessVerify,
    };
  },
  data() {
    return {
      tableContent: {
        columnsName: [],
        rowsData: [],
      },
      predictionInfos: {
        // Esta porra aqui em baixo está MUITO específica, PQ?
        timestampStart: "7/6/2022, 2:38:41 PM",
        execTime: "0",
        rowsPred: "0",
      },
      // Variaáveis auxiliares para tradução no tempo que levou pra montar os results
      rowsTranslated: this.$i18n.t("resultsPredictAPI.labels[0]"),
      rowTranslated: this.$i18n.t("resultsPredictAPI.labels[1]"),
      predictTranslated: this.$i18n.t("resultsPredictAPI.labels[2]"),
    }
  },
  methods: {
    handleNewPrediction() {
      this.showEntryMenu = true;
      this.showDataEntry = true;
    },
    handleCopy() {
      // const { columnsName, rowsData } = this.tableContent;
      // const rows = [columnsName, ...rowsData];

      // const excelData = rows.map((row) => row.join("\t")).join("\n");

      // navigator.clipboard.writeText(excelData);
      // alert("Data copied successfully!");
    },
    handleDownload() {
      window.open(this.downloadLink)
    }
      
    //   // const { columnsName, rowsData } = this.tableContent;
    //   // const rows = [columnsName, ...rowsData];

    //   // let today = new Date().toISOString().slice(0, 10).replaceAll("-","")
    //   // const currentAnalysis = this.prevAnalysisList.find(el => el.id == this.analysis);

    //   // const filename = today + "_airpoon_prediction_" + currentAnalysis.infos.dataset.split('.')[0]

    //   // exportToCsv(filename, rows);

    //   // function exportToCsv(filename, rows) {
    //   //   var processRow = function (row) {
    //   //     var finalVal = "";
    //   //     for (var j = 0; j < row.length; j++) {
    //   //       var innerValue = row[j] && row[j].toString();
    //   //       if (row[j] instanceof Date) {
    //   //         innerValue = row[j].toLocaleString();
    //   //       }
    //   //       var result = innerValue && innerValue.replace(/"/g, '""');
    //   //       if ((result && result.search(/("|,|\n)/g)) >= 0) result = '"' + result + '"';
    //   //       if (j > 0) finalVal += ",";
    //   //       finalVal += result;
    //   //     }
    //   //     return finalVal + "\n";
    //   //   };

    //   //   var csvFile = "";
    //   //   for (var i = 0; i < rows.length; i++) {
    //   //     csvFile += processRow(rows[i]);
    //   //   }

    //     var blob = new Blob([csvFile], { type: "text/csv;charset=utf-8;" });
    //     if (navigator.msSaveBlob) {
    //       // IE 10+
    //       navigator.msSaveBlob(blob, filename);
    //     } else {
    //       var link = document.createElement("a");
    //       if (link.download !== undefined) {
    //         // feature detection
    //         // Browsers that support HTML5 download attribute
    //         var url = URL.createObjectURL(blob);
    //         link.setAttribute("  ", url);
    //         link.setAttribute("download", filename);
    //         link.style.visibility = "hidden";
    //         document.body.appendChild(link);
    //         link.click();
    //         document.body.removeChild(link);
    //       }
    //     }
    //   // }
    // },
  },
  async mounted() {
    // Resultado da PredictAPI salvo na store:
    const result = this.resultPredictAPI
    console.log('tableContentSent: ', this.tableContentSent)
    console.log('resultPredictAPI: ', this.resultPredictAPI)
    let filename = this.fileUploadData.fileName || this.resultFileName;
    let resultFile = filename.split(".");
    resultFile = resultFile[0] + ".xlsx"
    console.log('XLSX: '+ resultFile )

    // Pegar informações (rows predicted, timestamp, execution time) sobre o predict:
    let url = process.env.VUE_APP_URL + '/getPredictionInfo';
    let nB = {'a_id':this.analysis, 't_id':this.transf_id, 'fileName': resultFile }
    this.trigSessVerify();
    let response = await fetch(url, {
      method:'POST',
      body:JSON.stringify(nB),
      headers: new Headers({
        "content-type":"application/json"
      })
    })
    const content = await response.json();
    if (content.invalidSession) {
      this.isSessionExpired = true;
    } else {
      console.log("getPredictionInfo: ", content);


      // this.downloadLink = content.resultsLink;
      this.predictionInfos = content;

      let predictonStartTS = new Date(content.timestampStart);

      // Formatando data:
      let formatted = {
        day: predictonStartTS.getDate() < 10 ? "0" + predictonStartTS.getDate() : predictonStartTS.getDate(),
        month: predictonStartTS.getMonth() < 10 ? "0" + predictonStartTS.getMonth() : predictonStartTS.getMonth(),
        year: predictonStartTS.getFullYear(),
        hours: predictonStartTS.getHours() < 10 ? "0" + predictonStartTS.getHours() : predictonStartTS.getHours(),
        minutes: predictonStartTS.getMinutes() < 10 ? "0" + predictonStartTS.getMinutes() : predictonStartTS.getMinutes(),
      }

      this.predictionInfos.timestampStart = `${formatted.year}-${formatted.month}-${formatted.day} ${formatted.hours}:${formatted.minutes}`;
    }

    // Adicionado a coluna 'Result' na tabela de dados enviados para o PredictAPI:
    this.tableContent.columnsName = [...this.tableContentSent.columnsName.map(column => column[0]), 'Result']
    this.tableContent.rowsData = this.tableContentSent.rowsData.map((row, i) => [...row, result[i]])
  }
}
</script>

<style scoped>
.results__container {
  padding-bottom: 40px;
}

.table__container {
  margin: auto;
  background-color: #FFF;
  padding: 24px;
  border-radius: 16px;
  width: max-content;
  margin-bottom: 32px;
  animation: surgir 1s;
}
@keyframes surgir {
  from {
    opacity: 0.5;
    transform: translateY(32px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.results__time {
  margin: auto;
  margin-bottom: 24px;
  text-align: left;
  max-width: 70vw;
  font-weight: 600;
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 4px;
  transform: translateX(-24px);
}

.results__time::before {
  content: '';
  display: inline-block;
  background: url(../../../assets/img/ai/icon-time.svg) no-repeat center / contain;
  width: 20px;
  height: 20px;
}

.results__time > span {
  font-weight: 400;
}

.table__wrapper {
  max-width: 70vw;
  max-height: 50vh;
  overflow: scroll;
  display: flex;
  flex-direction: row-reverse;
}

.table__buttons {
  display: flex;
  justify-content: center;
  gap: 24px;
}

.table__button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  border: 0;
  background-color: var(--primary);
  color: #FFF;
  font-size: 14px;
  font-weight: 500;
  height: 40px;
  width: 180px;
  border-radius: 8px;
  cursor: pointer;
  transition: .4s ease;
}

.table__button > img {
  filter: brightness(10);
  max-width: 20px;
}

.table__button:hover {
  background-color: var(--secundary-3);
}

table {
  border-collapse: collapse;
  font-size: 14px;
}

table tr > th:last-of-type,
table tr > td:last-of-type {
  color: var(--primary);
  font-weight: 600;
}

tr {
  display: block;
}

tr:nth-child(even) {
  background-color: rgba(228, 235, 242, 0.4);
}

td input, th input {
  border: 0;
  background-color: transparent;
  outline: 0;
  width: 80%;
  text-overflow: ellipsis;
}

th input {
  font-weight: bold;
  padding: 4px;
}

td, th {
  min-width: 160px;
  max-width: 160px;
  text-align: left;
  overflow: hidden;
  padding: 16px 8px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

::-webkit-scrollbar {
  height: 8px;
  border-radius: 8px;
  width: 8px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #bdc5cc;
  border-radius: 8px;
  transition: 0.4s;
}

::-webkit-scrollbar-thumb:hover {
  background: #95b0c7;
}

</style>