const messages = {
  en: {
    login: {
      titulo: "AI for all",
      descricao: "Solving problems with <strong>real intelligence</strong>",
      form: [
        "Welcome back!",
        "Login to your account",
        "Password",
        "Remember me",
        "Forgot password?",
        "Login now",
        "Not registered? Sign up here"
      ],
      email: "Email",
      error: "Failed login attempt, please check e-mail and password",
    },
    recoveryPassword: {
      title: "Password Recovery",
      description: "Insert your account login e-mail.<br>If it is correct, you will receive a recovery code in your mailbox in a few seconds",
      link: "Back to login",
      button: "Send e-mail"
    },
    enterCode: {
      title: "Insert your verification code",
      description: "Insert the code sent to ",
      link: "Didn't receive your code?<br>Please check the provided e-mail address",
      button: "Verify",
      btnBack: "Back",
    },
    confirmNewPassoword: {
      title: "Reset password",
      labels: ["Insert your new password" , "Confirm your new password"],
      warning: "Warning: the passwords don't match",
      button: "Confirm changes"
    },
    confirmNewAccount: {
      title: "Confirm your account",
      description: "Insert the code from",
      link: "Resend Code",
      button: "Confirm Account",
    },
    company: {
      welcome: "Welcome ",
      instruction: "Choose your account:",
      information: "Show account info",
      nocards: "No accounts found.",
      noinfocards: "Unavailable account information.",
    },
    dashboard: {
      hello: "Hello, ",
      logged: "You are logged into conta ",
    },
    home: {
      banner: {
        titulo: "Welcome to Airpoon",
        texto: "AI for All",
        botao: "Start now",
      },
      quick: {
        titulo: "Quick access",
        cards: [
          {
            titulo: "New analysis",
            botoes: ["Churn", "Health", "All analysis"], //o terceiro colocar um link pra All types
          },
          {
            titulo: "Existing analysis",
            botoes: ["Create a model"], //listagem com duas últimas análises e um list all
          },
          {
            titulo: "Support", //learn? econder por enquanto
            botoes: ["Guides", "Contact", "Data genius"], //learn? econder por enquanto
          },
        ],
      },
    },
    upload: {
      titulo: "File upload for Analytics",
      drop: ["Select a file or drag and drop here", "Select file"],
    },
    loading: {
      titulo: ["Processing data...", "Processing completed successfully!"],
      descricao:
        "Your submission is being processed. Please wait a few minutes.",
      botao: "Show results",
    },
    table: {
      titulo: "Data choice",
      reset: "Complete update?",
      dropdown: ["Data type", "Attribute type" , "ICP type"],
      botao: "Confirm",
      help:{
        titleDataChoice: "Data input",
        labelDataChoice: "<strong>Analytics inputs:</strong>\nTo upload data to the table, please enter data with at least the following attributes: ID, Data de início, Data de Churn, Valor.\nThese, as well as optional attributes such as monthly_date, may not be selected more than once on the dropdowns and doing so may incur in an error.\nFor further understanding of the functioning please check the knowledge base or contact support.",
        titleClearData: "How reseting works?",
        labelClearData: "There are two types of data loading: incremental updates and complete. In an incremental update, only new and modified lines are update. On a complete update, all the previously loaded data is wiped",
      }
    },
    modalError: {
      title: "Oops, something wrong happened!",
      errors: [
        "There cannot be two or more columns with the same required attributes.",
        "Please enter the column for the required attribute: ",
        "Please enter the columns for the required attributes: ",
        "You must select a valid target column.",
      ],
      buttons: ["Try again" , "Back to home"]
    },
    modalEdit: {
      button: "Save changes",
      titles: ["Edit analysis" ],
      fields: ["Analysis name" ],
    },
    modalHelper: {
      button: "I got it!"
    },
    graphs: {
      bar: {
        series: ["Old Revenue", "New Revenue"],
      },
      barStackNeg: {
        series: ["MRR", "New Costumers", "Churn"],
      },
      mix: {
        title: "Survival Analysis",
        serie: "Survival Chance",
      },
      barACtms: {
        title: "Active customers",
        serie: "Active customers",
      },
      heat: {
        title: "Cohort - Client Start Date",
        tooltip: ["Month:", "active customers", "of initial"],
      },
      heatSeg: {
        title: "Cohort - Client Category",
      },
      bubble: {
        title: "Average Purchase Value",
        tooltip: [
          "Total value",
          "Average per store",
          "Client",
          "No. of stores",
        ],
      },
    },
    UserDropdown: {
      companies: "Companies",
      logout: "Logout",
      settings: "Settings",
      subscription: "Subscription",
    },
    settings: {
      title: "Settings",
      labels: ["First name","Last name", "Email", "Phone", "Language", "Password", "Current password", "New password","Company Name", "Company Location" , "Use Type"],
      changePass: ["Change password", "Cancel" , "Your password must have: ", "at least 8 characters" , "at least one letter (a-z, A-Z)" , "at least one number (0-9)"],
      errorMsg: ['Incorrect password! Try again.' , 'Password changed Successfully'],
      button: "Save changes",
    },
    notFound: {
      content: ["Error 404: ", "Page not found", "Back to home"],
    },
    modalSession: {
      title: "Oops, your session has expired!",
      message: "Enter your password to continue or go back to login page.",
      user: "User",
      label: "Password",
      placeholder: "Enter your password",
      buttons: ["Continue", "Back to login page"],
      error: 'Wrong password',
    },
    customerPortal:{
      title: "Subscription management",
      labels: ["Next invoice" , "Cancel plan"],
      popups: [
        {
          label: "You still have 10 invites, use them and earn rewards",
          button: "Invite friends",
        },
        {
          label: "Earn credits and bonus by completing missions",
          button: "Start now",
        }
      ],
      errorPopUps: [
        {
          title: "Payment error - account suspended",
          text: "Please, check your payment method here",
        },
        {
          title: "Warning - payment method is about to expire",
          text: "Please, update your payment method here",
        }
      ],
      currentPlan:[
        {
          title: "Current plan",
          labelMonth: "per month",
          labels: ["Training cells per month:" , "Prediction cells per month:" , "Upgrade plan"]
        }
      ],
      usageHistory: [
        {
          title: "Usage history",
          subtitles: ["This month" , "Last month"],
          labels: ["Included training cells used:" , "Included prediction cells used:"],
          link: "Show previous months",
        }
      ],
    },
    chooseAnalysis:{
      titles: ["AI Models", "New Model"],
      labels: ["Select a previous AI analysis or create a new one" , "Choose analysis type:" , "Enter analysis name", "Required"],
      buttons: ["Create new analysis", "Start" , "Next" , "Previous"],
      error: "No previous analysis found.",
      analysisCards: {
        labels: ["Dataset" , "Transformations", "Created at", "Creator","Status"],
        buttons: ["Predict" , "Status"],
      },
      analysisAvailables: ["Predict customer retention based on past performance","Automated persona discovery","Find out who are your best clients","Predict sales conversion potential","Predict test viability on broader cohorts and reduce experiment costs and risk","Predict purchase susceptibility","Predict remarketing campaigns succes","Predict employee retention over a given timeframe","Prediction to health cases", "Standard classification modeling", "Standard regression modeling","Standard clustering modeling"],
      myAnalysisInfos: ["Dataset" , "Transformations" , "Created At" , "Creator" , "Status"],
    },
    predictAPI:{
      title: "Predict API",
      labels: ["API address" , "API key" , "Example request" , "Fields"],
      tableLabels: ["NAME" , "TYPE"],
      help: {
        api_title: {
          title: "What is Predict API?",
          msg: "You can generate a predict request through our API address. To do so you may use tools such as Postman, curl, a web browser request or any general purpose programming language with html POST METHOD capabilities.\nPlease follow the format as presented",
        },
        api_address:{
          title: "What is API address?",
          msg: "The request address to call the API",
        },
        api_key:{
          title: "What is API key?",
          msg: "The key used to call the API",
        },
        example_request:{
          title: "How do I format my request",
          msg: "You must format the header and the body element of the request upon sending it to our AI API server. Specific instructions are available in our documentation or contacting our support.",
        },
        fields:{
          title: "Field types",
          msg: "Field types and formatting for the json request",
        }
      }
    },
    modelResults:{
      title: 'Model results',
      infoCards: ['Dataset' , 'Transformations','Created at','Created by','Status'],
    },
    metrics: {
      btns: ["Predict API" , "One click predict", "New prediction", "Explainer"],
      title: ["Metrics"],
      dataGenius: ["Do you want better results?" , "Improve your analysis with our guides or talk to our data genius" , "Data genius"],
      dataGeniusModal: ["Data genius" , "If you want better results for your analysis, please send an e-mail to ", "Got it!"]
    },
    newPrediction:{
      container_title: "New Prediction",
      labels_title_container: ["- Data entry" , "- Results"],
      modalHelp: {
        title: "What are the differences between data insertion types?",
        slides:[
          {
            title: "File upload",
            msg: "Select a file that contains the required columns to update. \nSubscription tier input limit.",
          },
          {
            title: "Paste data",
            msg: "Copy data from a spreadsheet app (such as Excel, Google Sheets) and paste here. \nPlease include the header line. \n10 000 lines limit. ",
          },
          {
            title: "Insert manually",
            msg: "Manual data insertion.\n10 000 lines limit. ",
          },
        ],
        btn: "Got it!"
      },
      entryUpload:{
        title: "File upload",
        button: "Upload"
      },
      entryPaste:{
        title: ["Paste from spreadsheet" , "Table Cluster"],
        btnCopy: "Paste data",
        btnConfirm: "Predict",
      },
      entryManually:{
        title: "Insert data manually",
        btnConfirm: "Predict",
        modalError: {
          msg: "Please insert some data!"
        },
      },
    },
    dataEntry:{
      title: "How would you like to enter data?",
      options: ["File upload" , "Paste from spreadsheet" , "Insert data manually"],
    },
    predictNow:{
      title: "Predict now",
      tableHeads: ["Field" , "Data type" , "Value"],
      btns: ["Clear" , "Predict Now"],
      modalHelp: {
        title: "One click predict",
        msg: "To make a prediction enter the required data at each field, respecting the data types and limits present at the original dataset.\nCategorical and string type data must match the original input data and is case sensitive. For example, if an example dataset had categories aa, AA, aA and Aa in a given column, the input in this field must match one of the original categories.\nIf a new input such as bb is to be found, it is recommended that a new model be trained that takes into account the new possibilities to avoid prediction errors.For numeric fields, pay attention to the variable typing.\nInteger fields do not accept decimal values and may incur in an error.In float type fields, the dot (.) is the decimal separator.\nDo not use any other separator in these fields. If a value is entered that is outside the range of the training data, it is also recommended to retrain the model in order to avoid prediction errors. "
      }
    },
    resultsPredictAPI: {
      btns: ["New prediction" , "Copy data" , "Download CSV"],
      labels: [" rows" , " row", "predicted in"] 
    },
    modalContact: {
      title: 'Contact',
      label: 'if you have any doubts or find any errors, please contact us via ',
    },
    explainer: {
      title: "Explainer",
      subtitle: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Sed, porro reiciendis. Quisquam ratione nostrum molestias suscipit.",
      buttons: ["Show better results" , "Hide"],

    }
  },

  pt: {
    login: {
      titulo: "AI para todos",
      descricao: "Resolvendo problemas com <strong>inteligência real</strong>",
      form: [
        "Bem-vindo de volta!",
        "Entre na sua conta",
        "Senha",
        "Lembrar-me",
        "Esqueceu a senha?",
        "Entrar",
        "Não possui registro? Registre-se aqui"
      ],
      email: "E-mail",
      error: "Login incorreto, e-mail ou senha não conferem",
    },
    recoveryPassword: {
      title: "Recuperação de senha",
      description: "Insira o e-mail da sua conta. Se o login estiver correto, você receberá um código de acesso em alguns segundos.",
      link: "Voltar para login",
      button: "Enviar e-mail"
    },
    enterCode: {
      title: "Insira seu código de verificação",
      description: "Insira o código enviado para ",
      link: "Não recebeu seu código?<br>Confira se o e-mail fornecido está correto.",
      button: "Verificar",
      btnBack: "Voltar",
    },
    confirmNewPassoword: {
      title: "Redefinir senha",
      labels: ["Inserir nova senha" , "Confirmar nova senha"],
      warning: "Erro: as senhas são diferentes",
      button: "Confirmar mudanças"
    },
    confirmNewAccount: {
      title: "Confirmar conta",
      description: "Insira o código enviado para",
      link: "Reenviar código",
      button: "Confirmar conta",
    },
    company: {
      welcome: "Bem-vindo(a), ",
      instruction: "Escolha a sua conta:",
      information: "Exibir informações das contas",
      nocards: "Nenhuma conta encontrada.",
      noinfocards: "Informações de contas indisponíveis.",
    },
    dashboard: {
      hello: "Olá, ",
      logged: "Você está logado na conta ",
    },
    home: {
      banner: {
        titulo: "Bem-vindo ao Airpoon",
        texto:"Ai for All",
        botao: "Comece agora",
      },
      quick: {
        titulo: "Acesso rápido",
        cards: [
          {
            titulo: "Nova análise",
            botoes: ["Churn", "Saúde", "Análises disponíveis"], //trocar o último por outras
          },
          {
            titulo: "Análises existente",
            botoes: ["Crie um modelo"]
          },
          {
            titulo: "Suporte", //aprenda a usar ?? sumir por enquanto
            botoes: ["Guia", "Contato", "Data genius"], //aprenda a usar ?? sumir por enquanto
          },
        ],
      },
    },
    upload: {
      titulo: "Upload para Analytics",
      drop: [
        "Selecione um arquivo ou arraste e solte aqui",
        "Selecionar arquivo",
      ],
    },
    loading: {
      titulo: ["Processando dados...", "Processamento concluído com sucesso!"],
      descricao:
        "Estamos processando seu envio! Por favor, aguarde alguns minutos.",
      botao: "Ver resultados",
    },
    table: {
      titulo: "Escolha de dados",
      reset: "Deseja fazer uma substituição integral da base de dados",
      dropdown: ["Tipo de dado", "Tipo de atributo" , "Tipo ICP"],
      botao: "Confirmar",
      help:{
        titleClearData: "Como funciona a substituição integral",
        labelClearData: "Existem dois tipos de entrada de dados possíveis, a integral e a incremental. Na entrada de dados integral todos os dados presentes no banco são apagados. Já na incremental apenas entradas novas ou alterações são inseridas no banco. A entrada incremental só funciona se as colunas da base incemental forem compatíveis com a base original",
        titleDataChoice: "Entrada de dados",
        labelDataChoice: "<strong>Como escolher os dados:</strong>\nOs seguintes atributos DEVÊM ser incluídos na tabela: ID, Data de início, Data de Churn, Valor. Também é possível utiliza o valor data mensal e valores de categorização.\nEstas colunas, com excessão das colunas de categorização, não podem ser selecionadas mais de uma vez no menu.\nPara mais informações cheque a base de conhecimento ou entre em contato com o suporte"
      },
    },
    modalError: {
      title: "Oops, algo deu errado!",
      errors: [
        "Não pode haver duas ou mais colunas com atributos obrigatórios iguais.",
        "Por favor, informe a coluna referente ao atributo obrigatório: ",
        "Por favor, informe as colunas referentes aos atributos obrigatórios: ",
        "Você deve selecionar uma coluna alvo válida.",
      ],
      buttons: ["Tentar novamente" , "Voltar para home"]
    },
    modalEdit: {
      button: "Salvar mudanças",
      titles: ["Editar análise" ],
      fields: ["Nome da análise" ],
    },
    modalHelper: {
      button: "Entendi!"
    },
    graphs: {
      bar: {
        series: ["Receita Antiga", "Nova Receita"],
      },
      barStackNeg: {
        series: ["MRR", "Novos Clientes", "Churn"],
      },
      mix: {
        title: "Análise da Curva de Sobrevivência",
        serie: "Chance de Sobrevivência",
      },
      barACtms: {
        title: "Clientes ativos",
        serie: "Clientes ativos",
      },
      heat: {
        title: "Cohort por Data de Entrada",
        tooltip: ["Mês:", "clientes ativos", "do inicial"],
      },
      heatSeg: {
        title: "Cohort por Segmento",
      },
      bubble: {
        title: "Ticket médio por assinante",
        tooltip: ["Valor total", "Média por loja", "Cliente", "N.° de lojas"],
      },
    },
    UserDropdown: {
      companies: "Empresas",
      logout: "Sair",
      settings: "Configurações",
      subscription: "Assinatura",
    },
    settings: {
      title: "Configurações",
      labels: ["Nome", "Sobrenome", "E-mail", "Telefone", "Idioma", "Senha", "Senha atual", "Nova senha" , "Nome da Empresa", "Local da Empresa", "Tipo de uso"],
      changePass: ["Alterar senha", "Cancelar" , "A senha deve conter: ", "pelo menos 8 caracteres" , "pelo menos uma letra (a-z, A-Z)" , "pelo menos um número (0-9)"],
      errorMsg: ['Senha incorreta! Tente novamente.' , 'Senha alterada com sucesso'],
      button: "Salvar alterações",
    },
    notFound: {
      content: ["Erro 404: ", "Página não encontrada.", "Voltar ao início"],
    },
    modalSession: {
      title: "Oops, sua sessão expirou!",
      message: "Insira sua senha para continuar, ou volte para a página de login.",
      user: "Usuário",
      label: "Senha",
      placeholder: "Insira sua senha",
      buttons: ["Continuar", "Voltar para o login"],
      error: 'Senha incorreta',
    },
    customerPortal:{
      title: "Gerenciar Assinatura",
      labels: ["Próxima fatura" , "Cancelar plano"],
      popups: [
        {
          label: "Você ainda tem 10 convites, use-os e ganhe recompensas",
          button: "Convide amigos",
        },
        {
          label: "Ganhe créditos e bônus ao completar tarefas",
          button: "Comece agora",
        }
      ],
      errorPopUps: [
        {
          title: "Erro no pagamento - conta suspensa",
          text: "Por favor, verifique seu método de pagamento aqui",
        },
        {
          title: "Aviso - seu método de pagamento irá expirar",
          text: "Por favor, atualize seu método de pagamento aqui",
        }
      ],
      currentPlan:[
        {
          title: "Plano atual",
          labelMonth: "por mês",
          labels: ["Total de células de treinamento ao mês:" , "Total de células de previsão ao mês:" , "Trocar plano"]
        }
      ],
      usageHistory: [
        {
          title: "Histórico de uso",
          subtitles: ["Esse mês" , "último mês"],
          labels: ["Células de treinamento usadas:" , "Células de previsão usadas:"],
          link: "Mostrar meses anteriores",
        }
      ],
    },
    chooseAnalysis:{
      titles: ["Modelos de AI", "Novo Modelo"],
      labels: ["Selecionar uma análise antiga ou criar uma nova", "Escolha o tipo de análise" , "Digite o nome da análise", "Necessário"],
      buttons: ["Criar nova análise", "Começar" , "Próxima" , "Anterior"],
      error: "Nenhuma análise encontrada.",
      analysisCards: {
        labels: ["Dataset" , "Transformações", "Criada em", "Criador","Estado"],
        buttons: ["Prever" , "Estado"],
      },
      analysisAvailables: ["Prever a retenção de clientes com base no desempenho passado","Descoberta automatizada de personas","Descubra quem são seus melhores clientes","Preveja o potencial de conversão de vendas","Prever a viabilidade do teste em coortes mais amplas e reduzir os custos e o risco do experimento","Prever a suscetibilidade à compra","Prever o sucesso das campanhas de remarketing","Prever a retenção de funcionários durante um determinado período de tempo","Previsão para casos de saúde", "Modelagem de classificação padrão", "Modelagem de regressão padrão","Modelagem de clustering padrão"],
      myAnalysisInfos: ["Base de dados" , "Transformações" , "Criado em" , "Criador" , "Estado"],
    },
    predictAPI:{
      title: "Predict API",
      labels: ["Endereço da API" , "Chave da API" , "Solicitação de exemplo" , "Campos"],
      tableLabels: ["NOME" , "TIPO"],
      help: {
        api_title: {
          title: "O que é a Predict API?",
          msg: "Você pode fazer requests para a sua AI através da API de requests utilizando apps como o Postman, curl, um navegador web ou uma linguagem de programação com capacidade de fazer requests HTML POST",
        },
        api_address:{
          title: "O que é endereço de API?",
          msg: "Endereço para chamado da API pelo request POST",
        },
        api_key:{
          title: "O que é Chave de API?",
          msg: "Chave a ser inserida no cabeçalho do request post",
        },
        example_request:{
          title: "Solicitação de exemplo",
          msg: "Um chamado de API html pelo método POST tem um cabeçalho e um corpo de mensagem.\nO cabeçalho contém a chave de API e o corpo os itens a sere analisados pela AI",
        },
        fields:{
          title: "Campos",
          msg: "Tipos de campos e formatação destes para o corpo do json",
        }
      }
    },
    modelResults:{
      title: 'Resultados do modelo',
      infoCards: ['Arquivo' , 'Configurações','Criado em','Criador','Status'],
    },
    metrics: {
      btns: ["API de predição" , "One click predict", "Nova predição", "Explainer"],
      title: ["Metrics"],
      dataGenius: ["Você quer resultados melhores?" , "Aprimore suas análises com nossos guias ou fale com nosso data genius " , "Data genius"],
      dataGeniusModal: ["Data genius" , "Quer melhores resultados nas suas análises? Podemos te ajudar. Fale com a gente no ", "Entendido"]
    },
    newPrediction:{
      container_title: "Nova previsão",
      labels_title_container: ["- Insira Dados" , "- Resultados"],
      modalHelp: {
        title: "Quais as diferenças entre os tipos de inserção de dados?",
        slides:[
          {
            title: "Carregar arquivo",
            msg: "Envio de arquivo salvo no computador.\nLimite de dados da assinatura.",
          },
          {
            title: "Colar dados",
            msg: "Selecione e clique aqui para colar diretamente dados da área de transferência a partir de um software de planilhas como Excel ou Google Sheets.\nLimitado a 10 000 linhas",
          },
          {
            title: "Inserir manualmente",
            msg: "Inserção manual.\nLimitado a 10 000 linhas.",
          },
        ],
        btn: "Entendi!"
      },
      entryUpload:{
        title: "Carregar arquivo",
        button: "Upload"
      },
      entryPaste:{
        title: ["Colar dados" , "Tabela"],
        btnCopy: "Colar dados",
        btnConfirm: "Prever",
      },
      entryManually:{
        title: "Inserir dados manualmente",
        btnConfirm: "Prever",
        modalError: {
          msg: "Por favor insira alguma linha!"
        },
      },
    },
    dataEntry:{
      title: "Como gostaria de inserir seus dados?",
      options: ["Carregar arquivo" , "Colar dados" , "Inserir dados manualemnte"],
    },
    predictNow:{
      title: "Prever agora",
      tableHeads: ["Campo" , "Tipo de Dado" , "Valor"],
      btns: ["Limpar" , "Prever agora"],
      modalHelp: {
        title: "Predição em um clique",
        msg: "Para fazer uma predição entre os dados requeridos em cada campo, respeitando os tipos de dados e limites presentes no arquivo de treinamento original.Dados categóricos e strings devem ser iguais aos dados de entrada originais e diferenciam maiúsculas e minúsculas.\nPor exemplo, se os dados originais tinham as categorias aa, AA, aA e Aa em uma dada coluna, a entrada de dados nesse campo tem que ser idêntica a um desses valores.\nSe uma nova entrada como bb deve ser inserida, é recomendado que um novo modelo seja treinado que leve em consideração as novas possibilidades para evitar erros.Para campos numéricos, preste atenção ao tipo das variáveis. Campos do tipo integer não aceitam valores decimais, e fazer tal pode gerar um erro.\nEm campos do tipo float, o separador decimal é o ponto (ex: 0.01). Não use outros separadores nestes campos.Se um valor de um campo numérico fora do espaço de treinamento inicial for utilizado para uma predição, também é recomendado o retreinamento do modelo para evitar erros de predição."
      }
    },
    resultsPredictAPI: {
      btns: ["Nova previsão" , "Copiar dados" , "Baixar CSV"],
      labels: [" linhas" , " linha", "prevido em"] 
    },
    modalContact: {
      title: 'Contato',
      label: 'se você tiver alguma dúida ou encontrar algum erro, por favor entre em contato conosco via ',
    },
    explainer: {
      title: "Explainer PT",
      subtitle: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Sed, porro reiciendis. Quisquam ratione nostrum molestias suscipit.",
      buttons: ["Mostrar melhores resultados" , "Esconder"],

    }
  },
};

export default messages;
