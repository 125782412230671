<template>
  <section class="ai__container">
    <h2 class="ai__title">Artificial Intelligence</h2>
    <p class="ai__description">
      {{analysisType.type}} -
      <strong
        >{{ stepList[currentIndex].step
        }}<button title="Help" aria-label="Help" @click="handleHelp(stepList[currentIndex].step)"></button
      ></strong>
    </p>

    <Steps :stepList="stepList" :currentIndex="currentIndex" />

    <article>
      <component
        :is="currentComponent"
      ></component>
    </article>
  </section>
</template>

<script>
import Steps from "./Steps.vue";
import Upload from "./Upload.vue";
import DataChoice from "./DataChoice.vue";
import Parameters from "./Parameters.vue";
import Processing from "./Processing.vue";
import Result from "./Result.vue";

import { useAiStore } from "../../stores/ai";
import { storeToRefs } from 'pinia';

export default {
  name: "AiRun",
  components: {
    Upload,
    Steps,
    DataChoice,
    Parameters,
    Processing,
    Result
  },
  setup() {
    const aiStore = useAiStore();
    const { analysisType, stepList, currentComponent, currentIndex, setModalHelper } = storeToRefs(aiStore);

    return {
      analysisType,
      stepList,
      currentComponent,
      currentIndex,
      setModalHelper,
    }
  },
  methods: {
    handleHelp(stepName) {
      const helpMsg = {"File upload": "Nesta etapa você deve fazer o upload do arquivo de dados.", "Data choice": "Escolha de dados"}
      this.setModalHelper = {
        title: stepName,
        message: helpMsg[stepName],
        show: true,
        bigText: false
      }
    },
  },
  unmounted() {
    this.currentComponent = "Upload";
  }
};
</script>

<style scoped>
.ai__container {
  margin-top: 32px;
  text-align: center;
  font-size: 16px;
  animation: surgir 1s;
}

@keyframes surgir {
  from {
    opacity: 0.5;
  }

  to {
    opacity: 1;
  }
}

.ai__title {
  font-weight: 600;
  font-size: 2em;
}

.ai__description {
  font-weight: 400;
  font-size: 1.25em;
  margin-top: 8px;
}

.ai__description > strong {
  color: var(--primary);
  font-weight: 600;
  cursor: pointer;
  white-space: nowrap;
}

.ai__description button {
  cursor: pointer;
  height: 20px;
  width: 20px;
  background: url(../../assets/img/ai/icon-help.svg) no-repeat center / contain;
  border: 0;
  transform: translate3d(8px, 3px, 0);
  transition: 0.4s ease;
}

.ai__description > strong:hover button {
  transform: translate3d(8px, 3px, 0) scale(1.2);
}

@media screen and (max-width: 767px) {
  .ai__container,
  .analysis__card {
    font-size: 12px;
  }
}
</style>
