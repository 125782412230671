<template>
  <div class="user__container">
    <div class="user__wrapper" ref="dropdown">
      <!-- Botão com a letra inicial do username: -->
      <div class="user__profile" @click="handleShowUserDropdown">
        {{username[0]}}
      </div>
      <!-- Lista de botões exibidos no dropdown: -->
      <Transition name="bounce">
        <div class="user__dropdown" v-if="showUserDropdown">
          <button class="dropdown__button dropdown__companies" title="Companies" @click="selectOption('Companies')" v-if="isSubs">
            <img src="../../assets/revxy/company.svg" alt="Companies icon">
            <span>{{$t("UserDropdown.companies")}}</span>
          </button>
          
          <!-- Isso aqui, no futuro vai ser usado quando tivermos o componente de customer portal pronto 
          <button class="dropdown__button" title="Settings" @click="selectOption('CustomerPortal')">
            <img src="../../assets/img/main/icon-perfil.svg" alt="Profile icon">
            <span>{{$t("UserDropdown.subscription")}}</span>
          </button> -->

          <!-- No momento, isso vai levar para o link de gerenciamento de assinatura do stripe  (AINDA Ñ TEMOS ESSE LINK)-->
          <!-- <button class="dropdown__button" title="Settings" @click="selectOption('CustomerPortal')">
            <img src="../../assets/img/main/icon-perfil.svg" alt="Profile icon">
            <span>{{$t("UserDropdown.subscription")}}</span>
          </button> -->

          <button class="dropdown__button" title="Settings" @click="selectOption('Settings')">
            <img src="../../assets/revxy/engine.svg" alt="Settings icon">
            <span>{{$t("UserDropdown.settings")}}</span>
          </button>
          <button class="dropdown__button dropdown__logout" @click="logout">
            <img src="../../assets/img/main/icon-logout.svg" alt="Logout icon">
            <span>{{$t("UserDropdown.logout")}}</span>
          </button>
        </div>
      </Transition>
    </div>
    </div>
</template>

<script>
import { usePageSelector } from "../../stores/pageSelector";
import { useViewSelector } from "../../stores/viewSelector";
import { useUserStore } from '../../stores/user'
import { storeToRefs } from 'pinia';

export default {
  name: "UserDropdown",
  setup() {
    const userStore = useUserStore();
    const { clearTrigger, isSubscription, multiple_comps,} = userStore;

    const viewSelector = useViewSelector();
    const { currentView, } = storeToRefs(viewSelector);

    const pageSelector = usePageSelector();
    const { redirectTo, } = pageSelector;

    return {
      currentView,
      redirectTo,
      clearTrigger,
      isSubscription,
      multiple_comps,
    }
  },
  computed: {
    isSubs(){
      console.log("IMPRIMINDO VAR isSubscription do pinia na COMPUTED --- "+this.isSubscription)
      console.log("IMPRIMINDO VAR multiple_comps do pinia na COMPUTED --- "+this.multiple_comps)
      if(this.isSubscription === true && this.multiple_comps == false){
        return false //para desaparecer a opção
      }
      else{
        return true //caso NÃO seja uma subscription, retornar true, para não esconder
      }      
    }
  },
  methods: {
    handleShowUserDropdown() {
      this.showUserDropdown = !this.showUserDropdown
    },
    selectOption(option) {
      // Se clicar no botão Companies, troca-se a view:
      if (option === 'Companies') {
        this.currentView = 'Companies'
        return
      }
      // Para os demais casos, apenas se troca a page:
      this.redirectTo(option)
      this.handleShowUserDropdown();
    },
    logout() {
      let url = process.env.VUE_APP_URL + '/logout';
      // >>> Criar um modal confirmando a ação do usuário? <<<
      fetch(url, {
        method:'GET',
      })
      .then((res) => res.json())
      .then((content) => {
        console.log(JSON.stringify(content.logout))
        if(content.logout){
          this.clearTrigger();
          console.log('Inside content.logout if')
          this.currentView = 'Login';
          this.redirectTo('Home'); //porque?
        }
      })
    },
    // Fechar o dropdown quando clicar fora do box:
    closeOutside(event) {
      const dropdown = this.$refs.dropdown
      const clickedOutside = !(dropdown == event.target || dropdown.contains(event.target))
      if (clickedOutside && this.showUserDropdown) {
        this.handleShowUserDropdown();
      }
    }
  },
  props: ["username"],
  data() {
    return {
      showUserDropdown: false,
    }
  },
  mounted() {
    document.addEventListener('click', this.closeOutside)

    console.log('Var do pinia isSubscription (USERDROPDOWN) -- '+this.isSubscription)
  },
  unmounted() {
    document.removeEventListener('click', this.closeOutside);
  }
}
</script>

<style scoped>
  .bounce-enter-active {
    animation: bounce-in .3s;
  }
  .bounce-leave-active {
    animation: bounce-in .3s reverse;
  }
  @keyframes bounce-in {
    0% {
      transform: scale(0) translateY(-160px);
    }
    100% {
      transform: scale(1) translateY(0);
    }
  }

  .user__wrapper {
    position: relative;
  }

  .user__profile {
    width: 48px;
    height: 48px;
    background-color: var(--secundary-3);
    border-radius: 50%;
    display: grid;
    place-content: center;
    color: #FFF;
    font-weight: 600;
    font-size: 24px;
    position: relative;
    cursor: pointer;
    transition: .4s ease;
    user-select: none;
    text-transform: uppercase;
  }

  .user__profile:hover {
    background-color: var(--primary);
  }

  .user__profile::after {
    content: '';
    width: 8px;
    height: 8px;
    display: block;
    background: url(../../assets/img/table/arrowDown.svg) no-repeat center / cover;
    position: absolute;
    right: -24px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }

  .user__dropdown {
    position: absolute;
    top: 64px;
    right: -24px;
    font-size: 14px;
    font-weight: 600;
    width: 220px;
    display: flex;
    flex-direction: column;
    background-color: var(--secundary-3);
    background-color: #FFF;
    padding: 24px;
    border-radius: 16px 0 16px 16px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    z-index: 12;
    overflow: hidden;
  }

  .dropdown__lang {
    margin-bottom: 8px;
  }

  .dropdown__button {
    margin-top: 16px;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid var(--secundary-3);
    position: relative;
    cursor: pointer;
    font-weight: 500;
    color: var(--secundary-3);
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    height: 40px;
    font-size: 14px;
  }

  .dropdown__logout {
    background-color: var(--secundary-3);
    color: #FFF;
  }

  .dropdown__button:first-of-type {
    margin-top: 0;
  }

  .dropdown__button > img {
    width: 24px;
    height: 24px;
    margin-right: -4px;
    pointer-events: none;
    /* filter: brightness(0) saturate(100%) invert(11%) sepia(55%) saturate(6196%) hue-rotate(241deg) brightness(88%) contrast(138%); */
  }

  .dropdown__companies > img {
    width: 18px;
    height: 18px;
    margin-right: -2px;
  }

  .dropdown__logout > img {
    width: 16px;
    height: 16px;
    filter: none;
    margin-right: 0;
  }

  .dropdown__button > span {
    pointer-events: none;
  }

  @media screen and (max-width: 767px) {
    .user__profile {
      width: 36px;
      height: 36px;
      font-size: 18px;
    }

    .user__dropdown {
      font-size: 12px;
      width: 210px;
      top: 48px;
    }

    .dropdown__logout {
      font-size: 12px;
    }
  }

</style>