<template>
  <article class="entry__upload">

    <!-- Modal de erro relacionado com o backend, caso dê merda no arquivo -->
    <!-- <ModalErrorAi v-if="errorAPI" message="Deu ruim no teu arquivo filhão (Entry upload)"/> -->

    <div class="title__wrapper">
      <button title="Back to previous page" aria-label="Back to previous page" class="button__back" @click="backPage">
        <img src="../../../assets/img/ai/icon-back-arrow.svg" alt="Back to previous page">
      </button>
      <h3 class="upload__title">{{$t('newPrediction.entryUpload.title')}}</h3>
    </div>
    <div class="upload__drop" :class="{ 'upload__drop--active': isDroping }" @dragenter.prevent="toggleIsDroping" @dragleave.prevent="toggleIsDroping" @dragover.prevent @drop.prevent="drop">
      <img
        src="../../../assets/img/fileUpload/feather_upload-cloud.svg"
        alt="Upload icon"
      />
      <p>{{$t('upload.drop[0]')}}</p>
      <label title="Select file" for="file">{{$t('upload.drop[1]')}}</label>
      <input type="file" name="file" id="file" accept=".xlsx, .csv, .parquet, .pkl, .pickle" @input="onFileSelected" />
    </div>

    <div class="upload__files" v-if="filesList !== []">
      <div
        class="upload__file"
        v-for="file in filesList"
        :key="file.lastModified"
      >
        <div class="upload__file--icon">
          <img
            src="../../../assets/img/fileUpload/akar-icons_file.svg"
            alt="File icon"
          />
        </div>

        <div class="upload__file--info">
          <div class="file__info">
            <span class="file__info--name">
              {{ file.name }}
            </span>
            <span>{{ (file.size / 1024).toFixed(2) }}kB</span>
          </div>
          <div class="file__progress">
            <progress :value="file.progress" max="100">
              {{ file.progress }}%
            </progress>
          </div>
        </div>

        <div class="upload__file--button">
          <button @click="removeFile(file)">
            <img
              src="../../../assets/img/fileUpload/trash-icon.svg"
              alt="Button icon"
            />
          </button>
        </div>
      </div>
    </div>

    <button
      class="upload__button"
      :class="{ disabled: disabledButton }"
      :disabled="disabledButton"
      @click="sendFile"
    >
      {{$t('newPrediction.entryUpload.button')}}
    </button>
  </article>
</template>

<script>
// import ModalErrorAi from '../../elements/ModalErrorAI.vue';
import { storeToRefs } from 'pinia';
import { useAiStore } from '../../../stores/ai';
import { useUserStore } from '../../../stores/user';
export default {
  name: "EntryUpload",
  // components: {ModalErrorAi},
  setup() {
    
    const aiStore = useAiStore();
    const { showEntryMenu, showDataEntry, fileUploadData, predOrigFileName, isLoadingResult, dataEntryType, isUploadPredict, setModalErrorAI} = storeToRefs(aiStore);

    const userStore = useUserStore();
    const { user_id, comp_id, empresa, } = storeToRefs(userStore);
    const { trigSessVerify } = userStore;
 
      
    return {
      user_id,
      comp_id,
      empresa,
      trigSessVerify,
      showEntryMenu,
      showDataEntry,
      fileUploadData,
      predOrigFileName,
      isLoadingResult,
      dataEntryType,
      isUploadPredict,
      setModalErrorAI,
    }
  },
  data() {
    return {
      filesList: [],
      isDroping: false,

      /* Se por acaso ocorrer um erro quando chamar a função '/predictUpload' da API*/
      // errorAPI: false,
    }
  },
  methods: {
    backPage() {
      this.showEntryMenu = true;
    },
    toggleIsDroping() {
      this.isDroping = !this.isDroping
    },
    drop(event) {
      this.toggleIsDroping();
      const file = event.dataTransfer.files[0];
      this.filesList.push(file);
    },
    sendFile() {
      let dataForm = new FormData();
      let file = this.filesList[0];
      this.predOrigFileName = file.name
      dataForm.append("fileName", file.name);
      console.log(this.predOrigFileName);
      dataForm.append("file", file);
      dataForm.append("bucketName", 'airpoon_ml_upload')
      dataForm.append("u_id", this.user_id);
      dataForm.append("c_id", this.comp_id);
      dataForm.append("company", this.empresa);
      console.log(file);
      this.isLoadingResult = true;
      let url = process.env.VUE_APP_URL + '/predictUpload';
      this.trigSessVerify();
      fetch(url, {
        method:'POST',
        body:dataForm,
      })
      .then((res) => res.json())
      .then((content) => {
        console.log(JSON.stringify(content))
        if(content.invalidSession){
          this.isSessionExpired = true;
        }else {

          if(content.errorAPI === true){
            this.setModalErrorAI.show = true
            this.setModalErrorAI.title = "Oopss"
            this.setModalErrorAI.msg = "Error"
            console.log("Erro no backend da AI")
            // this.errorAPI = true
          }
          console.log('Oi começamos o EntryUpload')
          console.log('Comentário do EntryUpload = Agora que upamos o arquivo e deu certo, vamos para o EntryPaste ')

          /* 
            Qual é a moral, existem 3 tipos de fazer o predict(entryManually, entryUpload e entryPaste),
            todos eles fazem a mesma coisa, que é chamar a função do Pinia lá no ai.js (handlePredictAPI).
            No caso do entryUpload, ele não chama essa função, ele envia os dados do arquivo para o 
            EntryPaste, que faz o cluster da tabela e que por sua vez possui um botão que ai sim chama a 
            função.
          */
          this.isUploadPredict = true; //Flag q diz que os dados chegaram no EntryPaste via EntryUpload

          /* 
            Preenche as vars do pinia com os dados do arquivo, assim
            quando o EntryPaste for Mounted() ele verifica a flag 'isUploadPredict'
            se for TRUE, faz o cluster de tabela com base nas variáveis do Pinia abaixo 
          */
          this.fileUploadData.fileName = content.dbFileName;
          this.fileUploadData.columnsName = content["Name of Columns"]
          this.fileUploadData.rowsData = content.Dataframe


          this.dataEntryType = "EntryPaste" //Modando o componente atual para o entryUpload
          this.isLoadingResult = false;
        }
      })
    },
    onFileSelected(event) {
      const file = event.target.files[0];
      if (file) {
        console.log(file);
        this.filesList.push(file);
      }
    },
    removeFile(targetFile) {
      this.filesList = this.filesList.filter((file) => file.name !== targetFile.name);
    },
    goToTable() {
      this.showEntryTable = true;
    }
  },
  computed: {
    disabledButton: function () {
      return this.filesList.length !== 1;
    },
  },
}
</script>

<style scoped>

.title__wrapper {
  position: relative;
  max-width: fit-content;
  margin: auto;
  display: flex;
  align-items: center;
  margin-bottom: 64px;
}

.button__back {
  background-color: transparent;
  border: 0;
  position: absolute;
  top: -2px;
  left: -64px;
  cursor: pointer;
}

.upload__title {
  font-size: 24px;
  font-weight: 500;
  color: #6b6b6b;
}

.upload__drop {
  margin: auto;
  max-width: 560px;
  height: 290px;
  box-sizing: border-box;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='16' ry='16' stroke='%23009F53' stroke-width='4' stroke-dasharray='16' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 16px;
  transition: .4s ease;
  margin-top: 16px;
}

.upload__drop--active {
  background-color: rgba(39, 94, 246, 0.2);
}

.upload__drop > p, .upload__text {
  margin-top: 32px;
  margin-bottom: 16px;
  font-weight: 500;
  color: var(--primary);
}

.upload__text {
  display: block;
  margin: 0;
}

.upload__drop > label,
.upload__button {
  border: 2px solid var(--primary);
  color: var(--primary);
  font-family: inherit;
  font-weight: 600;
  font-size: 14px;
  background: transparent;
  border-radius: 8px;
  width: 100%;
  max-width: 200px;
  height: 40px;
  cursor: pointer;
  display: grid;
  place-items: center;
  margin: 0 auto;
}

input[type="file"] {
  display: none;
}

.upload__files {
  margin: auto;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  margin-bottom: 24px;
  max-width: 560px;
}

.upload__button {
  border: 0;
  background-color: var(--primary);
  color: #fff;
  transition: .4s ease;
}

.upload__button:hover {
  background-color: var(--secundary-3);
}

.upload__button.disabled {
  visibility: hidden;
}

.upload__file {
  background: #ffffff;
  border-radius: 16px;
  min-height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
}

.upload__file--info {
  width: 100%;
  margin-left: 16px;
  margin-right: 40px;
}

.file__info {
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.file__info--name {
  display: block;
  margin-right: 8px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

@media screen and (max-width: 480px) {
  .file__info--name {
    max-width: 80px;
  }
}

@media screen and (max-width: 360px) {
  .file__info--name {
    max-width: 40px;
  }
}

.file__progress > progress {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  margin-top: 14px;
}

.file__progress > progress::-webkit-progress-bar {
  background: #ececec;
  border-radius: 8px;
  height: 8px;
}

.file__progress > progress::-webkit-progress-value {
  background: var(--primary);
  border-radius: 8px;
  height: 8px;
}

.file__progress > progress::-moz-progress-bar {
  background: #ececec;
  border-radius: 8px;
  height: 8px;
}

.upload__file--button > button {
  background: transparent;
  border: 0;
  cursor: pointer;
}

</style>