<template>
  <section class="table__container">
    <ModalError v-if="erroAPI" message="Deu pau no arquivo" :erroAPI="erroAPI" @close-modal="handleShowModalAPI"/>
    <h2 class="table__titulo">{{$t('table.titulo')}} <button title="Help" aria-label="Help" @click="handleHelp('dataChoice')"></button></h2> 
    <br>
    <div class="container__resetBD">
      <h4 class="table__subtitle">{{$t('table.reset')}}</h4> 
      <span class="tablehead__switch">
        <input
          type="checkbox"
          name="swResetDB"
          :id="'swResetDB'"
          :value="'swResetDB'"
          v-model="resetDB"
          checked
        />
        <label @click="resetDB = !resetDB" class="checkmark"></label>
      </span>
      <button title="Help" aria-label="Help" @click="handleHelp('clearBD')"></button>
    </div>
    <br>
    <Loading v-if="isLoading" :is-done="isDone" />
    <ModalError v-if="showModal" :message="errorMessage" :missing-attr="missingAttrSelected" @close-modal="handleShowModal" />
    <div v-if="!isLoading">
      <div class="table__box" :class="{nodata: noData}">
        <div class="table__wrapper">
          <table>
            <span class="dropdown__title" v-if="!noData">{{$t('table.dropdown[0]')}}</span>
            <span class="dropdown__title" v-if="!noData">{{$t('table.dropdown[1]')}}</span>
            <!-- Cabeçalho da tabela (nomes das colunas e botões) -->
            <tr>
              <th v-for="name in columnsName" :key="name">
                <div class="tablehead__options">
                  <!-- Target button (select coluna principal): DESABILITADO-->
                  <div class="tablehead__mainColumn" v-if="false">
                    <input
                      type="radio"
                      name="mainColumn"
                      :value="name"
                      :id="'mc' + name"
                      @input="event => mainColumnName = event.target.value"
                    />
                    <label :for="'mc' + name" class="checkmark"></label>
                  </div>
                  <!-- Switch green/red (selecionar ou descartar coluna) -->
                  <div class="tablehead__switch">
                    <input
                      type="checkbox"
                      name="switchColumn"
                      :id="'sw' + name"
                      :value="name"
                      v-model="selectedColumns"
                      checked
                    />
                    <label :for="'sw' + name" class="checkmark"></label>
                  </div>
                  <br>
                </div>
                <span :title="name" :class="{columnError: repeatedAttrColumns.includes(name)}">
                  {{ name }}
                </span>
                <!-- Data type dropdown -->
                <select class="tablehead__dropdown" v-model="selectedDataTypes[name]">
                  <option v-for="option in dataTypesOptions" :key="option" :value="option">{{option}}</option>
                </select>
                <!-- Attribute type dropdown -->
                <select class="tablehead__dropdown" :class="{dropdownError: repeatedAttrColumns.includes(name)}" v-model="selectedDropdownOptions[name]">
                  <option value="">-</option>
                  <option v-for="option in dropdownOptions" :key="option" :value="option">{{option}}</option>
                </select>
              </th>
            </tr>
            <!-- Corpo da tabela (dados das colunas renderizados por linha) -->
            <tr v-for="(row, rowIndex) in rowData" :key="rowIndex">
              <td v-for="(data, dataIndex) in rowData[rowIndex]" :key="dataIndex" :title="data">
                {{ data }}
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div class="buttons__wrapper">
        <button class="button" @click="handleConfirm">{{$t('table.botao')}}</button>
      </div>
    </div>
  </section>
</template>

<script>
import Loading from '../elements/Loading.vue';
import ModalError from '../elements/ModalError.vue';
import { useUserStore } from "../../stores/user";
import { useAiStore } from "../../stores/ai";
import { storeToRefs } from 'pinia';

export default {
  name: "Table",
  components: {Loading, ModalError},
  setup() {
    const userStore = useUserStore();
    const {user_id, bucket, empresa, fileName, anlyticsOrigFileName} = storeToRefs(userStore);
    const aiStore = useAiStore(); 
    const {setModalHelper} = storeToRefs(aiStore);

    return {
      user_id,
      bucket,
      fileName,
      empresa,
      anlyticsOrigFileName,
      setModalHelper,
    }
  },
  data() {
    return {
      // Exibir tela de carregamento
      isLoading: false,
      // Exibir tela de concluído (com isLoading == true):
      isDone: false,
      // Exibir modal
      showModal: false,
      errorMessage: "Tente novamente em alguns instantes.",
      /*
      columnsName: [
        "Cliente", "Segmento", "Contrato", "Número", "ID", "Locais", "Entrada", "Saída", "Valor", 
        "Valor", "Valor", "Valor", "Valor",
      ],
      rowData: [
        ["Cliente", "Outros", "-", "10", "Cliente 10", "1", "2019-12-01", "2020-01-01", "690", "690", "690", "690", "690"],
        ["Cliente", "Outros", "-", "10", "Cliente 10", "1", "2019-12-01", "2020-01-01", "690", "690", "690", "690", "690"],
        ["Cliente", "Outros", "-", "10", "Cliente 10", "1", "2019-12-01", "2020-01-01", "690", "690", "690", "690", "690"],
        ["Cliente", "Outros", "-", "10", "Cliente 10", "1", "2019-12-01", "2020-01-01", "690", "690", "690", "690", "690"],
        ["Cliente", "Outros", "-", "10", "Cliente 10", "1", "2019-12-01", "2020-01-01", "690", "690", "690", "690", "690"],
        ["Cliente", "Outros", "-", "10", "Cliente 10", "1", "2019-12-01", "2020-01-01", "690", "690", "690", "690", "690"],
      ],
      dropdownOptions: ["Atributo categórico", "Atributo individual", "ID", "Data mensal", "Data de início", "Data de churn", "Valor"],
      */

      noData:true, // Tabela sem dados (efeito gradiente loading)
      
      columnsName: [],
      rowData: [],
      // Coluna principal (botão alvo):
      mainColumnName: "",
      // Colunas com switch verde:
      selectedColumns: [],
      // Opções do dropdown de tipo de atributo:
      dropdownOptions: [],
      selectedDropdownOptions: {},
      // Opções do dropdown de tipo de dado:
      dataTypesOptions: ["string", "int", "float", "date", "bool"],
      selectedDataTypes: {},
      // Atributos obrigatórios
      requiredAttr: ["ID", "Data de início", "Data de churn", "Valor"],
      // Atributos repetidos
      repeatedAttr: [],
      resetDB: false,
      erroAPI: false,
    };
  },
  computed: {
    repeatedAttrColumns() {
      return this.repeatedAttr
        // Apenas atributos obrigatórios não podem ser repetidos:
        .filter(array => this.requiredAttr.includes(array[1]))
        .map(array => array[0])
    },
    // Atributos obrigatórios que o usuário ainda não selecionou:
    missingAttrSelected() {
      const missingAttr = [];
      const selectedValues = Object.values(this.selectedDropdownOptions);
      for (const attr of this.requiredAttr) {
        if (!selectedValues.includes(attr)) {
          missingAttr.push(attr)
        }
      }
      
      return missingAttr
    }
  },
  methods: {
    handleHelp(whatHelp) {
      console.log(whatHelp)

      let titulo = ""
      let msg = ""

      if(whatHelp === "dataChoice"){
        titulo = this.$i18n.t("table.help.titleDataChoice")
        msg = this.$i18n.t("table.help.labelDataChoice")
      }
      else{
        titulo = this.$i18n.t("table.help.titleClearData")
        msg = this.$i18n.t("table.help.labelClearData")
      }
      this.setModalHelper = {
        title: titulo,
        message: msg,
        show: true,
        bigText: true,
      }
    },
    handleShowModal() {
      this.showModal = !this.showModal;
    },
    handleShowModalAPI(){
      this.erroAPI = !this.erroAPI
    },
    handleConfirm() {
      // Se há atributos obrigatórios repetidos:
      if (this.repeatedAttrColumns.length > 0) {
        this.errorMessage = this.$i18n.t("modalError.errors[0]")
        this.handleShowModal();
        return
      }
      // Se há atributos obrigatórios não selecionados:
      if (this.missingAttrSelected.length > 0) {
        const attributes = this.missingAttrSelected;
        const message = attributes.length === 1 
          ? this.$i18n.t("modalError.errors[1]")
          : this.$i18n.t("modalError.errors[2]")
        this.errorMessage = message
        this.handleShowModal();
        return
      }
      this.isLoading = true
      
      // Filtrar apenas os datatypes de colunas que possuem atributos selecionados
      const filteredDataType = 
        Object.entries(this.selectedDataTypes)
          .filter(array => Object.keys(this.selectedDropdownOptions).includes(array[0]))
      // Objeto a ser enviando para o back-end
      const sending = {
        selectedAttributes: {...this.selectedDropdownOptions},
        selectedDataTypes: Object.fromEntries(filteredDataType),
      }
      let url = process.env.VUE_APP_URL + '/tableConfirm';
      let selAtrib = sending.selectedAttributes;
      let selectedData = { bname:this.bucket, fname:this.fileName, orig_fname:this.anlyticsOrigFileName, company:this.empresa, selected:selAtrib, dictTypes:this.selectedDataTypes, resetDB:this.resetDB};
      fetch(url, {
        method:'POST',
        body:JSON.stringify(selectedData),
        headers: new Headers({
          "content-type":"application/json",
          // 'Request-Timeout':3600000,
          // "Keep-Alive": 'timeout'=3600000,
        })
      })
      .then((res) =>res.json())
      .then((content) => {
        console.log(content)
        if(content.errorAPI === true){
          this.erroAPI = true
        }
        console.log(JSON.stringify(content))
        // console.log("Receba: ", sending)
        if(content.datasetDelivered){
          this.isDone = true;
        }
      })
      
    },
    updateRepeatedAttr() {
      // Conta quantas vezes cada atributo foi selecionado:
      const selectedDropdownValues = Object.values(this.selectedDropdownOptions)
      let counts = {};
      selectedDropdownValues.forEach(value => {
        counts[value] = (counts[value] || 0) + 1
      })
      const repeatedAttr = 
        Object.entries(counts).filter(array => array[1] > 1).map(array => array[0])
      this.repeatedAttr = 
        Object.entries(this.selectedDropdownOptions)
          .filter(array => repeatedAttr.includes(array[1]))
    },
  },
  beforeUpdate() {
    // Filtrar chaves com valores vazios do selectedDropdownOptions:
    const filteredDropdownOptions = 
      Object.entries(this.selectedDropdownOptions)
        .filter(array => array[1] !== "")
    this.selectedDropdownOptions = Object.fromEntries(filteredDropdownOptions)
    // Atualizar atributos repetidos:
    this.updateRepeatedAttr()
  },
  mounted() {
    let url = process.env.VUE_APP_URL + '/tablePreview';
    let links = {bname:this.bucket, fname:this.fileName};
    console.log('links: '+JSON.stringify(links))
    fetch(url, {
      method:'POST',
      body:JSON.stringify(links),
      headers: new Headers({
        "content-type":"application/json"
      })
    })
    .then((res) =>res.json())
    .then((content) => {
      console.log("Mounted:", content)
      if(content.errorAPI === true){
        console.log("Realmente deu erro no back")
        this.erroAPI = true
      }
      this.noData = false;
      this.columnsName = content["Name of Columns"];
      this.rowData = content["Dataframe"];
      this.selectedColumns = content["Name of Columns"];
      this.dropdownOptions = content["Dropdown"];
      const types = content["Types"];
      types.forEach((type, index) => {
        const column = this.columnsName[index];
        this.selectedDataTypes[column] = type
      });
    })
  }
};
</script>

<style scoped>
.table__container {
  margin: 0 auto;
  margin-top: 32px;
  animation: surgir 1s;
  width: 100%;
  min-width: 70vw;
  max-width: min-content;
}
@media screen and (max-width: 767px) {
  .table__container {
    min-width: 100%;
  }
}
@keyframes surgir {
  from {
    opacity: 0.5;
    transform: translateY(32px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.table__titulo {
  font-weight: 600;
  margin-bottom: 32px;
}

.table__subtitle{
  font-weight: 600;
  margin-bottom: 0px;
}
.table__box {
  width: 100%;
  background-color: #fff;
  border-radius: 16px;
  padding: 12px 16px 16px 0px;
  max-width: calc(100vw - 96px - 48px - 16px);
}

.table__box.nodata {
  padding: 16px;
}

.table__box.nodata > .table__wrapper {
  display: inline-block;
  position: relative;
  overflow: hidden;
  border-radius: 16px;
}
.table__wrapper {
  width: 100%;
  height: 50vh;
  overflow: scroll;
}

.table__box.nodata > .table__wrapper::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(
    90deg,
    rgba(228, 235, 242, 0) 0,
    rgba(213, 224, 238, 0.2) 20%,
    rgba(213, 224, 238, 0.4) 60%,
    rgba(228, 235, 242, 0)
  );
  animation: shimmer 2s infinite;
  content: '';
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

::-webkit-scrollbar {
  height: 8px;
  border-radius: 8px;
  width: 8px;
}
::-webkit-scrollbar-track {
  background: transparent;
  margin-left: 24px;
}
::-webkit-scrollbar-thumb {
  background: #bdc5cc;
  border-radius: 8px;
  transition: 0.4s;
}
::-webkit-scrollbar-thumb:hover {
  background: #95b0c7;
}
.buttons__wrapper {
  display: flex;
  justify-content: center;
  margin-top: 32px;
  gap: 24px;
}

.container__resetBD{
  height: max-content;
  width: max-content;
  display: flex;
  align-items: center;
  gap: 10px;
}

.container__resetBD button{
  cursor: pointer;
  height: 20px;
  width: 20px;
  background: url(../../assets/img/ai/icon-help.svg) no-repeat center / contain;
  border: 0;
  transition: 0.4s ease;
  margin-left: 20px;
}

.container__resetBD button:hover{
  transform: scale(1.2);
}

h2 button{
  cursor: pointer;
  height: 20px;
  width: 20px;
  background: url(../../assets/img/ai/icon-help.svg) no-repeat center / contain;
  border: 0;
  transition: 0.4s ease;
}

h2 > button:hover  {
  transform: scale(1.2);
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary);
  border: 2px solid var(--primary);
  font-family: inherit;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  height: 40px;
  width: 100%;
  max-width: 220px;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.2s ease;
}
.button.disabled {
  opacity: 0.4;
  cursor: not-allowed;
  pointer-events: none;
}
.button:hover {
  background-color: var(--secundary-3);
  border-color: var(--secundary-3);
}
.button__secundary {
  background-color: transparent;
  color: var(--primary);
}
.button.button__secundary:hover {
  background-color: transparent;
  border-color: var(--secundary-3);
  color: var(--secundary-3);
}
@media screen and (max-width: 767px) {
  .table__box {
    max-width: calc(100vw - 32px);
  }
  .buttons__wrapper {
    gap: 12px;
  }
}
/* Tabela */
table {
  width: 100%;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  padding-left: 64px;
  position: relative;
}
tr {
  display: block;
}
tr:nth-child(even) {
  background-color: rgba(228, 235, 242, 0.4);
}
th,
td {
  min-width: 120px;
  max-width: 120px;
  /* height: 40px; */
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 16px 8px;
  white-space: nowrap;
}
th {
  font-weight: 600;
  text-transform: uppercase;
  height: auto;
  padding-top: 0;
  padding-bottom: 8px;
  white-space: nowrap;
  position: relative;
}
.switch_table{
  position: absolute;
  left: 0;
}
th > span {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}
/* Inputs customizados */
.tablehead__options {
  display: flex;
  align-items: center;
  width: fit-content;
  margin-left: auto;
  margin-bottom: 10px;
}
.tablehead__mainColumn > input,
.tablehead__switch > input {
  display: none;
}
.tablehead__mainColumn input ~ .checkmark {
  display: block;
  height: 16px;
  width: 16px;
  background: url(../../assets/img/table/targetOff.svg) no-repeat center /
    contain;
  cursor: pointer;
  margin-right: 6px;
  transition: 0.4s ease;
}
.tablehead__mainColumn .checkmark:hover {
  filter: brightness(0.6);
}
.tablehead__mainColumn input:checked ~ .checkmark {
  background-image: url(../../assets/img/table/targetOn.svg);
}
.tablehead__switch input ~ .checkmark {
  display: block;
  height: 12px;
  width: 24px;
  background: url(../../assets/img/table/switchOff.svg) no-repeat center /
    contain;
  cursor: pointer;
  transition: 0.1s linear;
}
.tablehead__switch input:checked ~ .checkmark {
  background-image: url(../../assets/img/table/switchOn.svg);
}
.tablehead__dropdown {
  display: block;
  border: 1px solid rgba(223, 223, 223, 0.8);
  border-radius: 8px;
  padding: 8px 20px 8px 8px;
  margin: 8px 0;
  cursor: pointer;
  transform: translateX(-7px);
  width: calc(100% + 7px);
  position: relative;
  outline: none;
  -moz-appearance:none;
  -webkit-appearance: none; 
  appearance: none;
  background: url(../../assets/img/table/arrowDown.svg) no-repeat center / contain;
  background-size: 9px;
  background-position: right 6px center;
  line-height: 16px;
}
.tablehead__dropdown.dropdownError {
  border-color: rgba(255, 0, 0, 0.4);
  background-color: rgba(255, 0, 0, 0.04);
  color: #db0606;
}
span.columnError {
  color: #db0606;
}
.dropdown__title {
  position: absolute;
  font-size: 10px;
  max-width: 44px;
  text-align: center;
  top: 57px;
  left: 12px;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 600;
}
.dropdown__title:nth-of-type(2) {
  top: 98px;
}
</style>