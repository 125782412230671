<template>
  <nav class="menu-lateral">
    <!-- Overlay do menu mobile: -->
    <div
      class="menu-lateral__overlay"
      :class="{ ativo: showMenu }"
      @click="handleShowMenu"
    ></div>

    <div class="menu-lateral__wrapper">
      <!-- Lytks: -->
      <div class="logo-account">
        <div class="menu-lateral__logo"></div>
        <p
          v-html="`<strong>${empresa}</strong>`"
          class="principal__logged"
        ></p>
      </div>

      <div style="display: flex; gap: 20px">
        <ul class="icons-desktop-loading" v-if="optionsList.length === 0">
          <li v-for="n in 2" :key="n"></li>
        </ul>
        <ul v-else class="icons-desktop">
          <li
            class="menu-lateral__option"
            v-for="option in optionsList"
            :key="option.title"
          >
            <button
              class="icon"
              :title="option.title"
              @click="selectOption(option.component)"
              :class="{ active: currentPage === option.component }"
            ></button>
          </li>
        </ul>
        <UserDropdown class="user__container" :username="firstname" />
        <!-- Botão menu hamburguer (mobile only): -->
        <button
          class="menu-lateral__hamburger"
          @click="handleShowMenu"
          :class="{ ativo: showMenu }"
        ></button>
      </div>

      <div class="menu-mobile" :class="{ ativo: showMenu }">
        <ul v-if="optionsList.length === 0" class="menu-mobile-loading">
          <li v-for="n in 2" :key="n"></li>
        </ul>
        <ul v-else>
          <li
            class="menu-lateral__option"
            v-for="option in optionsList"
            :key="option.title"
          >
            <button
              class="icon"
              :title="option.title"
              @click="selectOption(option.component)"
              :class="{ active: currentPage === option.component }"
            ></button>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
  import { storeToRefs } from "pinia";
  import { usePageSelector } from "../../stores/pageSelector";
  import { useUserStore } from "../../stores/user";
  import UserDropdown from "./UserDropdown.vue";

  export default {
    name: "LateralMenu",
    components: { UserDropdown },
    setup() {
      const pageSelector = usePageSelector();
      const { currentPage } = storeToRefs(pageSelector);
      const { redirectTo } = pageSelector;

      const userStore = useUserStore();
      const { statusSub, empresa, firstname } = storeToRefs(userStore);

      return {
        currentPage,
        redirectTo,
        pageSelector,
        statusSub,
        empresa,
        firstname,
      };
    },
    data() {
      return {
        showMenu: false, // Mobile
        optionsList: [], // Lista dos botões
      };
    },
    methods: {
      // função que bloqueia botões no menu lateral, vou deixar aqui, vai q seja útil no futuro
      // getPaymentStatus(option){

      //   switch (this.statusSub){
      //     case 'past_due': return false;

      //     case 'active': return false;

      //     default: // (incomplete, incomplete_expired, canceled, unpaid)
      //       switch(option){
      //         case 'Home': return false;
      //         case 'Analytics': return false;
      //         case 'File Upload': return false;
      //         case 'AI': return true;
      //       }
      //     break;
      //   }
      // },
      handleShowMenu() {
        this.showMenu = !this.showMenu;
      },
      selectOption(option) {
        this.redirectTo(option);
        this.handleShowMenu();
      },
    },
    mounted() {
      this.pageSelector.getLateralMenuElements().then((elements) => {
        // Array de referência para renderizar os botões, contendo o nome a ser exibido no hover e o componente da página para qual o botão redireciona.
        let reference = [
          { title: "Home", component: "Home" },
          { title: "Analytics", component: "Analytics" },
          { title: "File Upload", component: "FileUpload" },
          // { title: "AI", component: "AiChoose" },
        ];

        // Filtragem do array de referência de acordo com os elementos recebidos do banco de dados (exibir apenas o que o usuário pode ver).
        this.optionsList = reference.filter((el) =>
          elements.map((el) => el.name).includes(el.component)
        );

        console.log(this.optionsList);

        console.log("Var do Pinia (statusSub) -- " + this.statusSub);
      });
    },
  };
</script>

<style scoped>
  .indisponivel {
    pointer-events: none;
  }

  .user__container {
    /* position: absolute;
    right: 24px;
    top: 136px; */

    padding-right: 24px;
  }

  .menu-lateral {
    color: #000;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    padding: 32px 24px;
    position: relative;
    z-index: 20;


    position: fixed;
    width: 100vw;
    padding-left: 0;
    padding-right: 0;
    z-index: 20;
    background-color: #fff;
    box-shadow: 0px 6px 8px -6px rgba(24, 39, 75, 0.12), 0px 8px 16px -6px rgba(24, 39, 75, 0.08);

  }

  .logo-account {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  .logo-account .principal__logged {
    font-style: italic;
    font-weight: 400;
    /* border-bottom: 1px solid rgba(166, 166, 166, 0.16); */
  }

  .menu-lateral__overlay {
    display: none;
  }

  .menu-lateral__wrapper {
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 16px;
    position: static;
  }

  .menu-lateral__logo {
    height: 50px;
    width: 50px;
    background: url("../../assets/img/brand/aihuk_logo.svg") no-repeat center /
      contain;
  }

  .menu-lateral__hamburger {
    display: none;
    height: 40px;
    width: 40px;
    border: 0;
    background: url("../../assets/img/main/hamburger.svg");
    transition: 0.4s ease;
    cursor: pointer;
  }

  .menu-lateral__hamburger.ativo {
    background: url("../../assets/img/main/close.svg");
  }

  .menu-lateral__option > button:hover,
  .menu-lateral__option > button:focus {
    background-color: rgba(255, 255, 255, 0.12);
    outline: 0;
  }

  .icons-desktop {
    display: flex;
    gap: 20px;
  }

  button.icon {
    background: transparent;
    border: 0;
    height: 48px;
    width: 48px;
    border-radius: 8px;
    background: url("../../assets/img/main/icon-home.svg") no-repeat center /
      contain;
    background-size: 40px;
    box-sizing: border-box;
    cursor: pointer;
    transition: 0.4s ease;
  }

  button.active,
  button.active:hover {
    background-color: rgba(255, 255, 255, 0.24);
  }
  button[title="Home"] {
    background-image: url("../../assets/img/main/icon-home.svg");
    display: none;
  }
  button[title="Analytics"] {
    background-image: url("../../assets/img/main/icon-analytics.svg");
    display: none;
  }
  button[title="File Upload"] {
    background-image: url("../../assets/img/main/icon-uploadfile.svg");
    display: none;
  }
  button[title="Ai"] {
    background-image: url("../../assets/img/ai/icon-ai.svg");
  }

  .menu-mobile {
    position: absolute;
    background-color: #1a1a1a;
    right: -40vw;
    top: 110px;
    padding: 50px;
    height: 100vh;
    transition: 0.4s ease;

    display: none;
  }

  .menu-mobile.ativo {
    right: 0;
  }

  .menu-mobile ul li {
    margin: 20px 0;
  }

  .icons-desktop-loading {
    display: flex;
    gap: 20px;
  }

  .icons-desktop-loading li {
    height: 48px;
    width: 48px;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.12);
    overflow: hidden;
    position: relative;
  }

  .icons-desktop-loading li::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(228, 235, 242, 0) 0,
      rgba(213, 224, 238, 0.1) 20%,
      rgba(213, 224, 238, 0.2) 60%,
      rgba(228, 235, 242, 0)
    );
    animation: shimmer 2s infinite;
    content: "";
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }

  @media screen and (max-width: 767px) {
    .menu-lateral__hamburger {
      display: block;
    }

    .icons-desktop {
      display: none;
    }

    .menu-mobile {
      display: block;
    }

    .icons-desktop-loading {
      display: none;
    }

    .menu-lateral__overlay {
      display: block;
      position: fixed;
      top: 112px;
      left: 0;
      height: 100vh;
      width: 100vw;
      background: rgba(0, 0, 0, 0.32);
      pointer-events: none;
      opacity: 0;
      transition: 0.4s ease;
    }
    .menu-lateral__overlay.ativo {
      opacity: 1;
      pointer-events: all;
    }

    .menu-mobile-loading li {
      height: 48px;
      width: 48px;
      border-radius: 8px;
      background-color: rgba(255, 255, 255, 0.12);
      overflow: hidden;
      position: relative;
    }

    .menu-mobile-loading li::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: translateX(-100%);
      background-image: linear-gradient(
        90deg,
        rgba(228, 235, 242, 0) 0,
        rgba(213, 224, 238, 0.1) 20%,
        rgba(213, 224, 238, 0.2) 60%,
        rgba(228, 235, 242, 0)
      );
      animation: shimmer 2s infinite;
      content: "";
    }
  }
</style>
