<template>
  <!-- Este componente é geralmente utilizado para previnir erros por parte do usuário na escolha de colunas das tabelas (Table, DataChoice, Predicts) -->
  <section class="modal__overlay">
    <div class="modal__container">
      <div class="modal__banner">
      </div>
      <div class="modal__content">
        <h3 class="modal__title">{{setModalErrorAI.title}}</h3>
        <!-- Mensagem de erro personalizada -->
        <p class="modal__message">{{setModalErrorAI.msg}}</p>
        <div class="buttons__wrapper">
          <!-- <button class="button" @click="backToAnalysis('AiChoose')">{{ $t('modalError.buttons[0]') }}</button> -->
          <button class="button" @click="backToAnalysis('Home')">{{ $t('modalError.buttons[1]') }}</button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { storeToRefs } from 'pinia';
import { useAiStore } from '../../stores/ai';
import { usePageSelector } from "../../stores/pageSelector";

export default {
  name: "ModalError",
  // props: ['message', 'errorAPI_AI'],
  setup(){

    const aiStore = useAiStore();
    const { setModalErrorAI } = storeToRefs(aiStore);

    const pageSelector = usePageSelector();
    const { redirectTo } = pageSelector;

    return{
      redirectTo,
      setModalErrorAI,
    }
  },
  methods: {
    closeModal() {
      this.setModalErrorAI.show = false
      this.$emit('close-modal');
    },
    backToAnalysis(page){
      this.setModalErrorAI.show = false
      this.redirectTo(page)
    }
  },
  computed: {
    showList: function() {
      return !this.message.startsWith('Não') && !this.message.startsWith('There')
    },
  }
}
</script>

<style scoped>
  .modal__overlay {
    background-color: rgba(0, 0, 0, 0.4);
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100vw;
    z-index: 20;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: .3s esmaecer;
    padding: 16px;
  }

  @keyframes esmaecer {
    from {
      opacity: 0
    }
    to {
      opacity: 1;
    }
  }

  .modal__container {
    background-color: #FFF;
    width: 100%;
    max-width: 640px;
    min-height: 280px;
    border-radius: 16px;
    display: grid;
    grid-template-areas: 'banner' 'content';
    grid-template-rows: 120px 1fr;
    box-shadow: 0px 6px 8px -6px rgba(24, 39, 75, 0.12), 0px 8px 16px -6px rgba(24, 39, 75, 0.08);
    animation: .3s crescer;
  }

  @keyframes crescer {
    from {
      transform: scale(0);
    }
    to {
      transform: scale(1);
    }
  }

  .modal__banner {
    background: url(../../assets/img/warning.svg) no-repeat center / contain;
    background-size: 72px;
    background-color: var(--secundary-3);
    border-radius: 16px 16px 0 0;
    grid-area: banner;
    position: relative;
    padding: 24px;
  }

  .modal__closeButton {
    height: 32px;
    width: 32px;
    background: url(../../assets/img/main/close.svg) no-repeat center / contain;
    border: 0;
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
  }

  .modal__content {
    display: grid;
    place-content: center;
    grid-area: content;
    padding: 24px;
    text-align: center;
    margin: auto;
  }

  .modal__title {
    color: var(--secundary-3);
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 16px;
  }

  .modal__message {
    line-height: 22px;
  }

  .buttons__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 32px;
    gap: 24px;  
  } 

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--primary);
    border: 2px solid var(--primary);
    font-family: inherit;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    height: 40px;
    width: 100%;
    max-width: 220px;
    border-radius: 8px;
    cursor: pointer;
    transition: 0.2s ease;
  }

  .button:hover{
    background-color: var(--secundary-3);
    border-color: var(--secundary-3);
  }
</style>