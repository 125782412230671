<template>
  <div class="container">
    <h3 class="title">{{ $t('explainer.title') }}<button title="Help" aria-label="Help" @click="handleHelp('predictAPI_title')" :class="{reativar_animacao: cursorStoped}"></button></h3>
    <button title="Back to previous page" aria-label="Back to previous page" class="back-button" @click="backPage">
      <img src="../../assets/img/ai/icon-back-arrow.svg" alt="Back to previous page">
    </button>
  </div>

  <div class="graph_container">

  </div>
  <div class="more_details">
    <p>{{ $t('explainer.subtitle') }}</p>
    <button @click="showTable">Show table</button>
  </div>

  <div class="table_box" v-if="tableVisibility">
    <div class="table__wrapper">
        <table>
          <tr>
            <th v-for="columnName in tableContent.columnsName" :key="columnName">{{ columnName }}</th>
          </tr>
          <tr v-for="row in tableContent.rowsData" :key="row[0]">
            <td :title="row[0]">{{row[0]}}</td>
            <td :title="row[1]">{{row[1]}}</td>
            <td>
              <select class="tablehead__dropdown">
                <option value="">-</option>
                <option v-for="func in selectFunctions" :key="func" :value="func">{{func}}</option>
              </select>
            </td>
            <td>
              <input type="text" placeholder="Enter a value">
            </td>
          </tr>
        </table>
      </div>
  </div>
</template>

<script>
import { storeToRefs } from 'pinia';
import { useAiStore } from '../../stores/ai'

export default{
  name: 'Explainer',
  components: {},
  setup() {
    const aiStore = useAiStore();
    const { currentModelResults, selectedColumns, APItoken, setModalHelper} = storeToRefs(aiStore);

    return {
      currentModelResults,
      selectedColumns,
      APItoken,
      setModalHelper,
    }
  },
  data() {
    return {
      tableContent: {
        columnsName: ["Field","Data type","Function","Value"],
        rowsData: [["Male", "string"], ["Age", "int"], ["Weight","float"], ["Height", "float"]],
      },
      selectFunctions: ["teste","teste2","teste3","teste4","teste5",],

      tableVisibility: false,
    }
  },
  methods: {
    backPage() {
      this.currentModelResults = 'Metrics';
    },
    showTable(){
      this.tableVisibility = !this.tableVisibility;
    }
  },
  mounted() {
    // let url = process.env.VUE_APP_URL + '/Explain';

    // let nB = {}

    // fetch(url , {
    //   method:'POST',
    //   body:JSON.stringify(nB),
    //   headers: new Headers({
    //     "content-type":"application/json"
    //   })
    // })
    // .then((res) => res.json())
    // .then((content)=>{
    //   console.log(content)
        
    // }).catch( (error) => console.log('ERRO: '+error))
  },
}
</script>

<style scoped>
.container{
  position: relative;
}

.title{
  font-size: 24px;
  border-top: 2px solid #E2E2E2;
  padding-top: 28px;
  margin: 32px 0;
  font-weight: 600;
}

.back-button {
  position: absolute;
  left: 0px;
  top: 26px;
  background: transparent;
  border: 0;
  cursor: pointer;
}

h3 button , label button{
  cursor: pointer;
  height: 20px;
  width: 20px;
  background: url(../../assets/img/ai/icon-help.svg) no-repeat center / contain;
  border: 0;
  transition: 0.4s ease;
  position: relative;
  left: 9px;
  top: 3px;
}

.graph_container{
  background-color: #FFF;
  width: 100%;
  height: 500px;
  border-radius: 16px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.more_details{
  margin: 50px 0;
}

.more_details button{
  margin-top: 10px;
  background: transparent;
  border: 2px solid var(--primary);
  color: var(--primary);
  padding: 10px;
}

.table_box{
  background-color: #FFF;
  border-radius: 16px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
  overflow: scroll;
}

::-webkit-scrollbar {
  height: 8px;
  border-radius: 8px;
  width: 8px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #bdc5cc;
  border-radius: 8px;
  transition: 0.4s;
}

::-webkit-scrollbar-thumb:hover {
  background: #95b0c7;
}

table {
  width: 100%;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  position: relative;
}

tr {
  display: inline-block;
}

tr:nth-child(even) {
  background-color: rgba(228, 235, 242, 0.4);
}

th,
td {
  min-width: 180px;
  max-width: 180px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 16px 8px;
  white-space: nowrap;
}

th {
  font-weight: 600;
  text-transform: uppercase;
  height: auto;
  white-space: nowrap;
}

td > input {
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid #A2A4A4;
  max-width: 100%;
  outline: 0;
  padding: 4px;
}
</style>