<template>
  <section class="notfound__container">
    <article class="notfound__wrapper">
      <img src="../../assets/img/not-found.svg" alt="Not found" class="notfound__img">
      <h3 class="notfound__error">{{$t('notFound.content[0]')}}<span>{{$t('notFound.content[1]')}}</span></h3>
      <button class="notfound__button" @click="redirectTo('AiChoose')">{{$t('notFound.content[2]')}}</button>
    </article>
  </section>
</template>

<script>
import { usePageSelector } from '../../stores/pageSelector';
export default {
  name: 'NotFound',
  setup() {
    const pageSelector = usePageSelector();
    const { redirectTo } = pageSelector;

    return { redirectTo }
  }
}
</script>

<style scoped>
.notfound__container {
  margin: auto;
  margin-top: 32px;
  max-width: 560px;
  animation: surgir 1s;
  display: grid;
  place-content: center;
}

@keyframes surgir {
  from {
    opacity: 0.5;
    transform: translateY(32px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.notfound__wrapper {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  font-size: 16px;
  margin-top: 40px;
}

@media screen and (max-width: 480px) {
  .notfound__wrapper {
    font-size: 14px;
  }
}

.notfound__img {
  width: 100%;
  max-width: 240px;
  margin-bottom: 48px;
  animation: circular 1s ease-in-out infinite alternate;
}

@keyframes circular {
  0% {
    transform: translate3d(0px,0px,0px);
  }
  100% {
    transform: translate3d(24px,24px,0px);
  }
}

.notfound__error {
  font-size: 1.5em;
  font-weight: 600;
}

.notfound__error > span {
  font-weight: 400;

}

.notfound__button {
  background-color: var(--primary);
  color: #FFF;
  width: 100%;
  max-width: 200px;
  height: 40px;
  border: 0;
  font-weight: 600;
  border-radius: 8px;
  font-size: .8em;
  cursor: pointer;
  transition: .4s ease;
  margin-top: 32px;
}

.notfound__button:hover {
  background-color: var(--secundary-3);
}

</style>