<template>
  <ModalEdit v-if="showEditModal" @close-modal="toggleShowEditModal" @analysis-updated="updateAnalysisName" :fields="{[fieldModalEdit] : editTarget.name}" :title="titleModalEdit" :analysisID="editTarget.id" />
  <section class="ai__container">
    <!-- Lista de análises ativas criadas anteirormente: -->
    <article class="ai__active" v-if="showPrevAnalysis">
      <h2 class="ai__title">{{ $t('chooseAnalysis.titles[0]') }}</h2>
      <p class="ai__description">{{ $t('chooseAnalysis.labels[0]') }}</p>
      <button class="ai__button" :class="{disabled : canCreateAnalysis}" @click="handleCreateAnalysis">{{ $t('chooseAnalysis.buttons[0]') }}</button>

      <ul class="ai__prev-analyses" v-if="prevAnalysisList.length > 0">
        <!-- Cards das análises -->
        <li class="prev-analysis" v-for="analysis in prevAnalysisList" :key="analysis" :title="analysis.description">
          <div class="prev__icon">
            <!-- <img :src="require('../../assets/img/ai/analyses/' + analysis.icon)" :alt="analysis.name"> -->
          </div>
          <div class="prev__content">
            <div class="infos__name">
              <h3 class="infos__title" :title="analysis.name">{{analysis.name}}</h3>
              <button title="Edit analysis name" aria-label="Edit analysis name" class="edit-button" @click="handleEditAnalysis(analysis)">
                <img src="../../assets/img/ai/icon-edit.svg" alt="Info">
              </button>
            </div>
            <div class="infos__type">
              <h3 class="infos__subtitle">{{analysis.type}}</h3>
              <button :title="analysis.description" :aria-label="analysis.description" class="info-button">
                <img src="../../assets/img/ai/icon-info.svg" alt="Info">
              </button>
            </div>
            <ul>
              <li class="infos__item" v-for="info in Object.entries(analysis.infos)" :key="info[0]" :title="info[0]">
                <strong>{{getCreatedAnalysisCardsInfosTranslated(info[0])}}:</strong> {{info[1]}}
              </li>
            </ul>
            <div class="infos__buttons">
              <button @click="handlePredict(analysis)">{{ $t('chooseAnalysis.analysisCards.buttons[0]') }}</button> 
              <button @click="handleStatus(analysis)">{{ $t('chooseAnalysis.analysisCards.buttons[1]') }}</button>
            </div>
          </div>
        </li>
      </ul>
      <!-- Se ainda não houver análises criadas: -->
      <h3 class="no__prev" v-else>{{ $t('chooseAnalysis.error') }}</h3>

      <!-- Paginação -->
      <div class="analysis__pagination">
        <!-- Botão página anterior: -->
        <button class="pagination__latbutton" :class="{disabled: analysisPagination.currentPage == 1}" @click="getPaginateAnalysis(analysisPagination.currentPage - 1)"><img src="../../assets/img/ai/right-arrow.svg" alt="Arrow"> {{ $t('chooseAnalysis.buttons[3]') }}</button>
        <!-- Botões de números das páginas: -->
        <button class="pagination__button" :class="{active: n == analysisPagination.currentPage, disabled: n <= 0}" v-for="n in getPagesInterval" :key="n" @click="getPaginateAnalysis(n)">{{n}}</button>
        <!-- Botão próxima página: -->
        <button class="pagination__latbutton" :class="{disabled: analysisPagination.lastPage == analysisPagination.currentPage}" @click="getPaginateAnalysis(analysisPagination.currentPage + 1)">{{ $t('chooseAnalysis.buttons[2]') }} <img src="../../assets/img/ai/right-arrow.svg" alt="Arrow"></button>
      </div>

    </article>
    <!-- Exibir detalhes da análise ativa selecionada -->
    <ModelStatus v-else-if="showModelStatus" @back-page="backPage" />
    <!-- Criar nova análise -->
    <article class="ai__choose" v-else>
      <button title="Back to previous page" aria-label="Back to previous page" class="predapi__back-button" @click="backPage">
        <img src="../../assets/img/ai/icon-back-arrow.svg" alt="Back to previous page">
      </button>

      <h2 class="ai__title">{{ $t('chooseAnalysis.titles[1]') }}</h2>

      <p class="ai__description">{{ $t('chooseAnalysis.labels[1]') }}</p>

      <!-- Lista de cards dos tipos de análises disponíveis: IMAGEM ON -->
      <!-- <ul class="ai__analysis">
        <li
          class="analysis__card"
          :class="{ selected: analysisType.id === type.id}"
          v-for="type in analysisTypeList"
          :key="type.id" @click="selectAnalysisType(type)">
          <img
            :src="require('../../assets/img/ai/analyses/' + type.icon)"
            :alt="type.type"
            class="analysis__img"
          />
          <h3 class="analysis__title">{{type.type}}</h3>
          <p class="analysis__description">{{getTranslatedInfosAnalysisCards(type.type)}}</p>
        </li>
      </ul> -->

      <!-- Lista de cards dos tipos de análises disponíveis: IMAGEM OFF-->
      <ul class="ai__analysis">
        <li
          class="analysis__card"
          :class="{ selected: analysisType.id === type.id}"
          v-for="type in analysisTypeList"
          :key="type.id" @click="selectAnalysisType(type)">
          <h3 class="analysis__title">{{type.type}}</h3>
          <p class="analysis__description">{{getTranslatedInfosAnalysisCards(type.type)}}</p>
        </li>
      </ul>
  
      <p class="ai__description analysis-name">{{ $t('chooseAnalysis.labels[2]') }}</p>
      <!-- Nome da análise personalizado: -->
      <div class="ai__input-name" :class="invalidInputName ? 'invalid' : 'valid'">
        <input type="text" v-model="analysisType.name" :class="invalidInputName ? 'invalid' : 'valid'" :placeholder="invalidInputName ? 'Please enter a valid analysis name' : 'Analysis name' " @keyup="checkForAnalysisName()">
        <img src="../../assets/img/ai/right-arrow.svg" alt="Right arrow">
        <p :class="invalidInputName ? 'invalid' : 'valid'">{{ $t('chooseAnalysis.labels[3]') }}</p>
      </div>

      <button
        class="ai__button"
        :class="{ disabled: analysisType.id === null || analysisType.name.length === 0 }"
        @click="redirectTo('AiRun') , checkForAnalysisName()"
      >
        {{ $t('chooseAnalysis.buttons[1]') }}
      </button>
    </article>
  </section>
</template>

<script>
import { usePageSelector } from "../../stores/pageSelector";
import { useAiStore } from "../../stores/ai";
import { useUserStore } from '../../stores/user';
import { storeToRefs } from 'pinia';
import ModelStatus from "./ModelStatus.vue";
import ModalEdit from "../elements/ModalEdit.vue";

export default {
  name: "AiChoose",
  components: { ModelStatus, ModalEdit },
  setup() {
    const pageSelector = usePageSelector();
    const { redirectTo, getAllowedPages } = pageSelector;

    const {paginaAtualChoose , allowedPages} = storeToRefs(pageSelector);

    const aiStore = useAiStore();
    const { analysisType, analysisTypeList, prevAnalysisList, targetColumn, cameFromQuickActions} = storeToRefs(aiStore);

    const userStore = useUserStore();
    const { comp_id, user_id, analysis, firstname, transf_id, isSessionExpired, statusSub, isSubscription} = storeToRefs(userStore);
    const { trigSessVerify, } = userStore;
    return {
      redirectTo,
      getAllowedPages,
      allowedPages,
      analysisType,  // Tipo de análise selecionado pelo usuário
      analysisTypeList, // Tipos de análises disponíveis para serem criados
      prevAnalysisList, // Histórico e paginação de análises criadas anteriormente
      comp_id,
      user_id,
      analysis,
      transf_id,
      firstname,
      targetColumn,
      cameFromQuickActions,
      isSessionExpired,
      trigSessVerify,
      paginaAtualChoose,
      statusSub,
      isSubscription,
    }
  },
  methods: {
    selectAnalysisType(type) {
      console.log(type)
      this.analysisType = {...this.analysisType, ...type};
      // window.scrollTo(0, document.querySelector('.ai__input-name').offsetTop)
      this.invalidInputName  =true;
    },
    checkForAnalysisName(){
      if(this.analysisType.name.length === 0) this.invalidInputName = true
      else this.invalidInputName = false
    },
    backPage() {
      this.showPrevAnalysis = true;
      this.showModelStatus = false;
      this.cameFromQuickActions = 0
    },
    handleCreateAnalysis() {
      // Resetar o tipo de análise escolhido:
      this.analysisType = {
        id: null,
        name: "",
        type: null,
        description: null,
        icon: null,
        progress: 0,
        outputs:null,
      }
      // Ocultar a paginação e exibir a tela de criação de análises:
      this.showPrevAnalysis = false
    },
    handlePredict(analysis) {
      this.analysis = analysis.id;
      this.targetColumn = analysis.processed.targetColumn;
      this.transf_id = analysis.transf_id;
      this.redirectTo('AiModelResults')
    },
    handleStatus(analysis) {
      this.analysis = analysis.id;
      this.showPrevAnalysis = false;
      this.showModelStatus = true;
    },
    getAnalysesBasicInfo(){
      this.trigSessVerify();
      let url = process.env.VUE_APP_URL + '/getAnalysesBasicInfo'
      fetch(url, {
        method:'GET',
      })
      .then((res)=>res.json())
      .then((content) =>{
        // console.log(JSON.stringify(content))
        if(content.invalidSession){
          this.isSessionExpired = true;
        }else {
          this.analysisTypeList = content.map(item => {
            return ({
              id: item.id,
              type: item.name,
              description:item.description,
              icon:item.icons,
              progress:0,
              target:item.has_column_target,
              outputs:item.outputs
            })    
          })
          if(this.cameFromQuickActions > 0){
            // this.analysis = this.cameFromQuickActions
            this.quickCreation()
          }
          console.log(JSON.stringify(this.analysisTypeList))
          this.getPaginateAnalysis(1)
          return true
        }
      })
    },
    // Obter a lista de análises criadas de uma determinada página:
    getPaginateAnalysis(index) {
      let url = process.env.VUE_APP_URL + '/paginateAnalysis'
      let nB = {'comp_id': this.comp_id, 'user_id': this.user_id, 'pageIndex': index,}
      fetch(url, {
        method:'POST',
        body:JSON.stringify(nB),
        headers: new Headers({
          "content-type":"application/json"
        })
      })
      .then((res) =>res.json())
      .then((content) => {
        console.log('Resposta do fetch:', content);
        this.analysisPagination = content.analysisPagination; // Contém informações sobre a paginação (página atual, última página...)
        // Para cada análise da página:
        this.prevAnalysisList = content.analysisList.map(analysis => {

        // Encontrar o tipo de análise correspondente:
        const analysisType = this.analysisTypeList.find(el => el.type === analysis.type)

        // Capturar descrição e ícone do tipo de análise (se existir):
        let newAnalysis = {
          ...analysis,
          description: (analysisType && analysisType.description) || "No description available",
          icon: (analysisType && analysisType.icon) || "icon-robot-default.svg",
        }

        // Atualizar o nome do criador da análise para o nome do usuário logado (deverá mudar futuramente):
        //newAnalysis.infos.creator = this.firstname

        // Formatar a data de criação da análise para YYYY-MM-DD hh:mm
        // console.log(newAnalysis.infos["created at"])
        // let mlStartTS = new Date(newAnalysis.infos["created at"]);
        let formatted = new Date(newAnalysis.infos["created at"]).toLocaleString()
        // console.log(mlStartTS)
        // let formatted = {
        //   day: mlStartTS.getDate() < 10 ? "0" + mlStartTS.getDate() : mlStartTS.getDate(),
        //   month: mlStartTS.getMonth() < 10 ? "0" + mlStartTS.getMonth() : mlStartTS.getMonth(),
        //   year: mlStartTS.getFullYear(),
        //   hours: mlStartTS.getHours() < 10 ? "0" + mlStartTS.getHours() : mlStartTS.getHours(),
        //   minutes: mlStartTS.getMinutes() < 10 ? "0" + mlStartTS.getMinutes() : mlStartTS.getMinutes(),
        // }
        console.log('formatted: '+JSON.stringify(formatted ))
        
        //Atualizando a var do pínia conforme o índice recebido ao clicar no btn
        this.paginaAtualChoose = index 

        newAnalysis.infos["created at"] = formatted;

        return newAnalysis

        })
      })
      .catch((err) => {console.log('Erro, '+err)}); 
    },
    toggleShowEditModal() {
      this.showEditModal = !this.showEditModal
    },
    updateAnalysisName(newName) {  
      // Atualizar o nome da análise no front em tempo real, para não precisar recarregar a página
      let targetIndex = this.prevAnalysisList.findIndex(el => el.id == this.editTarget.id);
      this.prevAnalysisList[targetIndex].name = newName;
      this.toggleShowEditModal()
    },
    handleEditAnalysis(analysis) {
      // Setar a análise alvo (que está sendo editada) e abrir o modal de edição:
      this.editTarget = analysis
      this.toggleShowEditModal()
    },
    // Soluções hardcodadas focadas em time to marketing para traduzir valores
    // dos cards das AIs que vem do backend.
    // Creio que no futuro, no fetch que chama a função para receber os dados dessas análises
    // sejá onde onde for esse fetch, podemos enviar uma flag, indicando o idioma, e fazer a query
    getTranslatedInfosAnalysisCards(label){
      switch (label){
        case 'Churn': return this.$i18n.t('chooseAnalysis.analysisAvailables[0]')
        case 'Persona': return this.$i18n.t('chooseAnalysis.analysisAvailables[1]')
        case 'ICP': return this.$i18n.t('chooseAnalysis.analysisAvailables[2]')
        case 'Sales Prospects': return this.$i18n.t('chooseAnalysis.analysisAvailables[3]')
        case 'A/B testing': return this.$i18n.t('chooseAnalysis.analysisAvailables[4]')
        case 'Upsell/Cross-sell': return this.$i18n.t('chooseAnalysis.analysisAvailables[5]')
        case 'Remarketing viability': return this.$i18n.t('chooseAnalysis.analysisAvailables[6]')
        case 'Employee Turnover': return this.$i18n.t('chooseAnalysis.analysisAvailables[7]')
        case 'Health': return this.$i18n.t('chooseAnalysis.analysisAvailables[8]')
        case 'Classification': return this.$i18n.t('chooseAnalysis.analysisAvailables[9]')
        case 'Regression': return this.$i18n.t('chooseAnalysis.analysisAvailables[10]')
        case 'Clustering': return this.$i18n.t('chooseAnalysis.analysisAvailables[11]')
      }
    },
    getCreatedAnalysisCardsInfosTranslated(label){
      switch (label){
        case 'dataset': return this.$i18n.t('chooseAnalysis.myAnalysisInfos[0]') 
        case 'transformations': return this.$i18n.t('chooseAnalysis.myAnalysisInfos[1]') 
        case 'created at': return this.$i18n.t('chooseAnalysis.myAnalysisInfos[2]') 
        case 'creator': return this.$i18n.t('chooseAnalysis.myAnalysisInfos[3]') 
        case 'status': return this.$i18n.t('chooseAnalysis.myAnalysisInfos[4]') 
      }
    },
    quickCreation(){
      // const analysis = this.analysisTypeList.find(el => el.id === this.cameFromQuickActions)
      // console.log(analysis)
      this.selectAnalysisType(this.analysisTypeList.find(el => el.id === this.cameFromQuickActions))
    },
  },
  data() {
    return {
      showPrevAnalysis: true, // Exibir tela de histórico de análises anteriores (true) ou tela de criação de análise (false)
      showModelStatus: false,

      showEditModal: false,
      editTarget: {},
      lockEdit: false,

      invalidInputName: false,
      analysisPagination: {},

      fieldModalEdit: this.$i18n.t("modalEdit.fields[0]"),
      titleModalEdit: this.$i18n.t("modalEdit.titles[0]"),
    };
  },
  computed: {
    canCreateAnalysis(){
      if(this.isSubscription){
        switch (this.statusSub){
          case 'past_due': return false;

          case 'active': return false;

          // (incomplete, incomplete_expired, canceled, unpaid) 
          default: return true;
        }
      }
      else{
        return false;
      }
    },
    // Obter o intervalo de páginas a ser exibido nos botões da paginação:
    getPagesInterval: function() {
      let currentPage = this.analysisPagination.currentPage
      let lastPage = this.analysisPagination.lastPage

      // Se houver apenas 5 páginas ou menos, exibir os números do array a seguir em qualquer situação:
      if (lastPage <= 5) {
        let array = []
        for (let i = 1; i <= currentPage; i++) {
          array.push(i)
        }
        return array
      }
      // Se há mais de 5 páginas e o número da página atual é menor que 5:
      if (currentPage < 5 && lastPage > 5) {
        let array = []
        // Bloco de botões das 5 primeiras páginas fixo:
        for (let i = 1; i <= 5; i++) {
          array.push(i)
        }
        // Bloco final: ..., penúltima página, última página (-1 equivale a "...")
        return array.concat([-1, lastPage])
      } 
      // Se há mais de 5 páginas e a distância da página atual até a última NÃO é menor que 4
      if (currentPage >= 5 && !(lastPage - currentPage < 4)) {
        let array = [1,-1] // Primeiro bloco: 1, 2, ...
        for (let i = currentPage - 2; i <= currentPage + 2; i++) {
          array.push(i)
        } // Segundo bloco: página anterior à atual, página atual, próxima página
        // Terceiro bloco: ..., penúltima página, última página
        return array.concat([-1, lastPage])
        // Exemplo: 1, 2, ..., 41, 42*, 43, ..., 45, 46 (* = página atual)
      }
      // Se a distância entre a página atual até a última é menor que 4
      if (lastPage - currentPage < 4) {
        let array = [1,-1]
        for (let i = lastPage - 4; i <= lastPage; i++) {
          array.push(i)
        }
        // Exibir: 1, 2, ..., cinco últimas páginas (fixo).
        return array
      }
      return []
    }
  },
  async mounted() {
    /* 
      Se a var de paginação do pínia for maior que 1, ou seja, se o usuário já tiver navegado por alguma
      das páginas, ent ñ vamos seguir o flow padrão (chamar a função getAnalysisBasicInfo) de montagem deste objeto
      mas sim, chamar a função getPaginateAnalysis passando por parâmetro a var do pínia.

      O processo acima só funciona pois ao clicar no botão de 'predict' no card de uma análise o Pinia chama 
      a função 'redirecTo' para ModelResults, logo este componente é desmontado e descartado, assim ao voltarmos 
      de ModelResults , o componente é montado novamente
    */
    if(this.paginaAtualChoose > 1){
        this.getPaginateAnalysis(this.paginaAtualChoose)
    }else if(this.cameFromQuickActions > 0){
      this.showPrevAnalysis = false
      this.getAnalysesBasicInfo();

    }
    else if(this.cameFromQuickActions === -1){
      this.showPrevAnalysis = false
      this.getAnalysesBasicInfo();
    }else{
      this.getAnalysesBasicInfo();
    }


    if (this.allowedPages.length === 0) {
      await this.getAllowedPages()
      // this.redirectTo("Analytics")
    } 
    // this.getHomeElements();

    
  },
};
</script>

<style scoped>
.ai__container {
  margin-top: 32px;
  text-align: center;
  font-size: 16px;
  animation: surgir 1s;
}
@keyframes surgir {
  from {
    opacity: 0.5;
    transform: translateY(32px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.ai__title {
  font-weight: 600;
  font-size: 2em;
}
.ai__description {
  font-weight: 400;
  font-size: 1.25em;
  margin-top: 32px;
}
.ai__description.analysis-name {
  color: var(--secundary-3);
}
.ai__input-name {
  position: relative;
  width: fit-content;
  margin: auto;
  width: 100%;
  max-width: 360px;
}
.ai__input-name > img {
  position: absolute;
  width: 18px;
  height: 18px;
  left: 10px;
  top: 27px;
}
.ai__input-name > input {
  height: 40px;
  border-radius: 8px;
  width: 100%;
  border: 1px solid var(--secundary-3);
  margin-top: 16px;
  margin-bottom: 8px;
  background-color: transparent;
  padding: 8px 12px;
  padding-left: 32px;
  font-size: 1em;
  color: var(--secundary-3);
  font-style: italic;
  outline: 0;
}
.ai__input-name > input::placeholder {
    color: var(--secundary-3);
}

.ai__input-name p {
  font-size: 0.8em;
}

/* .ai__input-name::after{
  content: 'Required Field';
  font-size: 0.8em;
  width: 100%;
  left: 10px;
  color: var(--secundary-3);
  position: absolute;
  top: 60px;
} */
.ai__analysis {
  margin: auto;
  margin-top: 32px;
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  justify-content: center;
  max-width: 1280px;
}
.analysis__card {
  background-color: #fff;
  /* border-radius: 16px; */
  padding: 32px;
  text-align: left;
  display: grid;
  grid-template-areas: "img title" "img description";
  grid-template-columns: 56px 218px;
  grid-template-rows: auto 40px;
  gap: 8px 16px;
  line-height: 1.2;
  cursor: pointer;
  border: 3px solid transparent;
  transition: 0.4s ease;
  font-size: 16px;
  border-left: 3px solid var(--secundary-3);

  box-shadow: 0px 6px 8px -6px rgba(24, 39, 75, 0.12), 0px 8px 16px -6px rgba(24, 39, 75, 0.08);
}
.analysis__card:hover {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.analysis__card.selected {
  border-color: var(--primary);
}
.analysis__img {
  grid-area: img;
  width: 100%;
  height: 44px;
  /* background-color: var(--secundary-3); */
}
.analysis__title {
  font-weight: 600;
  font-size: 1.1em;
  grid-area: title;
}
.analysis__description {
  font-weight: 400;
  font-size: 0.75em;
  color: #767676;
  grid-area: description;
  max-width: 240px;
  line-height: 16px;
}
.ai__button {
  border-radius: 6px;
  border: 0;
  background-color: var(--primary);
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  max-width: 200px;
  width: 100%;
  height: 40px;
  margin-top: 32px;
  cursor: pointer;
  transition: 0.4s ease;
  position: relative;
}
.ai__button.disabled {
  opacity: 0.4;
  cursor: not-allowed;
  pointer-events: none;
}
.ai__button::after {
  content: url(../../assets/img/ai/right-arrow.svg);
  position: absolute;
  padding-left: 4px;
  transition: 0.2s ease;
}
.ai__button:hover {
  background-color: var(--secundary-3);
}
.ai__button:hover::after {
  padding-left: 12px;
}
.ai__active > .ai__button::after {
  display: none;
}
.ai__active > .ai__button {
  max-width: 240px;
}
.ai__active > .ai__button::before {
  content: url(../../assets/img/ai/icon-plus.svg);
  padding-right: 6px;
  height: 25px;
  display: inline-block;
  transform: translateY(4px);
}
.ai__prev-analyses {
  margin: auto;
  margin-top: 48px;
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
  align-items: center;
  justify-content: center;
  max-width: 1080px;
}
.prev-analysis {
  display: grid;
  grid-template-areas: 'icon content';
  grid-template-columns: 50px auto;
  background-color: #FFF;
  /* border-radius: 16px; */
  max-width: 490px;
  text-align: left;
  width: 100%;
  box-shadow: 0px 6px 8px -6px rgba(24, 39, 75, 0.12), 0px 8px 16px -6px rgba(24, 39, 75, 0.08);
}
.no__prev {
  color: var(--secundary-3);
  font-weight: 400;
  font-style: italic;
  font-size: 16px;
  margin: auto;
  margin-top: 25vh;
  width: max-content;
  padding: 6px;
  /* border-radius: 6px; */
  border-bottom: 1px solid var(--secundary-3);
}
.prev__icon {
  grid-area: icon;
  background-color: var(--secundary-3);
  padding: 0px;
  width: 10px;
  border-radius: 16px 0 0 16px;
  display: grid;
  place-content: center;
}
.prev__icon img {
  filter: brightness(0) invert(1);
}
.prev__content {
  grid-area: content;
  padding: 32px;
}
.analysis__pagination {
  margin: 48px auto;
  display: flex;
  width: 100%;
  max-width: 1080px;
  justify-content: center;
  gap: 24px;
  flex-wrap: wrap;
}
.pagination__button,
.pagination__latbutton {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  background-color: transparent;
  border: 1px solid var(--primary);
  color: var(--primary);
  font-weight: 500;
  cursor: pointer;
  transition: .4s ease;
}
.pagination__button.active {
  background-color: var(--primary);
  color: #FFF;
}
.pagination__button.disabled {
  border-color: transparent;
  color: transparent;
  position: relative;
  pointer-events: none;
}
.pagination__button.disabled::after {
  content: '...';
  position: absolute;
  bottom: 1px;
  left: 50%;
  transform: translateX(-50%);
  display: inline-block;
  color: var(--primary);
}
.pagination__latbutton {
  width: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}
.pagination__latbutton img {
  position: relative;
  top: -1px;
}
.pagination__latbutton:first-of-type img {
  transform: rotate(-180deg);
}
.pagination__latbutton.disabled {
  border-color: #bbb;
  color: #BBB;
  pointer-events: none;
}
.infos__name {
  display: flex;
  /* justify-content: space-between; */
  gap: 8px;
  align-items: center;
  max-width: 100%;
}
.edit-button {
  display: grid;
  place-content: center;
  width: 32px;
  height: 32px;
  cursor: pointer;
  background: transparent;
  border: 0;
  border-radius: 50%;
  transition: .4s ease;
}
.edit-button:hover {
  background-color: rgba(39, 94, 246, 0.08);
}
.infos__title {
  color: var(--secundary-3);
  font-size: 16px;
  font-weight: 600;
  max-width: 248px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.infos__type {
  margin-bottom: 16px;
}
.infos__subtitle {
  display: inline-block;
  color: var(--secundary-3);
  font-size: 14px;
  font-weight: 400;
  
}
.info-button {
  background: transparent;
  border: 0;
  transform: translate3d(8px, 3px, 0);
  height: 16px;
  width: 16px;
  display: none;
}
.info-button > img {
  width: 100%;
}
.infos__item {
  font-size: 12px;
  line-height: 1.4;
}
.infos__item > strong,
.infos__item[title="creator"] {
  text-transform: capitalize;
}
.infos__item::before {
  content: '';
  display: inline-block;
  height: 4px;
  width: 4px;
  background-color: var(--secundary-3);
  border-radius: 100%;
  margin-right: 8px;
  margin-bottom: 2px;
}
.infos__item:not(.infos__item:last-of-type) {
  margin-bottom: 10px;
}
.infos__buttons {
  display: flex;
  gap: 16px;
  margin-top: 24px;
}
.infos__buttons button {
  background: transparent;
  border: 1px solid var(--primary);
  color: var(--primary);
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  padding: 8px;
  width: 100%;
  max-width: 140px;
  cursor: pointer;
  transition: .4s ease;
}
.infos__buttons button:hover {
  border-color: var(--secundary-3);
  color: var(--secundary-3);
}
.ai__choose {
  position: relative;
  max-width: 1140px;
  margin: auto;
}
.predapi__back-button {
  position: absolute;
  left: 0px;
  top: 2px;
  background: transparent;
  border: 0;
  cursor: pointer;
}

.pop-up{
  width: 400px;
  height: 60px;
  position: absolute;
  top: 50%;
  right: 50%;
  left: 50%;
  right: 50%;
  background-color: #1F8;
}

.valid{
  border-color: var(--secundary-3);
  color: var(--secundary-3)
}
.invalid{
  animation: shake  .3s  2s ease forwards;
}

/* .invalid::after{
  content: 'Required Field';
  font-size: 0.8em;
  width: 100%;
  left: 10px;
  color: #d33;
  position: absolute;
  top: 60px;
}
 */

@keyframes shake {
  25%{transform: translateX(8px); border-color: #000; color: #d33;}
  50%{transform: translateX(-8px); border-color: #d33; color: #d33;}
  75%{transform: translateX(8px); border-color: #d33; color: #d33;}
  100%{transform: translateX(0px); border-color: #d33; color: #d33;}
}

@media screen and (max-width: 767px) {
  .ai__container,
  .analysis__card {
    font-size: 12px;
  }
  .prev-analysis {
    grid-template-areas: 'icon' 'content';
    grid-template-columns: auto;
    grid-template-rows: 128px auto;
  }
  .prev__icon {
    border-radius: 16px 16px 0 0;
  }
  .pagination__button {
    display: none;
  }
  .analysis__pagination {
    flex-wrap: nowrap;
  }
  .pagination__latbutton {
    width: 100%;
    max-width: 230px;
    height: 32px;
    border-radius: 4px;
  }
  .infos__title {
    max-width: 325px;
  }
}
@media screen and (max-width: 480px) {
  .infos__buttons button {
    font-size: 10px;
  }
  .analysis__card {
    grid-template-columns: 56px auto;
    width: 100%;
  }
  .ai__choose {
    padding-top: 56px;
  }
  .infos__title {
    max-width: calc(100vw - 150px);
  }
}
</style>