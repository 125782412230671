<template>
  <apexchart :options="options" :series="series"></apexchart>
</template> 

<script>
import { storeToRefs } from 'pinia';
import { useViewSelector } from "../../stores/viewSelector";
import { useUserStore } from '../../stores/user';
import VueApexCharts from "vue3-apexcharts";
export default{
  name: 'barChart',
  components:{
    apexchart: VueApexCharts,
  },
  setup() {
    const viewSelector = useViewSelector();
    const {currentView} = storeToRefs(viewSelector);
    
    const userStore = useUserStore();
    const {analysis, isSessionExpired, } = storeToRefs(userStore)
    const {trigSessVerify, } = userStore;
    return {
      currentView,
      analysis,
      isSessionExpired,
      trigSessVerify,
    }
  },
  data(){
    return {
      options: {
        chart: {
          type:'bar',
          id: 'vueBarChart',
          stacked: true,
          width:'100%',
        },
        plotOptions: {
          bar: {
            borderRadius: 2,
            horizontal: true,
            barHeight: '20%',
          },
        },
        xaxis: {
          type:'categories',
          categories:[],
          tickAmount: 2,
          min: 0,
          max: 1,
          labels: {
            formatter: (val) => `${(val*100).toFixed(0)}%` 
          },
        },
        // yaxis: {
        //   labels: {
        //     formatter: (val) => val.toFixed(0)
        //   },
        // },
        colors:['#009F53', '#676767'],
        dataLabels:{
          enabled:false,
        },
        title: {
          text: "Category Prediction",
        },
        responsive: [
          {
            breakpoint: 640,
            options: {
              chart: {
                toolbar: {
                  tools: {
                    zoom: false,
                    zoomin: false,
                    zoomout: false,
                    pan: false,
                    reset: false,
                  }
                },
              },
            }
          }
        ]
      },
      noData:{
        text:'Loading...',
      },
      legend: {
        position: 'right',
        offsetY: 40,
      },
      series: [{
            name: 'Correct',
            data: []
          }, {
            name: 'Incorrect',
            data: []
          },
      ],
    }
  },  
  
  mounted() {
    let url = process.env.VUE_APP_URL + '/AIResultBar'
    console.log('anal id: '+this.analysis)
    let nB = {'a_id':this.analysis}
    this.trigSessVerify();
    fetch(url, {
      method:'POST',
      body:JSON.stringify(nB),
      headers: new Headers({
        "content-type":"application/json"
      })
    })
    .then((res) =>res.json())
    .then((content) => {
      console.table(content);
      if(content.invalidSession){
        this.isSessionExpired = true;
      } else if (content.error || content.noRender) {
        return
      } else{
        let x = [].concat(...content.horizBar.good_bar).map(({name})=>name);
        let y = [].concat(...content.horizBar.bad_bar).map(({data})=>data);
        let z = [].concat(...content.horizBar.good_bar).map(({data})=>data);
        this.options = {
        ...this.options,
        ...{
            xaxis:{
              categories: x
            }
          }
        }
        this.series[0].data = z;
        this.series[1].data = y;
      }
    })
    .catch((err) => {console.log('Erro, '+err)}); 
    
  }, 
}
</script>


<style>
  .wrap {
    display: flex;
    justify-content: center;
}
</style>