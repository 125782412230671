// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/img/ai/right-arrow.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".result__container{border-radius:16px;background-color:#fff;margin:24px auto;max-width:380px;animation:surgir 1s;display:grid;grid-template-areas:\"banner\" \"content\";grid-template-rows:110px auto}@keyframes surgir{0%{opacity:.5;transform:translateY(32px)}to{opacity:1;transform:translateY(0)}}.result__banner{grid-area:banner;background-color:var(--secundary-3);border-radius:16px 16px 0 0;display:grid;place-content:center}.result__banner>img{height:60px}.result__content{padding:40px 32px 32px 32px}.result__title{font-size:24px;font-weight:600}.ai__button{border-radius:8px;border:0;background-color:var(--primary);color:#fff;font-weight:600;font-size:14px;width:100%;height:40px;margin-top:32px;cursor:pointer;transition:.4s ease;position:relative}.ai__button:after{content:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");position:absolute;padding-left:4px;transition:.2s ease}.ai__button:hover{background-color:var(--secundary-3)}.ai__button:hover:after{padding-left:12px}", ""]);
// Exports
module.exports = exports;
